import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";

const SliderCard = (props) => {
  const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // centerMode: true,
    cssEase: "linear",
  };

  return (
    <Slider {...settings}>
      {props.content.map((item) => {
        return (
          <div key={item} className="cavity-slider">
            <Card className={`cavity-card h-100 ${props.cssClass}`}>
              <Card.Body className="h-100">
                <Card.Text>{item}</Card.Text>
              </Card.Body>
            </Card>
          </div>
        );
      })}
    </Slider>
  );
};

export default SliderCard;
