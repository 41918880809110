import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";


import RatingDialog from "../Dialog/RatingDialog";
import { SideDrawerLink } from "./Drawer-links";
import "../dashboard.css";
import "./drawer.css";
import { logoutUserService, stopRefreshTokenTimer } from "../../../Redux/Services/AuthService";

import MenuIocn from '../../../assets/icon/menu-icon.svg';
const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const PersistentDrawerLeft = (props) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const theme = useTheme();
    let history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [userDetails, setUserDetails] = useState(
        JSON.parse(localStorage.getItem("user"))
    );
    const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
    const [isOpenRatingDialog, setIsOpenRatingDialog] = useState(false);
    const [dialogSelectedValue, setDialogSelectedValue] = useState("");
    const [sideDraw, setSideDraw] = useState([]);

    useEffect(() => {
        setSideDraw(SideDrawerLink(authToken, handleClickOpen, logoutHandler));
    }, []);

    const handleClickOpen = () => {
        setIsOpenRatingDialog(true);
        handleDrawerClose();
    };

    const handleClose = (value) => {
        setIsOpenRatingDialog(false);
        setDialogSelectedValue(value);
    };

    const handleDrawerOpen = () => {
        setOpen(!open);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const logoutHandler = async () => {
        const logoutService = await logoutUserService();
        if (logoutService && logoutService.status === 200) {
            history.push("/login");
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <img src="/images/icons/menu-icon.svg" alt="Menu Icon" />
                        {/* <MenuIcon className="menu-color" /> */}
                    </IconButton>
                    <Typography
                        variant="h5"
                        noWrap
                        className="letter-spacing-2px text-white w-100 text-center text-uppercase font-size-19"
                    >
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className="row mx-auto">
                    <div className="col-12 text-white pt-5 pb-4 px-0">
                        <h6 className="my-auto ml-4 font-weight-bold text-capitalize font-size-21">
                            {userDetails
                                ? `Hi, ${userDetails.user.first_name} ${userDetails.user.last_name}`
                                : "Welcome Guest!"}
                        </h6>
                    </div>
                    {/* <div className={`${classes.drawerHeader} col-4`}>
            <IconButton onClick={handleDrawerClose}>
              <i className="far fa-times-circle close-btn"></i>
            </IconButton>
          </div> */}
                </div>
                {/* Rest of the links */}
                <List className="py-0 mx-auto">
                    {sideDraw.map(
                        (routeLink, index) =>
                            routeLink.isShow && (
                                <ListItem button key={index}>
                                    {!routeLink.img ? (
                                        <ListItemIcon className="menu-icon mr-4">
                                            {routeLink.icons}
                                        </ListItemIcon>
                                    ) : (
                                        <div className="drawer-icon-img mr-4 d-flex align-items-center justify-content-center">
                                            <img src={routeLink.img} alt={routeLink.name} />
                                        </div>
                                    )}
                                    <ListItemText className="drawer-item-text">
                                        {/* Normal Links  */}
                                        {routeLink.link && (
                                            <Link
                                                to={routeLink.link}
                                                className="text-white w-100 d-block"
                                            >
                                                {routeLink.name}
                                            </Link>
                                        )}

                                        {/* Anchor tag Link  */}
                                        {routeLink.anchorLink && (
                                            <a
                                                href={routeLink.anchorLink}
                                                className="text-white w-100 d-block"
                                            >
                                                {routeLink.name}
                                            </a>
                                        )}

                                        {/* click event functionality  */}
                                        {routeLink.onClick && (
                                            <div
                                                className="text-white w-100 d-block"
                                                onClick={routeLink.onClick}
                                            >
                                                {routeLink.name}
                                            </div>
                                        )}
                                    </ListItemText>
                                </ListItem>
                            )
                    )}
                </List>

                {/* app version */}
                <List className="py-0">
                    <ListItem button>
                        <ListItemText className="text-white text-center mb-3">
                            Version - 0.1.0
                        </ListItemText>
                    </ListItem>
                </List>
                {/* <div className='border-radius-top-40 drawer-bottom-box' /> */}
            </Drawer>



            <Row>
                <RatingDialog
                    selectedValue={dialogSelectedValue}
                    open={isOpenRatingDialog}
                    onClose={handleClose}
                />
            </Row>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        userData: state.login.response,
    };
};

export default connect(mapStateToProps, null)(PersistentDrawerLeft);
