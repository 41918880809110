import React from 'react';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const CavityTartarEmptyContents = ({ emptyContent }) => {
    return (
        <div className="col-12 mt-5 text-center">
          <div>
            <p className="black-color font-size-16 mb-5">
              {emptyContent.headerText}
            </p>
            <div>
              <img src={emptyContent.img} alt="dental risk score" />
              <p className="black-color font-size-16 my-3">
                {emptyContent.bottomText}
              </p>
            </div>

            <Button className="my-5 form-btn font-size-18 font-weight-500 text-uppercase">
              <Link to="/camera-module-two" className="text-white w-100 h-100">
                Take scan
              </Link>
            </Button>
          </div>
        </div>
    )
}

export default CavityTartarEmptyContents;