import React, { useState, useEffect } from "react";
import "date-fns";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";
import Swal from "sweetalert2";

import { veterinaryCaseAction } from "../../../../Redux/Action/AuthAction";
// import userProfile from "../../../../assets/Dashboard/Mask-Group.svg";
import Header from "../../../UI/Header/Header";
import caseClasses from "./case.module.css";
import { getCaseById, updateCaseService } from '../../../../Redux/Services/VeterinaryCaseService';

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            width: "100%",
        },
        container: {
            height: "100vh",
        },
        button: {
            margin: "20px",
        },
    },
}));

const defaultLocationValue = {
    type: '',
    other_type: '',
    name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip_code: ''
};
const CaseForm = (props) => {
    const [userDetails, setUserDetails] = useState(
        JSON.parse(localStorage.getItem("user"))?.user
    );
    const [formMode, setFormMode] = useState('Location');
    const [location, setLocation] = useState(JSON.parse(localStorage.getItem("location"))?.location || defaultLocationValue);
    const classes = useStyles();
    const [error, setError] = useState("");
    const [profileDetailsData, setProfileDetailsData] = useState(userDetails);
    const heading = props.location.state?.title;
    let history = useHistory();
    const maxNumber = 1;

    useEffect(() => {
        if (!userDetails) {
            history.push("/login");
        }
        setError(props.veterinaryCaseResponse.message);
        if (
            props.veterinaryCaseResponse.message === "Your case saved successfully!"
        ) {
            Swal.fire("Case data saved successfully!", "", "success");
            props.dispatch({ type: "VETERINARY_CASE_CLEAR" });
            const tempData = props.veterinaryCaseResponse.response?.data?.data
            history.push("/observation/" + tempData._id);
        } else if (props.veterinaryCaseResponse.message) {
            Swal.fire(props.veterinaryCaseResponse.message, "", "error");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, props.veterinaryCaseResponse, userDetails]);

    const [caseDetails, setCaseDeatils] = useState({});
    // const [heading, setHeading] = useState('Case Id - ...');

    useEffect(() => {
        if(props.match.params.id && props.match.params.id !== 'new') {
            getCaseById(props.match.params.id)
                .then((res) => {
                    const resObj = res.data.data[0];
                    setCaseDeatils(resObj);
                    setLocation(resObj.location || defaultLocationValue);
                    // setHeading('Case Id - ' + resObj.case_number);
                        // setAllCases(res.data.data);
                })
                .catch((err) => { });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params]);

    const goBack = () => {
        if(formMode === 'Case') {
            setFormMode('Location')
        } else {
            history.push(`${caseDetails._id ? '/observation/' + caseDetails._id : '/dashboard'}`);
        }
    }

    return (
        <Container className="">
            <Header title={heading ? heading : 'Case Form'} routeRedirection={`${caseDetails._id ? '/observation/' + caseDetails._id : '/dashboard'}`} backFn={goBack}/>
            <div className='margin-top-100'></div>
            <Row className="bg-light border-radius-top-40">
                <Formik
                    enableReinitialize
                    validate={(values) => {
                        let errors = {};
                        if (formMode === 'Location') {
                            if (!values.locationType) {
                                errors.locationType = "Type is Required";
                            }
                            if (values.locationType === 'other' && !values.locationOtherType) {
                                errors.locationOtherType = "Other Type is Required";
                            }
                            if (!values.locationName) {
                                errors.locationName = "Name is Required";
                            }
                            // if (!values.locationAddress) {
                            //     errors.locationAddress = "Address is Required";
                            // }
                            // if (!values.locationCity) {
                            //     errors.locationCity = "City is Required";
                            // }
                            // if (!values.locationState) {
                            //     errors.locationState = "State is Required";
                            // }
                            // if (!values.locationCountry) {
                            //     errors.locationCountry = "Country is Required";
                            // }
                        } else if (formMode === 'Case') {
                            // if (!values.case_number) {
                            //     errors.case_number = "Case number is Required";
                            // }
                            if (!values.name) {
                                errors.name = "Name is Required";
                            }
                            if (values.locationType === 'school') {
                                if (!values.class) {
                                    errors.class = "Class is Required";
                                }
                            }
                            // const phoneRegExp =
                            //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

                        // if (!values.address_line1) {
                        //     errors.address_line1 = "State is Required";
                        // }
                        // if (!values.address_line2) {
                        //     errors.address_line2 = "Country is Required";
                        // }
                        // if (!values.camp_location) {
                        //     errors.camp_location = "Camp location is Required";
                        // }
                        if (!values.age) {
                            errors.age = "Age is Required";
                        }
                        if (!values.sex) {
                            errors.sex = "Sex is Required";
                        }
                        return errors;
                    }}}
                    validationSchema={Yup.object().shape({
                        // locationType: Yup.string().required("Type is required"),
                        // locationName: Yup.string().required("Name is required"),
                        // locationAddress: Yup.string().required("Address is required"),
                        // locationCity: Yup.string().required("City is required"),
                        // locationState: Yup.string().required("State is required"),
                        // locationCountry: Yup.string().required("Country is required"),
                        // case_number: Yup.string().required("Case Number is required"),
                        // name: Yup.string().required("Patient Name is required"),
                        // number: Yup.string().required("Phone Number is required"),
                        // email: Yup.string().email().required("Email is Required"),
                        // address_line1: Yup.string().required("Address Line 1 is Required"),
                        // zip_code: Yup.string().required("Zip code is Required"),
                        // age: Yup.string().required("Age is required"),
                        // sex: Yup.string().required("Sex is required"),
                        // locationZipCode: Yup.string().required("Sex is required"),
                    })}
                    initialValues={{
                        case_number: caseDetails.case_number || "",
                        name: caseDetails.name || "",
                        class: caseDetails.class || "",
                        section: caseDetails.section || "",
                        number: caseDetails.number || "",
                        email: caseDetails.email || "",
                        camp_location:caseDetails.camp_location || "",
                        address_line1: caseDetails.address_line1 || "",
                        address_line2: caseDetails.address_line2 || "",
                        zip_code: caseDetails.zip_code || "",
                        age: caseDetails.age || "",
                        sex: caseDetails.sex || "",
                        locationType: location.type || "",
                        locationOtherType: location.other_type || "",
                        locationName: location.name || "",
                        locationAddress: location.address || "",
                        locationCity: location.city || "",
                        locationState: location.state || "",
                        locationCountry: location.country || "",
                        locationZipCode: location.zip_code || "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        const tempLocationData = {
                            type: values.locationType,
                            other_type: values.locationOtherType || '',
                            name: values.locationName,
                            address: values.locationAddress,
                            city: values.locationCity,
                            state: values.locationState,
                            country: values.locationCountry,
                            zip_code: values.locationZipCode
                        };
                        const data = {
                            ...values,
                            location: tempLocationData,
                            case_number: values.case_number,
                            name: values.name,
                            number: values.number,
                            email: values.email,
                            camp_location:values.camp_location,
                            address_line1: values.address_line1,
                            address_line2: values.address_line2,
                            zip_code: values.zip_code,
                            age: values.age,
                            sex: values.sex
                        };
                        if (formMode === 'Location') {
                            localStorage.setItem('location', JSON.stringify({ location: tempLocationData }));
                            setSubmitting(false);
                            setFormMode('Case');
                            return false;
                        }
                        if (caseDetails._id) {
                            updateCaseService({
                                caseData: {
                                    caseId: caseDetails._id,
                                    ...data
                                }
                            }).then((res2) => {
                                Swal.fire("Case data saved successfully!", "", "success").then(swalRes => {
                                    history.push("/observation/" + caseDetails._id);
                                });
                            }).catch((err) => {
                                let msg = "Server error, Please try later!";
                                if (err && err.data && err.data.message) {
                                    msg = err.data.message;
                                }
                                setError(msg);
                                Swal.fire(msg, "", "error");
                            });
                        } else {
                            props.dispatch(veterinaryCaseAction(data));
                        }
                        setSubmitting(false);
                    }}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;

                        return (
                            <form
                                onSubmit={handleSubmit}
                                className={`${classes.root} mt-3 w-100`}
                            >

                                {formMode === 'Case' ? <div>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Case Number
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Enter case number here "
                                            defaultValue={values.case_number}
                                            name="case_number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.case_number && touched.case_number && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.case_number}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12}>
                                        <div className={`${caseClasses.SubHeading} mt-2`}>Patient details</div>
                                    </Col>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Name *
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Enter name here"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.name && touched.name && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.name}
                                            </div>
                                        )}
                                    </Col>
                                    {values.locationType === 'school' ? <div>

                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Class *
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Class"
                                            name="class"
                                            value={values.class}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.class && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.class}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Section
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Section"
                                            name="section"
                                            value={values.section}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.section && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.section}
                                            </div>
                                        )}
                                    </Col> 
                                    </div> : null
                                    }
                                    {values.locationType !== 'school' ? <div>
                                        <Col xs={12} className="profile-input">
                                            <FormLabel
                                                component="legend"
                                                className="ml-3 pt-3 font-weight-500 text-dark "
                                            >
                                                Phone Number
                                            </FormLabel>
                                            <input
                                                id="standard-basic"
                                                className="form-input my-2 grey-bg-color"
                                                placeholder="Enter number here"
                                                name="number"
                                                type="number"
                                                defaultValue={values.number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.number && touched.number && (
                                                <div className="text-danger mb-2 ml-2">
                                                    {errors.number}
                                                </div>)}
                                        </Col>
                                        <Col xs={12} className="profile-input">
                                            <FormLabel
                                                component="legend"
                                                className="ml-3 pt-3 font-weight-500 text-dark "
                                            >
                                                Email
                                            </FormLabel>
                                            <input
                                                id="standard-basic"
                                                className="form-input my-2 grey-bg-color"
                                                placeholder="Enter email here"
                                                name="email"
                                                defaultValue={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.email && touched.email && (
                                                <div className="text-danger mb-2 ml-2">
                                                    {errors.email}
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs={12} className="profile-input">
                                            <FormLabel
                                                component="legend"
                                                className="ml-3 pt-3 font-weight-500 text-dark "
                                            >
                                                Address
                                            </FormLabel>
                                            <input
                                                id="standard-basic"
                                                className="form-input my-2 grey-bg-color"
                                                placeholder="Address Line 1"
                                                name="address_line1"
                                                defaultValue={values.address_line1}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.address_line1 && touched.address_line1 && (
                                                <div className="text-danger mb-2 ml-2">
                                                    {errors.address_line1}
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs={12} className="profile-input">
                                            <input
                                                id="standard-basic"
                                                className="form-input my-2 grey-bg-color"
                                                placeholder="Address Line 2"
                                                name="address_line2"
                                                defaultValue={values.address_line2}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.address_line2 && touched.address_line2 && (
                                                <div className="text-danger mb-2 ml-2">
                                                    {errors.address_line2}
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs={12} className="profile-input">
                                            <FormLabel
                                                component="legend"
                                                className="ml-3 pt-3 font-weight-500 text-dark "
                                            >
                                                Zip/Pin Code
                                            </FormLabel>
                                            <input
                                                id="standard-basic"
                                                className="form-input my-2 grey-bg-color"
                                                placeholder="Type Zip/Pin Code"
                                                name="zip_code"
                                                type="number"
                                                defaultValue={values.zip_code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.zip_code && touched.zip_code && (
                                                <div className="text-danger mb-2 ml-2">
                                                    {errors.zip_code}
                                                </div>
                                            )}
                                        </Col>
                                    </div> : null}
                                    <Col xs={12}>
                                        <Row className='my-2'>
                                            <Col className="profile-input">
                                                <FormLabel
                                                    component="legend"
                                                    className="ml-3 pt-3 font-weight-500 text-dark "
                                                >
                                                    Age *
                                                </FormLabel>
                                                <input
                                                    id="standard-basic"
                                                    className="form-input my-2 grey-bg-color"
                                                    placeholder="Enter age"
                                                    name="age"
                                                    type='number'
                                                    defaultValue={values.age}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.age && touched.age && (
                                                    <div className="text-danger mb-2 ml-2">
                                                        {errors.age}
                                                    </div>
                                                )}
                                            </Col>
                                            <Col className="profile-input">

                                                <FormLabel
                                                    component="legend"
                                                    className="ml-3 pt-3 font-weight-500 text-dark "
                                                >
                                                    Sex *
                                                </FormLabel>
                                                <select
                                                    id="standard-basic"
                                                    className="form-input my-2 grey-bg-color"
                                                    name="sex"
                                                    value={values.sex}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <option value=''>Please select </option>
                                                    <option value='male'>Male</option>
                                                    <option value='female'>Female</option>
                                                    <option value='other'>Other</option>

                                                </select>
                                                {errors.sex && touched.sex && (
                                                    <div className="text-danger mb-2 ml-2">
                                                        {errors.sex}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>

                                    {error ? (
                                        <div className="text-danger m-2 pl-3">{error}</div>
                                    ) : (
                                        <div className="text-danger m-2 pl-3">{error}</div>
                                    )}
                                    <Col className={classes.root}>
                                        <Button
                                            type="submit"
                                            // disabled={isSubmitting}
                                            className="form-btn mb-4 mt-4"
                                        >
                                            Save and Continue
                                        </Button>
                                    </Col>
                                </div> : <div>
                                <Col xs={12}>
                                        <div className={`${caseClasses.SubHeading} mt-2`}>Dental Program Details</div>
                                    </Col>
                                
                                    <Col className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Type *
                                        </FormLabel>
                                        <select
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            name="locationType"
                                            value={values.locationType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value=''>Please select </option>
                                            <option value='company'>Company</option>
                                            <option value='school'>School</option>
                                            <option value='other'>Other</option>
                                        </select>
                                        {errors.locationType && touched.locationType && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.locationType}
                                            </div>
                                        )}
                                    </Col>
                                    {values.locationType === 'other' ? 
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Other Type *
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Other Type"
                                            name="locationOtherType"
                                            value={values.locationOtherType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.locationOtherType && touched.locationOtherType && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.locationOtherType}
                                            </div>
                                        )}
                                    </Col>
                                    : null}
                                                                        <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Name of Person doing Checkup
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Name"
                                            name="locationName"
                                            value={values.locationName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.locationName && touched.locationName && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.locationName}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Name of Camp Location
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="location"
                                            name="camp_location"
                                            defaultValue={values.camp_location}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.camp_location && touched.camp_location && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.camp_location}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Address
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Address"
                                            name="locationAddress"
                                            defaultValue={values.locationAddress}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.locationAddress && touched.locationAddress && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.locationAddress}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            City
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="City"
                                            name="locationCity"
                                            defaultValue={values.locationCity}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.locationCity && touched.locationCity && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.locationCity}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            State
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="State"
                                            name="locationState"
                                            defaultValue={values.locationState}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.locationState && touched.locationState && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.locationState}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Country
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Country"
                                            name="locationCountry"
                                            defaultValue={values.locationCountry}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.locationCountry && touched.locationCountry && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.locationCountry}
                                            </div>
                                        )}
                                    </Col>
                                    <Col xs={12} className="profile-input">
                                        <FormLabel
                                            component="legend"
                                            className="ml-3 pt-3 font-weight-500 text-dark "
                                        >
                                            Zip/Pin Code
                                        </FormLabel>
                                        <input
                                            id="standard-basic"
                                            className="form-input my-2 grey-bg-color"
                                            placeholder="Zip/Pin Code"
                                            name="locationZipCode"
                                            type="number"
                                            defaultValue={values.locationZipCode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.locationZipCode && touched.locationZipCode && (
                                            <div className="text-danger mb-2 ml-2">
                                                {errors.locationZipCode}
                                            </div>
                                        )}
                                    </Col>

                                    <Col className={classes.root}>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="form-btn mb-4 mt-4"
                                        // onClick={() => setShowCaseForm(true)}
                                        >
                                            Continue
                                        </Button>
                                    </Col>
                                </div>}
                            </form>

                        );
                    }}
                </Formik>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        veterinaryCaseResponse: state.veterinaryCase,
    };
};

export default connect(mapStateToProps, null)(CaseForm);
