import React from 'react';
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import BackArrow from "../../../assets/icon/menu-back-icon.svg";
import cssClasses from './Header.module.css';

const Header = (props) => {
    return (
        <Row>
            <Col
                xs={12}
                className={`d-flex align-items-center justify-content-center mb-3
                ${cssClasses['header-nav-container']} ${props.bgClass ? props.bgClass : 'primary-bg'} position-fixed`}
            >
                {props.backFn ? <div onClick={props.backFn} className={`position-absolute ${cssClasses['back-btn-icon']}`}>
                    <img src="/images/icons/menu-back-icon.svg" alt="back-arrow-img" />
                </div> : <Link to={props.routeRedirection} className={`position-absolute ${cssClasses['back-btn-icon']}`}>
                    <img src="/images/icons/menu-back-icon.svg" alt="back-arrow-img" />
                </Link>}
                {/* <Link to={props.routeRedirection} className={`position-absolute ${cssClasses['back-btn-icon']}`}>
                    <img src="/images/icons/menu-back-icon.svg" alt="back-arrow-img" />
                </Link> */}
                <h1 className={`${cssClasses['header-title']} text-white mb-0 py-3 font-size-20 text-uppercase`}>
                    {props.title}
                </h1>
            </Col>
        </Row>
    );
};

export default Header;