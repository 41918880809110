import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import TutorialVideoModal from "./TutorialVideoModal";

const StepsModal = (props) => {

  const [isOpenVideoTutorialModal, setIsOpenVideoTutorialModal] = useState(false);
  
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const reportCount = JSON.parse(localStorage.getItem('reportCount'));
    if (!user || !reportCount) {
      setIsOpenVideoTutorialModal(true);
    }
  }, []);
  
  const videoTutorial = () => {
    setIsOpenVideoTutorialModal(true);
  }

  const OnCloseDialog = () => {
    setIsOpenVideoTutorialModal(false);
  }

  return (
    <Row className="mx-0">
      <Col xs={12} className="d-flex justify-content-center mx-auto">
        {props.upperBlock.map((data, index) => {
          return props.myImages.length === index ? (
            <Card key={index} className="all-well-modal">
              <Card.Img
                className="camera-card-image"
                variant="top"
                src={data.image}
              />
              <Card.Body className="p-0 mt-2">
                <Card.Text className="black-color text-center py-3 px-2 font-weight-600">
                  Demo Pic of {data.title} <br />
                  Please shoot a photo like this
                </Card.Text>
                <div className="text-center">
                  {
                    isOpenVideoTutorialModal && <TutorialVideoModal index={+index} open={isOpenVideoTutorialModal} onClose={OnCloseDialog} />
                  }
                  <Button className="text-white font-weight-bold instructions-btn primary-bg"
                  onClick={videoTutorial}>
                    <i className="fas fa-video mr-2 font-weight-bold font-size-16"></i>
                    Video Tutorial
                  </Button>
                </div>
                <Link
                  to={{
                    pathname: "/camera-module-first",
                    state: { data, imgCount: index + 1 },
                  }}
                >
                  <p className="text-center card-link mt-1">Got it</p>
                </Link>
              </Card.Body>
            </Card>
          ) : (
            <div key={index}></div>
          );
        })}
      </Col>
    </Row>
  );
};

export default StepsModal;
