import React, { useState, useEffect } from "react";
import "date-fns";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import ImageUploading from "react-images-uploading";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { Formik } from "formik";
// import * as EmailValidator from "email-validator";
import * as Yup from "yup";
import Swal from "sweetalert2";

import { profileAction } from "../../../../Redux/Action/AuthAction";
// import userProfile from "../../../../assets/Dashboard/Mask-Group.svg";
import Header from "../../../UI/Header/Header";
import "./ProfileDetails.css";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            width: "100%",
        },
        container: {
            height: "100vh",
        },
        button: {
            margin: "20px",
        },
    },
}));
const ProfileForm = (props) => {
    const [userDetails, setUserDetails] = useState(
        JSON.parse(localStorage.getItem("user"))?.user
    );
    const classes = useStyles();
    const [images, setImages] = React.useState([]);
    const [error, setError] = useState("");
    const [isdatePickerOpen, setIsdatePickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState(
        new Date("2014-08-18T21:11:54")
    );
    const [profileDetailsData, setProfileDetailsData] = useState(userDetails);
    const heading = props.location.state?.title;
    let history = useHistory();
    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    // const handleDateChange = (date) => {
    //     setSelectedDate(date);
    // };

    const setPickerStatus = (status) => {
        setIsdatePickerOpen(status);
    };

    useEffect(() => {
        if (!userDetails) {
            history.push("/dashboard");
        }
        setError(props.profileResponse.message);
        if (
            props.profileResponse.message === "Your profile updated successfully!"
        ) {
            const updatedUserData = props.profileResponse.response.data.data;
            const userData = { ...userDetails, ...updatedUserData };
            localStorage.setItem("user", JSON.stringify({ user: userData }));
            Swal.fire("User data updated successfully!", "", "success");
            props.dispatch({ type: "USER_PROFILE_CLEAR" });
            history.push("/dashboard");
        }
        if (props.userData?.response?.data?.data?.user) {
            setProfileDetailsData(props.userData.response.data.data.user);
        } else {
            setProfileDetailsData(userDetails);
        }
    }, [props.profileResponse, props.userData?.response?.data?.data?.user]);
    // console.log('profileDetailsData', profileDetailsData);
    return (
        <Container className="">
            <Header title={heading ? heading : 'Profile details'} routeRedirection="/dashboard" />
            <div className='margin-top-200'></div>
            <Row className="bg-light border-radius-top-40">
                <Formik
                    validate={(values) => {
                        let errors = {};
                        if (!values.first_name) {
                            errors.first_name = "First Name is Required";
                        }
                        if (!values.last_name) {
                            errors.last_name = "Last Name is Required";
                        }
                        if (!values.organization_name) {
                            errors.name = "Organization name is Required";
                        }
                        const phoneRegExp =
                            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

                        if (!values.phone_number) {
                            errors.phone_number = "Phone number is Required";
                        } else if (!phoneRegExp.test(values.phone_number)) {
                            errors.phone_number = "Invalid Phone number Number";
                        }
                        if (!values.gender) {
                            errors.gender = "Gender is Required";
                        }
                        if (!values.state) {
                            errors.state = "State is Required";
                        }
                        if (!values.country) {
                            errors.country = "Country is Required";
                        }
                        if (!values.date_of_birth) {
                            errors.date_of_birth = "Dob is Required";
                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({})}
                    initialValues={{
                        first_name: profileDetailsData ? profileDetailsData.first_name : "",
                        last_name: profileDetailsData ? profileDetailsData.last_name : "",
                        organization_name: profileDetailsData ? profileDetailsData.organization_name || "" : "",
                        gender: profileDetailsData ? profileDetailsData.gender : "",
                        country: profileDetailsData ? profileDetailsData.country : "",
                        state: profileDetailsData ? profileDetailsData.state : "",
                        date_of_birth: profileDetailsData ? profileDetailsData.date_of_birth : "",
                        phone_number: profileDetailsData
                            ? profileDetailsData.phone_number
                            : "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        props.dispatch(profileAction(values));
                    }}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues
                        } = props;

                        const dateFns = new DateFnsUtils();

                        // console.log('values',values);

                        return (
                            <form
                                onSubmit={handleSubmit}
                                className={`${classes.root} mt-3 w-100`}
                            >

                                <Col className="profile-picture-center">
                                    <ImageUploading
                                        value={images}
                                        name="profile"
                                        onChange={onChange}
                                        maxNumber={maxNumber}
                                        dataURLKey="data_url"
                                        className="mx-auto"
                                    >
                                        {({ imageList, onImageUpload, isDragging, dragProps }) => (
                                            // write your building UI
                                            <div className="upload__image-wrapper">
                                                <button
                                                    style={isDragging ? { color: "red" } : null}
                                                    onClick={onImageUpload}
                                                    className="image-upload-btn"
                                                    {...dragProps}
                                                >
                                                    <i className="fas fa-camera"></i>
                                                </button>
                                                <div className="image-border mx-auto">
                                                    {imageList.map((image, index) => (
                                                        <div key={index} className="image-item">
                                                            <img src={image.data_url} alt="logo" />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </ImageUploading>
                                </Col>

                                <Col xs={12} className="profile-input">
                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        First Name <span className=''></span>
                                    </FormLabel>
                                    <input
                                        id="standard-basic"
                                        className="form-input my-2 grey-bg-color"
                                        placeholder="First Name"
                                        defaultValue={values.first_name}
                                        name="first_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.first_name && (
                                        <div className="text-danger mb-2 ml-2">
                                            {errors.first_name}
                                        </div>
                                    )}
                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        Last Name
                                    </FormLabel>
                                    <input
                                        id="standard-basic"
                                        className="form-input my-2 grey-bg-color"
                                        placeholder="Last Name"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.last_name && (
                                        <div className="text-danger mb-2 ml-2">
                                            {errors.last_name}
                                        </div>
                                    )}
                                </Col>
                                <Col xs={12}>
                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        Organization Name
                                    </FormLabel>
                                    <input
                                        id="standard-basic"
                                        className="form-input my-2 grey-bg-color"
                                        placeholder="Organization Name"
                                        name="organization_name"
                                        value={values.organization_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.organization_name && (
                                        <div className="text-danger mb-2 ml-2">
                                            {errors.organization_name}
                                        </div>
                                    )}
                                </Col>
                                <Col xs={12}>
                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        Date of Birth
                                    </FormLabel>
                                    <input
                                        id="standard-basic"
                                        type='date'
                                        max='2022-08-01'
                                        className="form-input my-2 grey-bg-color pr-3"
                                        placeholder="Date of Birth"
                                        name="date_of_birth"
                                        value={values.date_of_birth}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.date_of_birth && (
                                        <div className="text-danger mb-2 ml-2">
                                            {errors.date_of_birth}
                                        </div>
                                    )}

                                </Col>

                                {/* <Col xs={12} className="date-picker">
                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        Date of Birth
                                    </FormLabel>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                        <Grid
                                            container
                                            justifyContent="space-around"
                                        >
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                // label="Date of Birth"
                                                format="dd/MM/yyyy"
                                                name="date_of_birth"
                                                className="form-input my-2 grey-bg-color"
                                                // defaultValue={values.date_of_birth}
                                                onChange={(date) => {
                                                    const formatedDate = dateFns.format(date, 'yyyy-MM-dd');
                                                    console.log(formatedDate);
                                                    setValues({ 'date_of_birth': formatedDate });
                                                }}
                                                onClick={() => setPickerStatus(true)}
                                                onClose={() => setPickerStatus(false)}
                                                open={isdatePickerOpen}
                                                value={values.date_of_birth}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Col> */}

                                <Col xs={12}>
                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        Gender
                                    </FormLabel>
                                    <select
                                        id="standard-basic"
                                        className="form-input my-2 grey-bg-color pr-3"
                                        name="gender"
                                        value={values.gender}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <option value=''>Please select </option>
                                        <option value='male'>Male</option>
                                        <option value='female'>Female</option>
                                    </select>
                                    {errors.gender && (
                                        <div className="text-danger mb-2 ml-2">{errors.gender}</div>
                                    )}
                                </Col>

                                <Col xs={12} className="profile-input">
                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        Country
                                    </FormLabel>
                                    <input
                                        id="standard-basic"
                                        className="form-input my-2 grey-bg-color"
                                        placeholder="Country"
                                        name="country"
                                        defaultValue={values.country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.country && (
                                        <div className="text-danger mb-2 ml-2">
                                            {errors.country}
                                        </div>
                                    )}
                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        State
                                    </FormLabel>
                                    <input
                                        id="standard-basic"
                                        className="form-input my-2 grey-bg-color"
                                        placeholder="State"
                                        name="state"
                                        defaultValue={values.state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.state && (
                                        <div className="text-danger mb-2 ml-2">
                                            {errors.state}
                                        </div>
                                    )}

                                    <FormLabel
                                        component="legend"
                                        className="ml-3 pt-3 font-weight-500 text-dark "
                                    >
                                        Phone Number
                                    </FormLabel>
                                    <input
                                        className="form-input my-3 grey-bg-color"
                                        id="standard-basic"
                                        placeholder="Phone Number"
                                        name="phone_number"
                                        defaultValue={values.phone_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.phone_number && (
                                        <div className="text-danger mb-2 ml-2">
                                            {errors.phone_number}
                                        </div>
                                    )}
                                    <div className='phone-number-note'>Please make sure the given mobile number is correct. we'll use for further communication</div>
                                </Col>

                                {error ? (
                                    <div className="text-success m-2 pl-3">{error}</div>
                                ) : (
                                    <div className="text-danger m-2 pl-3">{error}</div>
                                )}
                                {/* {errors && JSON.stringify(errors, undefined, 4)} */}
                                <Col className={classes.root}>
                                    <Button
                                        type="submit"
                                        // disabled={isSubmitting}
                                        className="form-btn mb-4 mt-4"
                                    >
                                        Save and Continue
                                    </Button>
                                </Col>
                            </form>
                        );
                    }}
                </Formik>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state.login,
        profileResponse: state.userProfile,
    };
};

export default connect(mapStateToProps, null)(ProfileForm);
