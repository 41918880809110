import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import FlashOffIcon from "@material-ui/icons/FlashOff";
import { IconButton } from "@material-ui/core";
// import CameraFrontIcon from "@material-ui/icons/CameraFront";
// import CameraRearIcon from "@material-ui/icons/CameraRear";

import CameraCancelButton from "../../UI/CameraModule/CameraCancelButton";
import { GetMobileOperatingSystem } from "./common";
import "./CameraModule.css";

const CameraModuleFirst = (props) => {
  const [flashLight, setFlashLight] = useState(true);
  const [cameraFacingMode, setCameraFacingMode] = useState("ENVIRONMENT");
  const [operatingSystem, setOperatingSystem] = useState("");
  const [isShowCameraAccessDialog, setIsShowCameraAccessDialog] = useState(false);
  const [saveStream, setSaveStream] = useState('');

//   const demoImageData = props.location.state.data;
//   let demoImage = props.location.state.data.image;
//   let imgCount = props.location.state.imgCount;
//   let overlay = props.location.state.data.overlay;
  let history = useHistory();
  const fullscreen = true;

  const handleTakePhotoAnimationDone = (dataUri) => {
    flashLightHandler(false);
    setTimeout(() => {
      history.push("/camera-module-reshot", {
        dataUri,
        // demoImage,
        // imgCount,
        // data: demoImageData,
      });
    }, 500);
  };
  // const setRef = (stream, flashLight = true) => {
    const setRef = (stream,flashLight = true) => {
    setSaveStream(stream);
    const track = stream.getVideoTracks()[0];
    if (track.getCapabilities()?.torch) {
      track
        .applyConstraints({
          advanced: [{ torch: cameraFacingMode === 'ENVIRONMENT' ? flashLight : false, zoom: 2.5 }],
        })
        .catch((e) => {
          console.log(e);
        });
    }
   };

  useEffect(() => {
    toggleTorch();
    const getOperatingSystem = GetMobileOperatingSystem();
    setOperatingSystem(getOperatingSystem);

    return () => {
      flashLightHandler(false);
      setFlashLight(false);
    };
  }, []);

  const toggleTorch = () => {
    if (flashLight) {
      flashLightHandler(true);
      setFlashLight(false);
    } else {
      flashLightHandler(false);
      setFlashLight(true);
    }
  };

  const cameraFacingModeHandler = () => {
    if (cameraFacingMode === "ENVIRONMENT") {
      setCameraFacingMode("USER");
    } else {
      setCameraFacingMode("ENVIRONMENT");
    }
  };

  const flashLightHandler = (isTorchOpen) => {
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: "environment" },
      })
      .then((stream) => {
        const video = document.querySelector("video");
        video.srcObject = stream;

        // get the active track of the stream
        const track = stream.getVideoTracks()[0];

        video.addEventListener("loadedmetadata", (e) => {
          window.setTimeout(() => {
            onCapabilitiesReady(track.getCapabilities());
          }, 500);
        });

        function onCapabilitiesReady(capabilities) {
          if (capabilities.torch) {
            track
              .applyConstraints({
                advanced: [{ torch: isTorchOpen }],
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      })
      .catch((err) => {
        console.error("getUserMedia() failed: ", err)
    });
  };

  return (
    <Container fluid className="bg-container p-0">
      <Camera
        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
        isFullscreen={fullscreen}
        // idealFacingMode={FACING_MODES[cameraFacingMode]}
        isImageMirror={false}
        imageType={IMAGE_TYPES.JPG}
        className="WebCam"
        onCameraStart={(stream) => {
          setRef(stream,flashLight);
        }}
        onCameraError={(err) => {
          // console.log('stream', err);
          navigator.permissions.query({ name: "camera" }).then(res => {
            console.log('res', res.state);
            if (res.state === "denied") {
              setIsShowCameraAccessDialog(true);
            }
          });
        }}
      ></Camera>

      <Row className="position-absolute w-100 h-100 top-0 bottom-0 left-0 right-0 m-0">
        <Col className="p-0">
          <Row className="overlay-blur mx-0 justify-content-center">
            <Col xs={12} className="text-right top-10">

              <CameraCancelButton backFn={()  => {
                history.push('/camera-module-two')
              }} />
            </Col>
            {operatingSystem !== "iOS" && (
              <Col xs={1} className="p-0">
                <IconButton className="text-white" onClick={toggleTorch}>
                  {flashLight ? <FlashOffIcon /> : <FlashOnIcon />}
                </IconButton>
              </Col>
            )}
            <Col xs={10}>
              <div className="text-overlay">
                {/* <p>{demoImageData.title}</p>
                <p style={{ fontSize: 15 }}>{demoImageData.headerText}</p>
                <p style={{ fontSize: 15 }}>{demoImageData.headerMirrorText}</p> */}
              </div>
            </Col>
            {/* <Col xs={1}>
              <div className="camera-facing-icon">
                  <IconButton
                    style={{ color: "white" }}
                    onClick={cameraFacingModeHandler}
                  >
                    {cameraFacingMode === "USER" ? (
                      <CameraRearIcon />
                    ) : (
                      <CameraFrontIcon />
                    )}
                  </IconButton>
                </div>
            </Col> */}
          </Row>

          <Row className="height-56">
            <Col xs={1} className="p-0 h-100 black-bg"></Col>
            <Col xs={10} className="p-0">
              <div className="overlay">
                <div className="overlay-element top-left"></div>
                <div className="overlay-element top-right"></div>
                <div className="overlay-element bottom-left"></div>
                <div className="overlay-element bottom-right"></div>

                {/* Overlay images */}
                <div className="tooth-overlay-1">
                  {/* <img src={overlay} alt="overlay" /> */}
                </div>
              </div>
            </Col>
            <Col xs={1} className="p-0 h-100 black-bg"></Col>
          </Row>

          <div className="bottom-text-overlay d-flex pt-4">
            <p className="pt-2 bottom-mirror-text font-size-17">
              {/* {demoImageData.bottomMirrorText} */}
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CameraModuleFirst;
