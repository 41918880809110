import React, { useState, useEffect } from 'react';
// import MaleSignIcon from '../../assets/icon/male-sign-icon.svg'
// import FemaleSignIcon from '../../assets/icon/female-sign-icon.svg'

import MaleSignIcon from  '../../../../assets/icon/male-sign-icon.svg'
import FemaleSignIcon from '../../../../assets/icon/female-sign-icon.svg'

import './smallCard.css'
import { getDaysInYear } from 'date-fns/esm';

const genderObj = {
    male: {
        label:'M',
        icon: MaleSignIcon,
        gender:'male'
    },
    female: {
        label:'F',
        icon: FemaleSignIcon,
        gender: 'female'
    }
}

const SmallCard = (props) => {
    const { gender = 'male',excel_name, name, age_value, zip_code, case_number, date_value } = props;
    const [genderItem, setGenderItem] = useState(genderObj['male'])


    const setGenderValue = (g) => {
        let tempItem = {}
        if(genderObj[g]) {
            tempItem = genderObj[g]
        } else {
            tempItem = genderObj['male']
        }
        setGenderItem(tempItem)
        return tempItem
    }

    useEffect(() => {
        setGenderValue(gender)
    }, [getDaysInYear])

    return (
        <div className="card-box">
            <div className={`card-left-side gender-${genderItem.gender}`}>
                <div className={`gender-text`}>{genderItem?.label}</div>
                <div><img width={26} height={26} src={genderItem.icon} alt='gender-sign' /></div>
            </div>
            <div className="card-right-side">
                <div className="card-text-box">
                    <div><strong>{name}</strong></div>
                    <div className="card-small-text">{age_value}</div>
                </div>
                <div className="card-text-box">
                    <div>File Name - {excel_name}</div>
                </div>
                <div className="card-text-box">
                    <div>Case ID - {case_number}</div>
                    <div className="card-small-text teritary-color">{date_value}</div>
                </div>
            </div>
        </div>
    );
};

export default SmallCard;