import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Swal from "sweetalert2";
import Header from "../../../UI/Header/Header";

const ChnagePassword = (props) => {
  const [status, setStatus] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem("user"))?.user);
  let history = useHistory();

  useEffect(() => {
    if (!userDetails) {
      history.push('/dashboard');
    }
    if (props.changePwdResponse.message && props.changePwdResponse?.response?.status) {
      setLoading(false);
      document.getElementById("changePasswordFormReset").click();
      props.cleanChangePasswordData();
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      Swal.fire(props.changePwdResponse.message, '', 'success');
      props.history.push("/login");
    } else if (props.changePwdResponse.message && props?.changePwdResponse?.response === undefined) {
      Swal.fire(props.changePwdResponse.message, '', 'error');
      setLoading(false);
    }
  }, [props.changePwdResponse, props.history]);

  const handleReset = (resetForm) => {
    resetForm();
  };

  return (
    <Container>
      <Header title="Change Password" routeRedirection="/dashboard" />
      <Row className="margin-top-70px">
        <Col className="input">
          <Formik
            validate={(values) => {
              let errors = {};

              const passwordRegex = /(?=.*[0-9])/;
              if (!values.oldPassword) {
                errors.oldPassword = "Current Password is Required";
              }

              if (!values.password) {
                errors.password = "Password is Required";
              } else if (values.password.length < 8) {
                errors.password = "Password must be 8 characters long.";
              } else if (!passwordRegex.test(values.password)) {
                errors.password = "Invalid password. Must contain one number.";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Confirm Password is Required";
              }
              return errors;
            }}
            validationSchema={Yup.object().shape({
              oldPassword: Yup.string().required("No password provided."),
              password: Yup.string()
                .required("No password provided.")
                .min(8, "Password is too short - should be 8 chars minimum.")
                .matches(/(?=.*[0-9])/, "Password must contain a number."),
              confirmPassword: Yup.string().when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("password")],
                  "Both password need to be the same"
                ),
              }),
            })}
            initialValues={{
              oldPassword: "",
              password: "",
              confirmPassword: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              props.changePassword(values);
              setSubmitting(false);
              setLoading(true);
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="input-with-icon-placeholder">
                    <input
                      id="oldPassword"
                      name="oldPassword"
                      type="password"
                      placeholder="Current Password"
                      className="form-input my-3 fontAwesome"
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <i className="fa fa-envelope"></i> */}
                    {errors.oldPassword && touched.oldPassword && (
                      <div className="text-danger">{errors.oldPassword}</div>
                    )}
                  </div>
                  <div className="input-with-icon-placeholder">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      className="form-input my-3 fontAwesome"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <i className="fa fa-lock"></i> */}
                    {errors.password && touched.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </div>
                  <div className="input-with-icon-placeholder mb-2">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      className="form-input my-3 fontAwesome"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <i className="fa fa-lock"></i> */}
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="text-danger">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                  {props.changePwdResponse ? (
                    <div className="text-danger m-2">
                      {props.changePwdResponse?.message}
                    </div>
                  ) : (
                    <div className="text-success m-2">
                      {props.changePwdResponse?.message}
                    </div>
                  )}
                  <button
                    hidden
                    id="changePasswordFormReset"
                    onClick={handleReset.bind(null, props.resetForm)}
                    type="button"
                  >
                    Reset
                  </button>
                  <div className="text-center">
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      className="form-btn"
                      //disabled={isSubmitting}
                    >
                      UPDATE PASSWORD
                    </Button>
                  )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    changePwdResponse: state.changePassword,
  };
};

const mapDespatchToProps = (dispatch) => {
  return {
    changePassword: (changePassword) => {
      dispatch({ type: "CHANGE_PASSWORD", changePassword });
    },
    cleanChangePasswordData: () => {
      dispatch({ type: "CLEAN_CHANGE_PASSWORD"});
    }
  };
};

export default connect(mapStateToProps, mapDespatchToProps)(ChnagePassword);
