import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Button } from 'react-bootstrap';

import PlusWh from "../../assets/LoginSignup/plus-wh.svg";

import './../../stylesheets/common.css';

class LoginSignup extends Component {

    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            return this.props.history.push('/dashboard');
        }
    }

    render() {
        return (
            <Container
                style={{ minHeight: `${window.innerHeight}px` }}>
                <Row>
                    <img src={PlusWh} alt="Plus Cat Dog" style={{
                        margin: '100px auto'
                    }} />
                </Row>
                <div className='primary-text mb-5'>
                    Log all dental cases in one place
                </div>
                <Link to={'/login'}>
                    <Button
                        variant="primary"
                        size="lg"
                        className='form-btn mb-4'
                    >
                        Login
                    </Button>
                </Link>
                <Link to={'/signup'}>
                    <Button
                        variant="primary"
                        size="lg"
                        className='form-btn'
                    >
                        Sign up
                    </Button>
                </Link>
            </Container>
        );
    }
}

export default LoginSignup;