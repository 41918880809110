import * as types from '../Action';

const initState = {
  message: undefined
}

const getMsgFromResponse = (res, defaultMsg = '') => {
    let msg = defaultMsg;
    if(res && res.data && res.data.message && typeof res.data.message === 'string' ) {
        msg = res.data.message;
    }
    return msg;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action){
  const response = action.payload;

  switch(action.type) {
    case types.VETERINARY_CASE:
      return { ...state, message:"" };
    case types.VETERINARY_CASE_SUCCESS:
      return { ...state, response, message:"Your case saved successfully!" };
    case types.VETERINARY_CASE_ERROR: 
      return { ...state, message: getMsgFromResponse(response, "Something went wrong please try again!") };
    case types.VETERINARY_CASE_CLEAR:
      return { ...state, message:"" };
    default:
      return state;
  }
};