import loginReducers from './loginReducers';
import signupReducers from './signupReducers';
import imagereducers from './imageReducers';
import reportReducers from './reportReducers';
import reportUploadReducers from './reportUploadReducers';
import forgotPasswordReducers from './forgotPasswordReducers';
import verifyCodeReducers from './VerifyReducers';
import createPasswordReducers from './CreatePasswordReducers';
import  changePasswordReducers from './ChangePasswordReducers';
import  userProfileReducers from './userProfileReducers';
import verifyUserCodeReducers from './verifyUserReducers';
import veterinaryCaseReducers from './veterinaryCaseReducers';
import { combineReducers } from 'redux';

const rootReducers = combineReducers({
    signup: signupReducers,
    login: loginReducers,
    image: imagereducers,
    report: reportReducers,
    reportUpload: reportUploadReducers,
    forgotPassword: forgotPasswordReducers,
    verifyCode: verifyCodeReducers,
    verifyUserCode: verifyUserCodeReducers,
    createPassword: createPasswordReducers,
    changePassword: changePasswordReducers,
    userProfile: userProfileReducers,
    veterinaryCase: veterinaryCaseReducers,
});
export default rootReducers;