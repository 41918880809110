import React, { useEffect, useState } from "react";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { getQuestionnaireById } from "../../../Redux/Services/AuthService";
import TeethImg from "../../../assets/Dashboard/questionnaire/Teeth.svg";
import Header from "../../UI/Header/Header";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { AllQuestions } from "../Questionnaire/QuestionsData";
import "./DentalRiskScore.css";

const DentalRiskScore = () => {
  let history = useHistory();
  const [showDentalScore, setDentalScore] = useState({});
  const [userQuestionsData, setUserQuestionsData] = useState([]);
  const [allQuestions, setAllQuestions] = useState([...AllQuestions]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      getDentalScore(user.user._id);
    }
    if (user && user.user.gender !== "female") {
      allQuestions.splice(0, 1);
    }
  }, []);

  const getDentalScore = (userId) => {
    getQuestionnaireById(userId)
      .then((res) => {
        if (res && res.data.data.length > 0) {
          setUserQuestionsData(res.data.data[0].questions);
          const scoreObj = {};
          const score = res.data.data[0].score;
          if (score <= 1.5) {
            scoreObj.dentalScoreStatus = "Low";
          } else if (score > 1.5 && score <= 2.5) {
            scoreObj.dentalScoreStatus = "Medium";
          } else if (score > 2.5) {
            scoreObj.dentalScoreStatus = "High";
          }
          scoreObj.dentalScore = score;
          setDentalScore(scoreObj);
        }
      })
      .catch((err) => {
      });
  };

  const redirectToQuestionnaire = () => {
    history.push("/questionnaire", {
      userQuestionsData: userQuestionsData
    });
  }

  const questionsWithUserAnswer = (dentalScore) => {
    let count = 0;
    return userQuestionsData.map((ques, index) => {
      return (
        (ques.score && +ques.score >= 2 && dentalScore > 1) &&
        <div className={`mt-2 my-3 ${userQuestionsData.length !== index + 1 ? 'border-bottom' : ''}`} key={`question${index + 1}`}>
          <p className="black-color font-size-16">{`${count += 1}. ${ques.question}`}</p>
          <RadioGroup
            row
            aria-label={`question${index + 1}`}
            name={`question${index + 1}`}
            value={ques.score}
          >
            {ques.options.map((data, index1) => {
              return (
                <label key={`option${index1}`}
                  className={`w-100 questions-option d-flex align-items-center justify-content-center
                  ${+ques.score === +data.value ? 'border border-danger' : ''}`}>
                  <FormControlLabel
                    className="profile-radios"
                    value={`${data.value}`}
                    control={<Radio color="primary" />}
                    label={data.option}
                  />
                  <div>
                    <img src={allQuestions[index]?.options[index1]?.icon} alt="icon" />
                  </div>
                </label>
              );
            })}
          </RadioGroup>
        </div>
      );
    })
  }

  return (
    <Container className="h-100 dental-risk-bg">
      <Header title="Dental risk score" routeRedirection="/dashboard" />
      <Row className={`px-3 h-100 align-items-center ${showDentalScore.dentalScoreStatus && 'margin-top-70px'}`}>
        {showDentalScore.dentalScoreStatus && (
          <Col xs={12} className="mx-auto px-0 mb-5 pb-5">
            <div className="px-3">
              <div className="score-test height-10px mt-5">
                <div
                  className="score-test-value height-10px"
                  style={{
                    width:
                      showDentalScore.dentalScore > 0
                        ? `calc(100% - ${showDentalScore.dentalScore * 33.3}%)`
                        : "100%",
                  }}
                >
                  <span className="font-size-12 grey-color top-20px position-absolute left-0">
                    {showDentalScore.dentalScoreStatus}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        )}
        {showDentalScore.dentalScoreStatus === "Low" && (
          <Col
            className="bg-white border-radius-15 shadow font-size-18 black-color py-3"
            xs={12}
          >
            {
              showDentalScore.dentalScore > 1 ? questionsWithUserAnswer(showDentalScore.dentalScore) : (
            <p className="mx-3">
              Congrats! You are having a <b className="text-success">LOW</b>{" "}
              risk of developing dental problems. Keep up the routine tracking
              to see if your score changes
            </p>
            )
            }
          </Col>
        )}
        {(showDentalScore.dentalScoreStatus === "Medium" || showDentalScore.dentalScoreStatus === "High") && (
          <Col
            className="bg-white border-radius-15 shadow font-size-18 black-color py-3"
            xs={12}
          >
            <p className="font-weight-500 text-center font-size-20">What is increasing your risk</p>
            {questionsWithUserAnswer(showDentalScore.dentalScore)}
          </Col>
        )}
        <Col xs={12} className="text-center my-3 pt-3 px-0">
          {!showDentalScore.dentalScoreStatus ? (
            <div>
              <p className="black-color font-size-16 mb-5">
                Dental risk score takes into consideration your dental habits
                and some health conditions.
              </p>
              <div>
                <img src={TeethImg} alt="dental risk score" />
                <p className="black-color font-size-16 my-3">
                  Would not take you more than 30 seconds
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          <Button className="my-5 form-btn font-size-18 text-uppercase font-weight-500"
            onClick={redirectToQuestionnaire}>
            {showDentalScore.dentalScoreStatus ? "Retake " : "Take "}
            Questionnaire
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export default DentalRiskScore;
