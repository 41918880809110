import React from 'react';
import './NotificationReminder.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Notification = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col className='d-flex'>
                        <Link to='/dashboard'>
                            <i className="fas fa-chevron-left dashboard-btn"></i>
                        </Link>
                        <h1 className='blog-header mx-auto mb-3'>Notification & Reminders</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className='reminder mt-3'>
                        <h6><i className="fas fa-bell mr-2"></i>Set Reminder</h6>
                        <p>Reminder recommendation bases on your report :</p>
                        <Row>
                            <Col xs={8}>
                                <p>Toothbrushing and flossing</p>
                            </Col>
                            <Col xs={4} className='reminder-day'>
                                <p>Twice a day</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <p>Power Rinse <i className="fas fa-question-circle ml-2 reminder-question"></i></p>
                            </Col>
                            <Col xs={4} className='reminder-day'>
                                <p>Once a day</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <p>Mouthwash</p>
                            </Col>
                            <Col xs={4} className='reminder-day'>
                                <p>Thrice a day</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <p>Waterfloss <i className="fas fa-question-circle mr-2 reminder-question"></i></p>
                            </Col>
                            <Col xs={4} className='reminder-day'>
                                <p>Weekends</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className='reminder mt-3'>
                        <h6><i className="fas fa-bell mr-2"></i>Notification Preference</h6>
                        <Row>
                            <Col xs={9}>
                                <p>Push Notification</p>
                            </Col>
                            <Col xs={3}>
                                <label className="switch ml-2">
                                    <input type="checkbox" />
                                        <span className="slider round"></span>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={9}>
                                <p>Email</p>
                            </Col>
                            <Col xs={3}>
                                <label className="switch ml-2">
                                    <input type="checkbox" />
                                        <span className="slider round"></span>
                                </label>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Notification;