export const TartarContents = {
  low: [
    "Try to stay on top of it. Here's how:",
    "- Don’t skip flossing",
    "- Brush your teeth every night before bed",
    "- Eat less candy",
    "Keep tracking your risk score with regular Toothlens scans",
  ],
  medium: [
    "Pay close attention to cleaning these surfaces when brushing and flossing your teeth daily",
    "Use the brushing method recommended by your dentist and invest time doing it",
    "Keep tracking your progress with regular Toothlens scans",
  ],
  high: [
    "Visit a dentist and get the tartar taken care of",
    "Follow regular brushing and flossing, check with your dentist if your method is right",
    "Avoid sticky foods and add fibrous foods to your diet",
  ],
};

export const TartarZeroScoreContents = [
  `With 0 tartar score, You're on the right track. Keep brushing and flossing well to maintain the cavity score at 0.`,
  "If you are unsure of the score, you may retake the test",
];

export const CavityContents = {
  low: [
    `With 0 cavity score, You're on the right track. Try to stay on top of it. Here's how:`,
    "- Don’t skip flossing",
    "- Brush your teeth every night before bed",
    "- Eat less candy",
    "If you are unsure of the score, you may retake the test",
  ],
  high: [
    "Get your cavity looked at by a dentist",
    "Follow regular brushing & flossing and avoid sticky, sugary foods",
    "Try to rinse your mouth after every food intake as much as possible and stay hydrated",
    "Add fibrous foods and diet rich in vitamin C, Vitamin D, and Calcium",
  ],
};
