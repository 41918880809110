import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import './../../stylesheets/common.css';
import classes from './landing.module.css';

class Landing extends Component {

    constructor() {
        super();
        this.state = {
            isIntroVideo: true
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            return this.props.history.push('/dashboard');
        }
        setTimeout(() => {
            this.props.history.push('/login');
        }, 2000);
    }

    render() {
        return (
            <Container fluid
                className={`${classes['landing-bg']} overflow d-flex align-items-center justify-content-center`}
                style={{ 
                    height: `${window.innerHeight}px`,
                    backgroundColor: '#048ffc'
                     }}>
                <Link to={'/landing-two'}>
                    <div>
                        <img src="/images/logo/logo.svg" alt='Toothlens Lite Logo' />
                    </div>
                    <div className='text-center text-white font-size-20'>Toothlens Lite Dental Checkup App</div>
                </Link>
                <div className='position-absolute bottom-0 start-0 mb-5 text-white'>
                    Powered by Toothlens
                </div>
            </Container>
        );
    }
}

export default Landing;