import React, { useEffect, useState } from "react";
import Drawer from "./Drawer/Drawer";
import { Container, Row, Button, Col } from "react-bootstrap";
import {
  getAllCases,
  getCsvUploads,
} from "../../Redux/Services/VeterinaryCaseService";
import "./dashboard.css";
import { studentExcelUploadService } from "../../Redux/Services/AuthService";
import SnackBarMessages from "../UI/Snackbar/Snackbar";
import { Link, useHistory } from "react-router-dom";

const List = () => {
  const history = useHistory();

  const [excelList, setExcelList] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  const getUploadedCsvList = async () => {
    await getCsvUploads()
      .then((res) => {
        setExcelList(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUploadedCsvList();
  }, [fileUploaded]);
  const handleFileUpload = async () => {
    setError(false);
    setSuccess(false);
    await studentExcelUploadService(selectedFile)
      .then((res) => {
        setFileUploaded(!fileUploaded);
        setMessage(res.message);
        setSuccess(true);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setError(true);
      });
  };

  const handleFileData = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    setSelectedFile(filesArray);
  };
  const handleClick = (event) => {
    // Prevents the default behavior of the button
    // Your click event handling logic here
  };
  return (
    <div>
      <Drawer title="Toothlens Lite" />
      <div className="dashboard-bg dashboard-overflow h-100">
        <div className="position-absolute right-0 p-2 width-50px z-index-9">
          <img className="w-100" src="/images/logo/logo.svg" alt="logo" />
        </div>
      </div>

      <Container>
        <Row className="mt-5">
          <Col className="pt-5 py-3 teritary-color form-input-account text-center">
            Uploaded Files- {excelList.length != 0 ? excelList.length : 0}
          </Col>
          {/* <Col xs={6} className="pt-5  card-small-text text-center ">Date</Col> */}
        </Row>
        <div
          className=""
          style={{
            overflowY: "auto", // Vertical overflow
            maxHeight: "500px", // Adjust as needed
            padding: "20px", // Add padding as needed
          }}
        >
          {excelList.length != 0 ? (
            excelList.map((item) => {
              return (
                <div>
                  <div
                    className=" mt-1 mx-2 bottom-border "
                    onClick={() => {
                      localStorage.setItem(
                        "excelName",
                        JSON.stringify(`${item.excel_name}`)
                      );
                      history.push("/dashboard");
                    }}
                  >
                    <Col className=" form-input-account">
                      <strong>{item.excel_name}</strong>
                    </Col>
                    <Col className="card-small-text teritary-color">
                      {item.createdAt}
                    </Col>
                    {/* </button> */}
                  </div>
                </div>
              );
            })
          ) : (
            <div>Please Upload a file</div>
          )}
        </div>
      </Container>

      <div
        className="position-absolute  primary-text mb-3 mt-5"
        style={{
          bottom: 10,
        }}
      >
        <div className="text-center mx-3">
          <input
            type="file"
            onChange={(e) => {
              handleFileData(e);
            }}
            className="btn btn-outline-primary btn-sm"
            // variant="primary"
          />
        </div>
        <div>
          <Button
            onClick={() => {
              handleFileUpload();
            }}
            className="mt-4 btn-lg"
            variant="primary"
          >
            Upload
          </Button>
        </div>
      </div>
      {success && (
        <SnackBarMessages
          open={true}
          position="top-right"
          timing={5000}
          type="success"
          message={message}
        />
      )}
      {error && (
        <SnackBarMessages
          open={true}
          position="top-right"
          timing={5000}
          type="error"
          message={message}
        />
      )}
    </div>
  );
};

export default List;
