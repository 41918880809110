import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Container, Col, Button } from "react-bootstrap";
import { getCaseById } from '../../../../Redux/Services/VeterinaryCaseService';
import { dateFormat } from '../../../Utils';

import Header from "../../../UI/Header/Header";

import classes from "./caseDetails.module.css";

const CaseDetails = (props) => {
    // const prams =  useParams();
    const [details, setDeatils] = useState();
    const [heading, setHeading] = useState('Case Id - ...');

    useEffect(() => {
        getCaseById(props.match.params.id)
            .then((res) => {
                const resObj = res.data.data[0];
                setDeatils(resObj);
                setHeading('Case Id - ' + resObj.case_number);
                // setAllCases(res.data.data);
            })
            .catch((err) => { });
    }, []);
    return (
        <Container fluid className={`${classes.CaseDetailsPage} min-vh-100`} >
            <Header bgClass="teritary-bg" title={heading ? heading : ''} routeRedirection="/dashboard" />
            <div className='margin-top-100'></div>
            <Row className={`bg-light border-radius-top-40 p-4 ${classes.CaseDetailsPageHeight}`}>
                {details ? <Col className='mt-4'>
                    <div className={`${classes.CaseDetailsTabs} d-flex align-self-center justify-content-around`}>
                        <div className={classes.CaseDetailsTabsActive}>Patient detail</div>
                    </div>
                    <Row className='mt-4'>
                        <Col xs={2} className='box'>
                            {" "}
                        </Col>
                        <Col xs={8}>
                            <Row>
                                <Col xs={12}><h4>{details.name}</h4></Col>
                            </Row>
                            <Row className='d-flex mt-2 align-items-center justify-content-between'>
                                <Col>{details.zip_code}</Col>
                                <Col className='text-right'>{details.sex}</Col>
                            </Row>
                            <Row className='font-italic mt-2 d-flex align-items-center justify-content-between'>
                                <Col>{details.age} year old</Col>
                                <Col className='text-right teritary-color'>{dateFormat(details.createdAt)}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col> : null}
            </Row>
            <div className='position-absolute bottom-0  mb-5 add-case-btn primary-text'>
                <Link to={'/camera'}>
                    <Button
                        variant="primary"
                        size="lg"
                        className='form-btn'
                    >
                        Add Observation
                    </Button>
                </Link>
            </div>
        </Container>
    );
};

export default CaseDetails;