import React, { useEffect, useState } from "react";
import { Row, Container, Col, Tabs, Tab } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  getCavityTartarContents,
  getReportById,
} from "../../../../Redux/Services/AuthService";
import CavityTartarBackArrow from "../../../UI/Header/CavityTartarBackArrow";
import { CAVITY_IMG_URL, TARTAR_IMG_URL } from "../../../../Redux/Services/BaseUrl";
import SliderCavityTartarCard from "../../../UI/Slider/SliderCavityTartarCard";
import GradesCard from "../../../UI/Card/ExplainerCard/GradesCard";
import NoteText from "../../../UI/Note/NoteText";
import "./Report.css";
import SendReportBtnUI from "./SendReportBtnUI";

const Cavity = (props) => {
  const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // centerMode: true,
    cssEase: "linear",
  };

  const [reportImages, setReportImages] = useState([]);
  const [cavityContents, setCavityContents] = useState("");
  const [tartarContents, setTartarContents] = useState("");
  const [overallReportContents, setOverallReportContents] = useState("");
  const [reportId, setReportId] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    getReportById(props.match.params.id)
      .then((res) => {
        const resObj = res.data.data[0];
        setReportId(resObj._id);
        setUserId(resObj.user_id);
        const reportScore = Object.keys(resObj.report_score[0]);
        reportScore.splice(reportScore.length - 1, 1);
        setReportImages(reportScore);
        const cavityScore = resObj.report_score[0].overall_score[1];
        const tartarScore = resObj.report_score[0].overall_score[0];
        getCavityTartarContents(resObj._id, cavityScore, tartarScore)
          .then((res1) => {
            if (
              res1 &&
              res1?.data?.code === 200 &&
              res1?.data?.data?.cavityContent &&
              res1?.data?.data?.tartarContent
            ) {
              setCavityContents(res1.data.data.cavityContent);
              setTartarContents(res1.data.data.tartarContent);
              setOverallReportContents(res1.data.data);
            }
            resultTextHandler(
              "outputTartarContent1",
              res1.data.data.overallTartarResult[0]
            );
            resultTextHandler(
              "outputCavityContent1",
              res1.data.data.overallCavityResult[0]
            );
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  }, []);

  const tagHighLightedHandler = (text) => {
    let replaceText;
    let cssClass;
    if (text.search('A') > -1) {
      replaceText = /A/g;
      cssClass = 'text-success';
    } else if (text.search('B') > -1) {
      replaceText = /B/g;
      cssClass = 'text-warning';
    } else if (text.search('C') > -1) {
      replaceText = /C/g;
      cssClass = 'text-danger';
    } else if (text.search('D') > -1) {
      replaceText = /D/g;
      cssClass = 'text-danger';
    } else if (text.search('E') > -1) {
      replaceText = /E/g;
      cssClass = 'text-danger';
    }
    return text.replace(replaceText, (key) => `<span class="${cssClass} font-weight-bold">${key}</span>`).replace(/(\#\S+)/g, (key) => `<span>${key}</span>`);
  }

  function resultTextHandler(el, text) {
    var outEl = document.getElementById(el);
    outEl.innerHTML = tagHighLightedHandler(text);
  }

  

  return (
    <Container fluid className="dental-risk-bg min-vh-100">
      {/* Back Arrow */}
      <CavityTartarBackArrow routeUrl="/total-test" />
      <Row>
        <Col className="px-0 overflow">
          <Tabs
            defaultActiveKey="Tartar"
            id="uncontrolled-tab-example"
            className="cavity-navTabs mx-auto black-color"
          >
            <Tab eventKey="Tartar" title="Tartar">
              <div className="mt-4 pt-5">
                <div className="text-right my-3 mx-3">
                <SendReportBtnUI reportId={reportId} userId={userId} />
                </div>
                {
                  reportImages.length > 0 && (
                    <>
                      {
                        overallReportContents?.overallTartarResult?.map((item, index) => (
                          <p key={index} className="px-4 font-size-20 mb-2" id={`outputTartarContent${index + 1}`}>
                            {item}
                          </p>
                        ))
                      }
                      <div className="shadow bg-white m-3 py-3 border-radius-15px">
                        <SliderCavityTartarCard
                          isCavityOrTartar="tartar"
                          reportId={reportId}
                          imgUrl={TARTAR_IMG_URL}
                          reportScoreImg={reportImages}
                          contents={tartarContents}
                        />
                      </div>
                      <GradesCard isTartar={true} />
                    </>
                  )
                }
              </div>
            </Tab>
            <Tab eventKey="Cavity" title="Cavity">
              <div className="mt-5 pt-5">
                {reportImages.length > 0 && (
                  <>
                    {
                      overallReportContents?.overallCavityResult?.map((item, index) => (
                        <p key={index} className="px-4 black-color font-size-20 mb-2" id={`outputCavityContent${index + 1}`}>
                          {item}
                        </p>
                      ))
                    }
                    <div className="shadow bg-white m-3 py-3 border-radius-15px">
                      <SliderCavityTartarCard
                        isCavityOrTartar="cavity"
                        reportId={reportId}
                        imgUrl={CAVITY_IMG_URL}
                        reportScoreImg={reportImages}
                        contents={cavityContents}
                      />
                    </div>
                    <GradesCard isCavity={true} />
                  </>
                )}
              </div>
            </Tab>
          </Tabs>

          {/* Note Text */}
          {reportImages.length > 0 && (
            <Col>
              <NoteText />
            </Col>
          )}

        </Col>
      </Row>
    </Container>
  );
};

export default Cavity;
