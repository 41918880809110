import PersonCropCircle from "../../../assets/Dashboard/Drawer/person-circle.svg";
import CheckmarkShield from "../../../assets/Dashboard/Drawer/checkmark.shield.svg";
import DocText from "../../../assets/Dashboard/Drawer/doc.text.svg";
import NoteText from "../../../assets/Dashboard/Drawer/note.text.svg";
import TwoPerson from "../../../assets/Dashboard/Drawer/person.2.svg";
import PersonFill from "../../../assets/Dashboard/Drawer/person.fill.questionmark.svg";
import LogoutIcon from "../../../assets/Dashboard/Drawer/logout.svg"
import SettingsIcon from "../../../assets/Dashboard/Drawer/settings.svg"


export const SideDrawerLink = (authToken, handleClickOpen, logoutHandler) => {
  return [
    {
      name: "Login",
      link: "/login",
      icons: <i className="fas fa-sign-in-alt"></i>,
      isShow: !authToken ? true : false,
    },
    {
      name: "Signup",
      link: "/signup",
      icons: <i className="fas fa-sign-out-alt"></i>,
      isShow: !authToken ? true : false,
    },
    {
      name: "Profile Details",
      link: "/profile-details",
      icons: <i className="fas fa-user-alt"></i>,
      img: PersonCropCircle,
      isShow: authToken ? true : false,
    },
    // {
    //   name: "All Test Reports",
    //   link: "/total-test",
    //   icons: <i className="fas fa-user-alt"></i>,
    //   img: NoteText,
    //   isShow: authToken ? true : false,
    // },
    // {
    //   name: "FAQ's",
    //   link: "/FAQ",
    //   icons: <i className="fas fa-question-circle"></i>,
    //   img: PersonFill,
    //   isShow: true,
    // },
    // {
    //   name: "Privacy policy",
    //   link: "/privacy-policy",
    //   icons: <i className="fas fa-sticky-note"></i>,
    //   img: CheckmarkShield,
    //   isShow: true,
    // },
    // {
    //   name: "Terms of use",
    //   link: "/terms-of-use",
    //   icons: <i className="fas fa-copy"></i>,
    //   img: DocText,
    //   isShow: true,
    // },
    // {
    //   name: "About Us",
    //   link: "/abouts-us",
    //   icons: <i className="fas fa-address-card"></i>,
    //   img: TwoPerson,
    //   isShow: true,
    // },
    {
        name: "Account Setting",
        link: "/change-password",
        icons: <i className="fas fa-lock"></i>,
        img: SettingsIcon,
        isShow: authToken ? true : false,
      },
    // {
    //   name: "Change Password",
    //   link: "/change-password",
    //   icons: <i className="fas fa-lock"></i>,
    //   isShow: authToken ? true : false,
    // },
    // {
    //   name: "support@toothlens.com",
    //   anchorLink: "mailto:support@toothlens.com",
    //   icons: <i className="fas fa-envelope"></i>,
    //   isShow: true,
    // },
    // {
    //   name: "Knowledge",
    //   link: "/knowledge",
    //   icons: <i className="fas fa-graduation-cap"></i>,
    //   isShow: true,
    // },
    // {
    //   name: "Feedback",
    //   onClick: handleClickOpen,
    //   isClickEvent: true,
    //   icons: <i className="fas fa-comment-alt"></i>,
    //   isShow: authToken ? true : false,
    // },
    {
      name: "Logout",
      onClick: logoutHandler,
      isClickEvent: true,
      icons: <i className="fas fa-sign-out-alt"></i>,
      img: LogoutIcon,
      isShow: authToken ? true : false,
    },
  ];
};
