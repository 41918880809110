import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import cv from "@techstark/opencv-js";
import { Skeleton } from "@material-ui/lab";

import CameraModuleBackground from "../../UI/CameraModule/CameraModuleBackground";
import BlurAndBrightnessModal from "../../UI/Modal/BlurAndBrightnessModal";
import "./CameraModule.css";
import { GetMobileOperatingSystem } from "./common";

// window.cv = cv;

const CameraModuleReshot = (props) => {
  const [canvaImg, setCanvaImg] = useState("");
  const [operatingSystem, setOperatingSystem] = useState('');
  const imgRef = useRef("");
  let imageUrl = props.location.state.dataUri;
  let demoImage = props.location.state.demoImage;
  let imgCount = props.location.state.imgCount;
  const demoImageData = props.location.state.data;
  const isReshoot = props.location.state.isReshoot;
  let history = useHistory();
  const [isBlur, setIsBlur] = useState(false);
  const [isLowBrightness, setIsLowBrightness] = useState(false);

  useEffect(() => {
    const image = document.getElementById("source");
    const getOperatingSystem = GetMobileOperatingSystem();
    setOperatingSystem(getOperatingSystem);
    if (cv.getBuildInformation) {
      if (!isReshoot) {
        processImage(image);
        if (getOperatingSystem !== 'iOS') {
          blurImageHandler();
        }
      } else {
        setCanvaImg(imageUrl);
      }
    } else {
      // WASM
      cv["onRuntimeInitialized"] = () => {
        if (!isReshoot) {
          processImage(image);
          if (getOperatingSystem !== 'iOS') {
            blurImageHandler();
          }
        } else {
          setCanvaImg(imageUrl);
        }
      };
    }

    if (getOperatingSystem !== 'iOS') {
      // Getting image brigntness
      getImageLightness(imageUrl, function (brightness) {
        if (brightness < 60) {
          setTimeout(() => {
            setIsLowBrightness(true);
          }, 500);
        }
      });
    }
  }, []);

  const processImage = (imgSrc) => {
    let src = cv.imread(imgSrc);
    let dst = new cv.Mat();
    let rect = new cv.Rect(20, 160, 430, 320);
    dst = src.roi(rect);

    // equivalent(ish) to cv.imshow();
    let canvas = document.getElementById("canvaoutput");
    let context = canvas.getContext("2d");

    let imageData = context.createImageData(dst.cols, dst.rows);
    imageData.data.set(new Uint8ClampedArray(dst.data, dst.cols, dst.rows));

    canvas.height = dst.rows;
    canvas.width = dst.cols;

    context.putImageData(imageData, 0, 0);
    cv.imshow("canvaoutput", dst);
    const canvaImg = canvas.toDataURL("image/jpeg", 0.75);
    setCanvaImg(canvaImg);

    src.delete();
    dst.delete();
  };

  const croppingImageUsingCanva = () => {
    // const canvas = document.getElementById("canvas");
    // const ctx = canvas.getContext("2d");
    // const image = document.getElementById("source");
    // console.log(image.width, image.height)
    // ctx.drawImage(image, 10, 100, 2000, 1700, 10, 10, 1240, 600);
    // console.log('ctx', canvas.toDataURL("image/jpeg",0.75));
    // setCanvaImg(canvas.toDataURL("image/jpeg", 0.75)); // covert canva img to base64 img format
    // const imageURL = 'https://images.unsplash.com/photo-1572107998877-0f1749cf787b?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ&w=400';
    // crop(imageUrl, 16/14).then(canvas => {
    //   setCanvaImg(canvas.toDataURL("image/jpeg", 0.75));
    //   // document.body.appendChild(canvas);
    // });
  };

  const crop = (url, aspectRatio) => {
    return new Promise((resolve) => {
      // this image will hold our source image data
      const inputImage = new Image();

      // we want to wait for our image to load
      inputImage.onload = () => {
        // let's store the width and height of our image
        const inputWidth = inputImage.naturalWidth;
        const inputHeight = inputImage.naturalHeight;

        // get the aspect ratio of the input image
        const inputImageAspectRatio = inputWidth / inputHeight;

        // if it's bigger than our target aspect ratio
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio;
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputWidth / aspectRatio;
        }

        // calculate the position to draw the image at
        const outputX = (outputWidth - inputWidth) * 0.5;
        const outputY = (outputHeight - inputHeight) * 0.5;

        // create a canvas that will present the output image
        const outputImage = document.getElementById("canvas");

        // set it to the same size as the image
        outputImage.width = outputWidth;
        outputImage.height = outputHeight;

        // draw our image at position 0, 0 on the canvas
        const ctx = outputImage.getContext("2d");
        ctx.drawImage(inputImage, outputX, outputY);
        resolve(outputImage);
      };

      // start loading our image
      inputImage.src = url;
    });
  };

  const handleReshoot = () => {
    history.goBack();
  };

  const handleReshootRedirect = () => {
    history.push("/camera-module-first", {
      demoImage,
      imgCount: imgCount,
      data: demoImageData,
    });
  };

  const handleSubmit = () => {
    const allTheImages = [...props.myImages];
    const isImg = allTheImages.some((img) => imageUrl === img);
    if (!isImg) {
      props.addImage({ imageUrl: canvaImg, imgCount });
      props.reportUpload({ imageUrl: canvaImg, imgCount });
    }

    history.push("/camera-module-two");
  };

  const blurImageHandler = () => {
    let imgElement = document.getElementById("source");
    let src = cv.imread(imgElement);
    let dst = new cv.Mat();
    let men = new cv.Mat();
    let menO = new cv.Mat();
    cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);
    // You can try more different parameters
    const t = cv.Laplacian(src, dst, cv.CV_64F, 1, 1, 0, cv.BORDER_DEFAULT);
    cv.meanStdDev(dst, menO, men);
    // console.log(t, cv.meanStdDev(dst, menO, men), menO.data64F[0], men.data64F[0]);
    if (men.data64F[0] < 4) {
      setTimeout(() => {
        setIsBlur(true);
      }, 500);
    }
    src.delete();
    dst.delete();
  };

  const getImageLightness = (imageSrc, callback) => {
    var img = document.createElement("img");
    img.src = imageSrc;
    img.style.display = "none";
    document.body.appendChild(img);

    var colorSum = 0;

    img.onload = function () {
      // create canvas
      var canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0);

      var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      var data = imageData.data;
      var r, g, b, avg;

      for (var x = 0, len = data.length; x < len; x += 4) {
        r = data[x];
        g = data[x + 1];
        b = data[x + 2];

        avg = Math.floor((r + g + b) / 3);
        colorSum += avg;
      }

      var brightness = Math.floor(colorSum / (this.width * this.height));
      callback(brightness);
    };
  };

  return (
    <Container
      fluid
      className="reshoot-container position-absolute p-0 min-vh-100"
    >
      <CameraModuleBackground />
      <Row className="align-items-end">
        <Col lg={8} xs={12} className="d-flex justify-content-around mx-auto">
          <div className="camera-module-overlay-images">
            {demoImage != null ? (
              <div>
                <img aria-label="image" src={demoImage} />
                <div className="demo-label position-absolute d-flex align-items-center justify-content-center w-100 left-0">
                  <p className="black-color bg-white font-size-18 font-weight-bold">
                    Demo
                  </p>
                </div>
              </div>
            ) : (
              <img
                aria-label="image"
                src="https://www.trucaredentistry.com/blog/wp-content/uploads/How-to-strengthen-loose-teeth-1.png"
              />
            )}
            <div className="d-flex justify-content-start close-image-icon">
              <i onClick={handleReshoot} className="fas fa-times"></i>
            </div>
          </div>
        </Col>
        <Col lg={8} xs={12} className="d-flex justify-content-around mx-auto">
          <div className="position-relative px-4 mb-4">
            {/* <canvas ref={imgRef} /> */}
            {/* <canvas className="crop-image" id="canvas"></canvas> */}
            <img
              className="d-none"
              aria-label="image"
              id="source"
              src={imageUrl}
            />
            <canvas hidden id="canvaoutput" />
            {canvaImg && (
              <img className="crop-image" src={canvaImg} alt="cropImage" />
            )}
            {!canvaImg && (
              <Skeleton animation="wave" width={300} height={350} />
            )}

            <Button
              onClick={handleReshootRedirect}
              style={{ left: isBlur || isLowBrightness ? "45%" : "30%" }}
              className="reshoot-btn reshoot-left-icon rounded-pill bg-white"
              variant="primary"
              size="sm"
            >
              <i className="fas fa-redo font-size-22 primary-color"></i>
            </Button>

            {(!isBlur && !isLowBrightness) && (
              <Button
                onClick={handleSubmit}
                className="reshoot-btn reshoot-right-icon rounded-pill"
                variant="primary"
                size="sm"
              >
                <i className="fas fa-check font-size-22"></i>
              </Button>
            )}

            {(isBlur || isLowBrightness) && <BlurAndBrightnessModal
                isOpenDialog={isBlur || isLowBrightness ? true : false}
                isBlur={isBlur}
                isLowBrightness={isLowBrightness} 
            />}

            
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    myImages: state.image,
    reportState: state.report,
  };
};

const mapDespatchToProps = (dispatch) => {
  return {
    addImage: (imageData) => {
      dispatch({ type: "ADD_IMAGE", payload: imageData });
    },
    reportUpload: (report) => {
      dispatch({ type: "USER_REPORT", report });
    },
  };
};

export default connect(mapStateToProps, mapDespatchToProps)(CameraModuleReshot);
