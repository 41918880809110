import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-browser-router';
import './Blog.css'

import BlogOne from '../../../../assets/Dashboard/Drawer/blog3'
import BlogTwo from '../../../../assets/Dashboard/Drawer/blog2'
import BlogThree from '../../../../assets/Dashboard/Drawer/blog5'
import BlogFour from '../../../../assets/Dashboard/Drawer/blog4'
const Blog = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col className='d-flex'>
                        <Link to='/dashboard'>
                            <i className="fas fa-chevron-left dashboard-btn"></i>
                        </Link>
                        <h1 className='blog-header mx-auto mb-3'>BLOG </h1>
                    </Col>
                </Row>
                <Row className='px-3'>
                    <Col className='d-flex blog-card my-2'>
                        <div className='blog-image'>
                            <img src={BlogOne} alt='Blog' />
                        </div>
                        <div className='blog-text'>
                            <h5>#1 How to maintain dent.... </h5>
                            <p>Author Name (London,UK)</p>
                        </div>
                        <div className='blog-text-date'>
                            <p className=''>25 April, 2020</p>
                        </div>
                    </Col>
                </Row>
                <Row className='px-3'>
                    <Col className='d-flex blog-card my-2'>
                        <div className='blog-image'>
                            <img src={BlogTwo} alt='Blog' />
                        </div>
                        <div className='blog-text'>
                            <h5>#1 How to maintain dent.... </h5>
                            <p>Author Name (London,UK)</p>
                        </div>
                        <div className='blog-text-date'>
                            <p className=''>25 April, 2020</p>
                        </div>
                    </Col>
                </Row>
                <Row className='px-3'>
                    <Col className='d-flex blog-card my-2'>
                        <div className='blog-image'>
                            <img src={BlogThree} alt='Blog' />
                        </div>
                        <div className='blog-text'>
                            <h5>#1 How to maintain dent.... </h5>
                            <p>Author Name (London,UK)</p>
                        </div>
                        <div className='blog-text-date'>
                            <p className=''>25 April, 2020</p>
                        </div>
                    </Col>
                </Row>
                <Row className='px-3'>
                    <Col className='d-flex blog-card my-2'>
                        <div className='blog-image'>
                            <img src={BlogFour} alt='Blog' />
                        </div>
                        <div className='blog-text'>
                            <h5>#1 How to maintain dent.... </h5>
                            <p>Author Name (London,UK)</p>
                        </div>
                        <div className='blog-text-date'>
                            <p className=''>25 April, 2020</p>
                        </div>
                    </Col>
                </Row>
                <Row className='px-3'>
                    <Col className='d-flex blog-card my-2'>
                        <div className='blog-image'>
                            <img src={BlogOne} alt='Blog' />
                        </div>
                        <div className='blog-text'>
                            <h5>#1 How to maintain dent.... </h5>
                            <p>Author Name (London,UK)</p>
                        </div>
                        <div className='blog-text-date'>
                            <p className=''>25 April, 2020</p>
                        </div>
                    </Col>
                </Row>
                <Row className='px-3'>
                    <Col className='d-flex blog-card my-2'>
                        <div className='blog-image'>
                            <img src={BlogThree} alt='Blog' />
                        </div>
                        <div className='blog-text'>
                            <h5>#1 How to maintain dent.... </h5>
                            <p>Author Name (London,UK)</p>
                        </div>
                        <div className='blog-text-date'>
                            <p className=''>25 April, 2020</p>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default Blog