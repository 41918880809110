import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

const CaseDetails = (props) => {
    const { reportImages, onChangeImgPreview, deleteCaseConfirm, goto, caseDetails, openCameraScreen, defaultImages  } = props;
    const [showLocation, setShowLocation] = useState(false);
    const imageList = reportImages.length ? reportImages : defaultImages;
  return (
    <div
    className="vetlens-page"
    style={{
      minHeight: "calc(100vh - 80px)",
    }}
  >
    <div style={{ marginTop: 80 }}>
      <div
        className="mx-auto mt-5"
        style={{
          maxHeight: "calc(100vh - 150px)",
          overflowX: "auto",
        }}
      >
        <div className="text-center mt-3 pt-3">
          <p className="font-size-18 ml-5 text-left font-weight-bolder primary-color mb-2">
            Observation
          </p>
          {imageList.map((img, idx) =>
            img.image_name ? (
              <img
                onClick={() => onChangeImgPreview(img, idx)}
                className="obe-report-img"
                key={idx}
                src={img.image_url}
                alt="obe-report-img"
              />
            ) : (
                <>
                <img
                onClick={() =>{ 
                  if(!localStorage.getItem('folderName')){
                    const timestamp = Date.now().toString(); // Convert current timestamp to string
                    const randomSuffix = Math.floor(Math.random() * 1000000000); // Generate random 9-digit number
                    const randomNum = timestamp + randomSuffix.toString().padStart(9, '0');
                  localStorage.setItem('folderName',randomNum);
                  }
                  

                openCameraScreen(idx);

                }}
                className="obe-report-img"
                key={idx}
                src={"/images/add-photo.svg"}
                alt="obe-report-img"
              />
                {/* {reportImages.length === idx + 1 ? <p className="text-center card-link mt-1" onClick={() => openCameraScreen(0)}>Capture Photo</p> : null } */}
              </>
            )
          )}

        </div>
        <div className="mx-5 my-3">
          <div className="mt-2">
            <p className="font-size-18 font-weight-bolder primary-color my-3">
              Patient Details
            </p>
          </div>
          <div className="mt-2">
            <p className="font-size-18 font-weight-bolder">
              Case Number:
            </p>
            <p className="font-size-18">{caseDetails.case_number}</p>
          </div>
          <div className="mt-2">
            <p className="font-size-18 font-weight-bolder">
              Patient Name:
            </p>
            <p className="font-size-18">{caseDetails.name}</p>
          </div>
          {/* <div className="mt-2">
            <p className="font-size-18 font-weight-bolder">
              Phone Number:
            </p>
            <p className="font-size-18">{caseDetails.number}</p>
          </div>
          <div className="mt-2">
            <p className="font-size-18 font-weight-bolder">Email:</p>
            <p className="font-size-18">{caseDetails.email}</p>
          </div>
          <div className="mt-2">
            <p className="font-size-18 font-weight-bolder">
              Address:
            </p>
            <p className="font-size-18">{`${caseDetails.address_line1} ${caseDetails.address_line2}`}</p>
          </div>
          <div className="mt-2">
            <p className="font-size-18 font-weight-bolder">
            Zip/Pin Code:
            </p>
            <p className="font-size-18">{caseDetails.zip_code}</p>
          </div> */}
          <div className="d-flex justify-content-around mt-2">
            <div className="w-100">
              <p className="font-size-18 font-weight-bolder">Age:</p>
              <p className="font-size-18">{caseDetails.age}</p>
            </div>
            <div className="w-100">
              <p className="font-size-18 font-weight-bolder">Sex:</p>
              <p className="font-size-18">{caseDetails.sex}</p>
            </div>
          </div>
          {/* {caseDetails.location ? 
                      <div>
                          <div className='mt-2' onClick={() => setShowLocation(!showLocation)}>
                              <p className="my-3"><span className="font-size-18 font-weight-bolder primary-color">Dental Program Details </span><i className={`fa fa-chevron-${showLocation ? 'up' : 'down'}`}></i></p>
                          </div> 
                          {showLocation ? <div>
                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>Type:</p>
                              <p className='font-size-18'>{caseDetails.location.type}</p>
                          </div>
                          {caseDetails.location.other_type ? 
                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>Other Type:</p>
                              <p className='font-size-18'>{caseDetails.location.other_type}</p>
                          </div> : null }
                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>Checked By:</p>
                              <p className='font-size-18'>{caseDetails.location.name}</p>
                          </div>
                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>Address:</p>
                              <p className='font-size-18'>{caseDetails.location.address}</p>
                          </div>
                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>City:</p>
                              <p className='font-size-18'>{caseDetails.location.city}</p>
                          </div>
                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>State:</p>
                              <p className='font-size-18'>{caseDetails.location.state}</p>
                          </div>

                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>Country:</p>
                              <p className='font-size-18'>{caseDetails.location.country}</p>
                          </div>
                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>State:</p>
                              <p className='font-size-18'>{caseDetails.location.state}</p>
                          </div>
                          <div className='mt-2'>
                              <p className='font-size-18 font-weight-bolder'>Zip/Pin Code:</p>
                              <p className='font-size-18'>{caseDetails.location.zip_code}</p>
                          </div>
                          </div>: null }
                      </div>
                          : null
                      } */}
          <div className="my-5 py-4"></div>
          {/* <div className="my-5 py-5"></div> */}
        </div>
      </div>
      <div
        className="position-absolute add-case-btn primary-text bg-white"
        style={{
          bottom: 10,
        }}
      >
        Powered by toothlens
        {/* <Button
          variant="primary"
          size="lg"
          className="form-btn"
          onClick={() => goto("/case-form/" + caseDetails._id)}
        >
          Edit Case
        </Button>
        <Button
          variant="primary"
          size="lg"
          className="form-btn mt-1 bg-danger border-0 case-delete-btn"
          onClick={() => deleteCaseConfirm()}
        >
          Delete Case
        </Button> */}
      </div>
    </div>
  </div>
  )
}

export default CaseDetails