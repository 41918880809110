import React, { useState, useEffect } from "react";
import CameraTakeImage from "./CameraTakeImage";
import CameraReshot from "./CameraReshot";
import CameraHeader from "../UI/Header/CameraHeader";
// import StepsModal from '../UI/Modal/steps-modal';

import { Container, Row, Col } from "react-bootstrap";
import Observations from "./Observations";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  uploadImage,
  scanTooth,
  getCaseById,
  updateCaseService,
  deleteCaseService,
  generatePdf,
} from "../../Redux/Services/VeterinaryCaseService";
import "./observation.css";
import CaseDetails from "./CaseDetails";
import FailedTeeth from "../../assets/cameraModule/Failed.svg";
import "./observation.css";
const CameraStepsData = [
  {
    number: 1,
    title: "Front Teeth",
    image: "/images/sample/Front.svg",
    headerText: "Align the teeth with the template",
    headerMirrorText: "",
    bottomMirrorText: "The camera screen should face a mirror",
    overlay: "/images/overlay/overlay-1.svg",
  },
  {
    number: 2,
    title: "Upper Jaw",
    image: "/images/sample/Upper.svg",
    headerText: "Align the teeth with the template",
    headerMirrorText: "Open the mouth and tilt the head backwards",
    bottomMirrorText: "The camera screen should face a mirror",
    overlay: "/images/overlay/overlay-2.svg",
  },
  {
    number: 3,
    title: "Lower Jaw",
    image: "/images/sample/Lower.png",
    headerText: "Align the teeth with the template",
    headerMirrorText: "Open the mouth and tilt the head downwards",
    bottomMirrorText: "The camera screen should face a mirror",
    overlay: "/images/overlay/overlay-3.svg",
  },
  // {
  //     number: 4,
  //     title: "Left Side",
  //     image: '/images/sample/Left.svg',
  //     headerText: "Align your teeth with the template",
  //     headerMirrorText: "Pull the corner of your mouth as back as possible",
  //     bottomMirrorText: "Your camera screen should face a mirror",
  //     overlay: '/images/overlay/overlay-4.svg',
  // },
  // {
  //     number: 5,
  //     title: "Right Side",
  //     image: '/images/sample/Right.svg',
  //     headerText: "Align your teeth with the template",
  //     headerMirrorText: "Pull the corner of your mouth as back as possible",
  //     bottomMirrorText: "Your camera screen should face a mirror",
  //     overlay: '/images/overlay/overlay-5.svg',
  // },
];

const screens = [
  {
    heading: "Take a set of images for an observation",
    mode: "TakeImage",
    index: 0,
  },
  {
    heading: "Is the photo taken correctly ?",
    mode: "Reshot",
    index: 1,
  },
  {
    heading: "CASE DETAILS",
    mode: "CaseDetails",
    index: 2,
  },
  {
    heading: "Take a set of images for an observation",
    mode: "Observations",
    index: 3,
  },
];

const defaultImages = [
  {
    title: "front Teeth",
  },
  {
    title: "Upper Jaw",
  },
  {
    title: "Lower Jaw",
  },
  // {
  //     title: "Left Side",
  // },
  // {
  //     title: "Right Side",
  // },
];

const Observation = () => {
  let history = useHistory();
  let { id } = useParams();
  const [caseDetails, setCaseDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentMode, setCurrentMode] = useState(screens[2]);
  const [imageUri, setImageUri] = useState();
  const [currentStepCount, setCurrentStepCount] = useState(0);
  const [reportImages, setReportImages] = useState([...defaultImages]);
  const [isReshoot, setIsReshoot] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [mlError, setMlError] = useState(false);

  const goto = (path) => {
    history.push(path);
  };

  const changeMode = (index = 0) => {
    setCurrentMode(screens[index]);
    // setCurrentIndex(index);
  };

  const previousScreen = () => {
    if (currentMode.mode === "CaseDetails") {
      goto("/dashboard");
    } else if (currentMode.mode === "Observations" && !isAction) {
      changeMode(2);
    } else if (currentMode.mode === "TakeImage") {
      changeMode(2);
    }
  };

  const gotoScreen = (index) => {
    if ((index || index === 0) && screens.length >= index && 0 <= index) {
      changeMode(index);
    }
  };
  const onImageClick = (dUri, index = currentStepCount) => {
    setImageUri(dUri);
    setCurrentStepCount(index);
    changeMode(1);
  };

  const openCameraScreen = (index = 0) => {
    setImageUri("");
    setCurrentStepCount(index);
    changeMode(0);
  };
  const retake = (index = currentStepCount) => {
    setMlError(false)
    setIsReshoot(false);
    if (index >= 3) {
      changeMode(3);
    } else {
      setImageUri("");
      setCurrentStepCount(index);
      changeMode(0);
    }
  };

  const deleteCase = () => {
    deleteCaseService(id)
      .then((res2) => {
        goto("/dashboard");
      })
      .catch((err) => {
        Swal.fire("Server error, Please try later!", "", "error");
      });
  };

  const deleteCaseConfirm = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All data related to this Case ID will be deleted parmanently",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      customClass: {
        confirmButton: "swal-confirm-yes",
        cancelButton: "swal-confirm-no",
      },
      confirmButtonText: "Yes, DELETE it!",
      cancelButtonText: "No, cancel please!",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((res) => {
      if (res.value) {
        deleteCase();
      } else {
        Swal.fire("Cancelled", "Case Record is safe :)", "error");
      }
    });
  };

  const updateCaseDetails = async () => {
    await updateCaseService({
      caseData: {
        caseId: id,
        report_images: reportImages,
      },
    })
      .then(async (res2) => {
        Swal.fire("Congratulations!! You have completed the check successfully!", "", "success");
        goto("/dashboard");
        setReportImages([...defaultImages]);
      })
      .catch((err) => {
        Swal.fire("Server error, Please try later!", "", "error");
      });
  };

  const onScanTooth = async (rImages = reportImages) => {
    setLoading(true);
    const images = [];
    rImages.forEach((img) => {
      const splitImg = img.image_name.split("/")[1];
      images.push(splitImg);
    });

    // ['fr_c_106.jpeg', 'fr_t_7.jpeg']
    const imagesObj = {
      image: images,
    };
    await scanTooth({
      data: imagesObj,
    })
      .then(async (res) => {
        await updateCaseService({
          caseData: {
            caseId: id,
            report_images: reportImages,
            report_score: [res.data],
          },
        })
          .then(async (res) => {
            await generatePdf({ caseId: id })
              .then((res) => {
                Swal.fire(
                  "Report has been mailed successfully!",
                  "",
                  "success"
                );

                goto("/dashboard");
              })
              .catch((err) => console.log(err));
            setReportImages([...defaultImages]);
          })
          .catch((err) => {
            Swal.fire("Server error, Please try later!", "", "error");
          });
      })
      .catch((err) => {
        Swal.fire("Server error, Please try later!", "", "error");
      });
  };

  const onImageSubmit = (img, note) => {
    setIsReshoot(false);
    uploadImage({
      imageUrl: img,
    })
      .then((res) => {
        if(!res.data.data.is_reshoot){
          const tempData = res.data.data;
          const tempMyImages = reportImages;
          const tempNote = note || tempMyImages[currentStepCount].note;
          tempMyImages[currentStepCount] = {
            ...tempMyImages[currentStepCount],
            image_name: tempData.imageName,
            image_url: tempData.fullUrl,
            note: tempNote,
            tempData,
          };
          setIsAction(true);
          setReportImages(tempMyImages);
          setImageUri(tempMyImages[currentStepCount].image_url);
          const { allImageDone, lastImageIndex } = checkAllImages();
          if (allImageDone) {
            changeMode(3);
          } else {
            setCurrentStepCount(lastImageIndex);
            changeMode(0);
          }
        }
        else{
          setMlError(res.data.data.is_reshoot)
          changeMode(0);

        }
      })
      .catch((err) => {
        Swal.fire("Image not uploaded, Please try again", "", "error");
      });
  };

  const getCaseDetails = (caseId) => {
    setLoading(true);
    getCaseById(caseId)
      .then((res) => {
        setLoading(false);
        const tempDetails = res.data.data[0] || {};
        setCaseDetails(tempDetails);
        const tempReportImages = tempDetails.report_images || [];
        if (caseDetails._id !== tempDetails._id) {
          setReportImages([...defaultImages]);
        }

        if (tempReportImages && tempReportImages.length) {
          setImageUri(tempReportImages[0].image_url);
          setReportImages(tempReportImages);
        }
        // else {
        //   const { allImageDone, lastImageIndex } = checkAllImages();
        //   if (allImageDone) {
        //     onChangeImgPreview("", 0);
        //   } else {
        //     setCurrentStepCount(lastImageIndex);
        //     changeMode(0);
        //   }
        // }
      })
      .catch((err) => {
        Swal.fire("Server error, Please try later!", "", "error");
      });
  };

  const onChangeImgPreview = (rImage, index) => {
    setImageUri(reportImages[index].image_url);
    setCurrentStepCount(index);
    changeMode(3);
  };

  const gotoReshotScreen = (index = 0) => {
    setImageUri(reportImages[index].image_url);
    setCurrentStepCount(index);
    setIsReshoot(true);
    changeMode(1);
  };

  const checkAllImages = (rImages = reportImages) => {
    let tempStep = 0;
    let allImageDone = true;
    let flag = false;
    rImages.forEach((item, idx) => {
      if (!item.image_name && !flag) {
        flag = true;
        tempStep = idx;
        allImageDone = false;
      }
      return item;
    });
    return { allImageDone: allImageDone, lastImageIndex: tempStep };
  };

  useEffect(() => {
 
    getCaseDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const close = () => {
    if (currentMode.index === 3 && isAction) {
      Swal.fire({
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: "Are you sure?",
        html:
          "<p> Your images won't be saved unless you click on submit. </p>" +
          "<br>" +
          "<p>You can choose to reshoot any image or delete the case if you want</p>",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "swal-confirm-yes",
          cancelButton: "swal-confirm-no",
        },
        confirmButtonText: "Yes!",
        cancelButtonText: "No",
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then((res) => {
        if (res.value) {
          goto("/dashboard");
          setReportImages(defaultImages);
          // deleteCase();
        } else {
          // Swal.fire("Cancelled", "Case Record is safe :)", "error");
        }
      });
    } else {
      goto("/dashboard");
    }
  };

  const updateNote = (note) => {

    const tempMyImages = reportImages;
    tempMyImages[currentStepCount] = {
      ...tempMyImages[currentStepCount],
      note: note,
    };
    setReportImages(tempMyImages);
  };

  return (
    <div className="camera-screen black-color-1 ">
      <CameraHeader
        title={currentMode.heading}
        showCloseIcon={true}
        showBackIcon={
          ["CaseDetails", "Observations", "TakeImage"].includes(
            currentMode.mode
          ) && !isAction
        }
        backFn={previousScreen}
        closeFn={close}
      />
      {currentMode.mode === "CaseDetails" && (
        <Container
          fluid
          className="reshoot-container primary-bg p-0 min-vh-100"
        >
          {!loading ? (
            <CaseDetails
              reportImages={reportImages}
              defaultImages={defaultImages}
              caseDetails={caseDetails}
              goto={goto}
              deleteCaseConfirm={deleteCaseConfirm}
              onChangeImgPreview={onChangeImgPreview}
              openCameraScreen={openCameraScreen}
            />
          ) : (
            <Row style={{ marginTop: 180 }}>
              <Col
                lg={8}
                xs={12}
                className="d-flex justify-content-around mx-auto"
              >
                <div className="position-relative px-4 mb-4 text-white font-size-18">
                  Loading...
                </div>
              </Col>
            </Row>
          )}
        </Container>
      )}
      {currentMode.mode === "TakeImage" && (
        <div>
        <CameraTakeImage
          onImageClick={onImageClick}
          retake={gotoReshotScreen}
          cameraStepsData={CameraStepsData}
          stepCount={currentStepCount}
          myImages={reportImages}
          openCameraScreen={openCameraScreen}
        />
          {mlError && (
            <div className="popup">
              <div className="popup-content">
                <div className=" mx-3">
                  <Row className="flex-nowrap bg-white mx-3">
                    <Col xs={12} className="text-center">
                      <img src={FailedTeeth} alt="FailedTeeth" />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-auto flex-nowrap pt-3 bg-white">
                    <Col xs={11} className="black-color  mb-1 ">
                      {/* {props?.reportState?.mouthDetectionMessage === "mouth far away" ? */}

                      <div className="text-center ">
                        <p>
                          {" "}
                          <b> Improper photo. </b>
                          {/* <br /> */}
                          <b> This may be due to </b>{" "}
                        </p>
                      </div>

                      <div className=" px-2 py-1">
                        <ol>
                          <li> Poor lighting </li>
                          <li> Blurred image</li>
                          <li> Image does not match the demo pic.</li>
                        </ol>
                      </div>
                      {/* } */}
                    </Col>
                  </Row>
                  <div className="text-center">
                    <button
                      onClick={()=>retake()}
                      className=" mt-3 mb-2 btn btn-primary"
                    >
                      <span className="">Click here to reshoot</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {currentMode.mode === "Reshot" && (
        <div>
          <CameraReshot
            dataUri={imageUri}
            isReshoot={isReshoot}
            retake={retake}
            stepCount={currentStepCount}
            onSubmit={onImageSubmit}
            updateNote={updateNote}
            note={reportImages[currentStepCount].note}
            reportImages={reportImages}
          />
        </div>
      )}

      {/* {currentMode.mode === 'Prediction' && <PredictionResults predictionDetails={predictionDetails} onRemoveObservation={removeObservation} onSubmit={(status, text) => addPredictions(status, text)} deleteCase={deleteCaseConfirm} />} */}
      {currentMode.mode === "Observations" && (
        <Observations
          retake={retake}
          onChangeImgPreview={onChangeImgPreview}
          onImageClick={onImageClick}
          gotoScreen={gotoScreen}
          stepCount={currentStepCount}
          imageUrl={imageUri}
          reportImages={reportImages}
          nextLabel="Submit"
          note={reportImages[currentStepCount].note}
          updateNote={updateNote}
          loading={loading}
          next={updateCaseDetails}
        />
      )}
    </div>
  );
};

export default Observation;
