import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import { connect } from "react-redux";
import cv from "@techstark/opencv-js";
import { Skeleton } from "@material-ui/lab";

import CameraModuleBackground from "../UI/CameraModule/CameraModuleBackground";
import BlurAndBrightnessModal from "../UI/Modal/BlurAndBrightnessModal";
import ActionButton from '../UI/ActionButton/ActionButton';
// import "./CameraModule.css";
import { GetMobileOperatingSystem } from "../Utils";
import FailedTeeth from "../../assets/cameraModule/Failed.svg";

// window.cv = cv;

const CameraReshot = (props) => {
    const { updateNote, retake, onSubmit, note, dataUri, isReshoot, reportImages = [], stepCount=0 } = props;
    let imageUrl = dataUri;
    // console.log('dataUri', dataUri, isReshoot);
    const [canvaImg, setCanvaImg] = useState("");
    const [operatingSystem, setOperatingSystem] = useState('');
    const imgRef = useRef("");
    let history = useHistory();
    const [isBlur, setIsBlur] = useState(false);
    const [isLowBrightness, setIsLowBrightness] = useState(false);
    const [obsText, setObsText] = useState(note || '' );


    useEffect(() => {
        const image = document.getElementById("source");
        const getOperatingSystem = GetMobileOperatingSystem();
        // console.log('dataUri', dataUri);
        setOperatingSystem(getOperatingSystem);
        if (cv.getBuildInformation) {
            if (!isReshoot) {
                processImage(image);
                if (getOperatingSystem !== 'iOS') {
                    blurImageHandler();
                }
            } else {
                setCanvaImg(imageUrl);
            }
        } else {
            // WASM
            cv["onRuntimeInitialized"] = () => {
                if (!isReshoot) {
                    processImage(image);
                    if (getOperatingSystem !== 'iOS') {
                        blurImageHandler();
                    }
                } else {
                    setCanvaImg(imageUrl);
                }
            };
        }

        if (getOperatingSystem !== 'iOS') {
            // Getting image brigntness

            if (!isReshoot) {
                getImageLightness(imageUrl, function (brightness) {
                    if (brightness < 60) {
                        setTimeout(() => {
                            setIsLowBrightness(true);
                        }, 500);
                    }
                });
            }
        }
    }, []);

    const processImage = (imgSrc) => {
        let src = cv.imread(imgSrc);
        let dst = new cv.Mat();
        let rect = new cv.Rect(20, 160, 430, 320);
        dst = src.roi(rect);

        // equivalent(ish) to cv.imshow();
        let canvas = document.getElementById("canvaoutput");
        let context = canvas.getContext("2d");

        let imageData = context.createImageData(dst.cols, dst.rows);
        imageData.data.set(new Uint8ClampedArray(dst.data, dst.cols, dst.rows));

        canvas.height = dst.rows;
        canvas.width = dst.cols;

        context.putImageData(imageData, 0, 0);
        cv.imshow("canvaoutput", dst);
        const tempCanvaImg = canvas.toDataURL("image/jpeg", 0.75);
        setCanvaImg(tempCanvaImg);

        src.delete();
        dst.delete();
    };

    const handleReshootRedirect = () => {
        if (retake) {
            retake();
        }
    };

    const handleSubmit = () => {
        if (onSubmit) {
            if(!isReshoot){
                onSubmit(canvaImg, obsText);
            } else {
                retake(stepCount + 1); 
                updateNote(obsText)
            }
        }
    };

    const blurImageHandler = () => {
        let imgElement = document.getElementById("source");
        let src = cv.imread(imgElement);
        let dst = new cv.Mat();
        let men = new cv.Mat();
        let menO = new cv.Mat();
        cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);
        // You can try more different parameters
        const t = cv.Laplacian(src, dst, cv.CV_64F, 1, 1, 0, cv.BORDER_DEFAULT);
        cv.meanStdDev(dst, menO, men);
        // console.log(t, cv.meanStdDev(dst, menO, men), menO.data64F[0], men.data64F[0]);
        if (men.data64F[0] < 4) {
            setTimeout(() => {
                setIsBlur(true);
            }, 500);
        }
        src.delete();
        dst.delete();
    };

    const getImageLightness = (imageSrc, callback) => {
        var img = document.createElement("img");
        img.src = imageSrc;
        img.style.display = "none";
        document.body.appendChild(img);

        var colorSum = 0;

        img.onload = function () {
            // create canvas
            var canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(this, 0, 0);

            var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            var data = imageData.data;
            var r, g, b, avg;

            for (var x = 0, len = data.length; x < len; x += 4) {
                r = data[x];
                g = data[x + 1];
                b = data[x + 2];

                avg = Math.floor((r + g + b) / 3);
                colorSum += avg;
            }

            var brightness = Math.floor(colorSum / (this.width * this.height));
            callback(brightness);
        };
    };


    return (
        <Container
            fluid
            className="reshoot-container p-0 min-vh-100"
        >
            <Row style={{
                marginTop: 120
            }}>
                <Col lg={8} xs={12} className="d-flex justify-content-around mx-auto">
                    <div className="position-relative px-4 mb-4">
                        <img
                            className="d-none"
                            aria-label="image"
                            id="source"
                            src={imageUrl}
                        />
                        <canvas hidden id="canvaoutput" />
                        {canvaImg && (
                            <img style={{
                                border: '3px solid white',
                                borderRadius: 10,
                                width: 350,
                                height: 350,
                            }} src={canvaImg} alt="cropImage" />
                        )}
                        {!canvaImg && (
                            <Skeleton animation="wave" width={400} height={400} />
                        )}
                        {(isBlur || isLowBrightness) && <BlurAndBrightnessModal
                            isOpenDialog={isBlur || isLowBrightness ? true : false}
                            isBlur={isBlur}
                            isLowBrightness={isLowBrightness}
                        />}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={8} xs={12}>
                    <ActionButton
                        {...(!isBlur && !isLowBrightness) ? {
                            leftFaFaIcon: "fa-check",
                            leftType: 'yes-btn',
                            leftFn: handleSubmit
                        } :
                            null
                        }
                        rightType='no-btn'
                        rightFaFaIcon='fa-redo'
                        rightFn={handleReshootRedirect}
                        onChangeTextare={(val) => setObsText(val)}
                        bottomText={obsText}
                        showTextArea={true}
                    />
                </Col>
            </Row>
          
        </Container>
    );
};

export default CameraReshot;
