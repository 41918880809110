import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { getAllReports } from "../../../Redux/Services/AuthService";
import TeethImg from "../../../assets/Dashboard/reports/report-tooth.svg";
import MailCheckIcon from "../../../assets/icon/mail-check.svg";
import cssClasses from "./TotalTest.module.css";
import Header from "../../UI/Header/Header";

const TotalTest = () => {
  const [allReports, setAllReports] = useState([]);

  useEffect(() => {
    getAllReports()
      .then((res) => {
        setAllReports(res.data.data);
      })
      .catch((err) => { });
  }, []);

  const calculateTartarScore = (tartarScore) => {
    if (tartarScore <= 5) {
      return "Low";
    } else if (tartarScore > 5 && tartarScore <= 10) {
      return "Medium";
    } else if (tartarScore > 10) {
      return "High";
    }
  }

  const calculateCavityScore = (cavityScore) => {
    if (cavityScore < 1.5) {
      return "Low";
    } else if (cavityScore > 1.5 && cavityScore < 2.5) {
      return "Medium";
    } else if (cavityScore > 2.5) {
      return "High";
    }
  }

  const dateFormat = (date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
  }

  return (
    <Container fluid>
      <Header title="TEST REPORTS" routeRedirection="/dashboard" />
      <Row className="px-3 margin-top-70px">
        {allReports.map((item, index) => {
          return (
            <Col
              key={index}
              xs={12}
              className={`${cssClasses["totaltest-card"]} my-2 w-100 border-radius-10px`}
            >
              <Link to={"/total-test-report/" + item._id}>
                <Row>
                  <Col xs={7}>
                    <h6 className="black-color font-size-20 font-weight-500">
                      Test_#00{index + 1}
                      {
                        item.is_report_send_to_dentist && (
                          <span className="position-relative mail-check">
                            <img src={MailCheckIcon} alt="MailCheckIcon" />
                          </span>
                        )
                      }

                    </h6>
                  </Col>
                  <Col xs={5} className="text-right">
                    <p className="black-color font-size-14">
                      <i className="far fa-calendar-alt mr-1"></i>
                      {dateFormat(item.createdAt)}
                      {/* {new Date(item.createdAt).toLocaleDateString()} */}
                    </p>
                  </Col>
                </Row>
                <Row className="align-items-center black-color my-2 font-size-14 justify-content-center">
                  <Col xs={3}>Cavity</Col>
                  <Col xs={6}>
                    <div className={`${cssClasses["cavity-bg"]} score-test`}>
                      <div
                        style={{
                          width: `calc(100% - ${item.report_score[0].overall_score[1] * 33.3}%)`,
                        }}
                      ></div>
                    </div>
                  </Col>
                  <Col xs={3}>{calculateCavityScore(item.report_score[0].overall_score[1])}</Col>
                </Row>
                <Row className="align-items-center black-color font-size-14 justify-content-center">
                  <Col xs={3}>Tartar</Col>
                  <Col xs={6}>
                    <div className={`${cssClasses["tartar-bg"]} score-test`}>
                      <div
                        style={{
                          width: `calc(100% - ${item.report_score[0].overall_score[0] * 6.3}%)`,
                        }}
                      ></div>
                    </div>
                  </Col>
                  <Col xs={3}>{calculateTartarScore(item.report_score[0].overall_score[0])}</Col>
                </Row>
              </Link>
            </Col>
          );
        })}

        {/* when there is no report list */}
        {allReports.length === 0 && (
          <div className="col-12 mt-5 text-center">
            <div>
              <p className="black-color font-size-16 mb-5">
                It seems you haven’t checked your teeth conditions yet
              </p>
              <div>
                <img src={TeethImg} alt="dental risk score" />
                <p className="black-color font-size-16 my-3">
                  Get to know your teeth a lot better
                </p>
              </div>

              <Button className="my-5 form-btn font-size-18 font-weight-500 text-uppercase">
                <Link
                  to="/camera-module-two"
                  className="text-white w-100 h-100"
                >
                  Take Scan
                </Link>
              </Button>
            </div>
          </div>
        )}
      </Row>
    </Container>
  );
};
export default TotalTest;
