import axios from "axios";
import { API_HOST } from "./BaseUrl";

const axiosJWT = axios.create();
let refreshTokenTimeout;
// let isHeadersSet = false;
axiosJWT.interceptors.request.use(
    async (config) => {
        const authToken = localStorage.getItem("authToken");
        let currentDate = new Date();
        const decodedToken = JSON.parse(atob(authToken.split('.')[1]));
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            // isHeadersSet = true;
            const userDetails = JSON.parse(localStorage.getItem('user'));
            const tokenRefresh = userDetails?.token;
            const email = userDetails.user.email;
            const responseData = await refreshToken(tokenRefresh, email);
            config.headers["Authorization"] = responseData.data.data.jwtToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const errorHandler = (error, reject) => {
    if (error && error.response) {
        reject({ ...error.response, isError: true });
    } else {
        reject({ message: "Server is disconnected!!!", isError: true });
    }
};

export const createCaseService = (request) => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
    };

    return new Promise((resolve, reject) => {
        return axiosJWT
            .post(API_HOST + `case`, request.caseData, {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const updateCaseService = (request) => {
    request.caseData.folderName=localStorage.getItem('folderName')
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
    };

    return new Promise((resolve, reject) => {
        return axiosJWT
            .patch(API_HOST + `case/update`, request.caseData, {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export const deleteCaseService = (caseId) => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
    };

    return new Promise((resolve, reject) => {
        return axiosJWT
            .delete(API_HOST + `case/${caseId}`, {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export function stopRefreshTokenTimer() {
    localStorage.clear();
    clearTimeout(refreshTokenTimeout);
}

export function refreshToken(refreshTokens, email) {
    const headers = {
        "Content-Type": "application/json"
    };
    return new Promise((resolve, reject) => {
        return axios
            .post(API_HOST + `user/refresh-token`, { token: refreshTokens, email }, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    localStorage.setItem('authToken', response.data.data.jwtToken);
                    // startRefreshTokenTimer(response.headers.authorization, response.data.data.token);
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                stopRefreshTokenTimer();
                if (error && error.response) {
                    reject({ ...error.response, isError: true });
                } else {
                    reject({ message: 'Network error', isError: true });
                }
            });
    });
}

export const getAllCases = (query) => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Authorization: authToken,
    };

    return new Promise((resolve, reject) => {
        return axiosJWT
            .get(API_HOST + `case`, { headers, params: query ? query : undefined  })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getCaseById = (caseId) => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Authorization: authToken,
    };

    return new Promise((resolve, reject) => {
        return axiosJWT
            .get(API_HOST + `case/${caseId}`, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getCsvUploads = () => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Authorization: authToken,
    };

    return new Promise((resolve, reject) => {
        return axiosJWT
            .get(API_HOST + `uploadedCsvFiles`, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const uploadImage = (request) => {
    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(",")[0].indexOf("base64") >= 0)
            byteString = atob(dataURI.split(",")[1]);
        else byteString = unescape(dataURI.split(",")[1]);

        // separate out the mime component
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }
    var blob = dataURItoBlob(request.imageUrl);
    var file = new File([blob], "image.jpeg", { type: "image/jpeg" });

    var formData = new FormData();
    formData.append("image", file);
    formData.append('folderName', localStorage.getItem('folderName')); // Append the string value to FormData
    
    const headers = {
        "Content-Type": "multipart/form-data",
    };
    return new Promise((resolve, reject) => {
        return axiosJWT
            .post(API_HOST + `case/image`, formData, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                return error;
            });
    });
};

// 

export const scanTooth = (request) => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
    };

    return new Promise((resolve, reject) => {
        return axiosJWT
            .post(API_HOST + `case/prediction`, request.data, {
                headers: headers,
            })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                }
                reject(response);
            })
            .catch((error) => {
                errorHandler(error, reject);
            });
    });
};

export function generatePdf(reportData) {
    const authToken = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken,
    };
    return new Promise((resolve, reject) => {
      return axiosJWT
        .post(API_HOST + `case/pdf-download`, reportData, { headers: headers })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          return error;
        });
    });
  }