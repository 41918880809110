import React, { useState, useEffect } from 'react';
import Camera, { IMAGE_TYPES, FACING_MODES } from "react-html5-camera-photo";
import Dialog from "@material-ui/core/Dialog";
import { Container, Row, Col, Card } from 'react-bootstrap';
import FlashOnIcon from "@material-ui/icons/FlashOn";
import FlashOffIcon from "@material-ui/icons/FlashOff";
import { IconButton } from "@material-ui/core";
import CameraModuleBottomImage from "../UI/CameraModule/CameraModuleBottomImage";
import CameraModuleBackground from "../UI/CameraModule/CameraModuleBackground";
import CameraCancelButton from "../UI/CameraModule/CameraCancelButton";
import { GetMobileOperatingSystem } from '../Utils';
import Swal from "sweetalert2";

const fullscreen = true;
const CameraTakeImage = (props) => {
    const { cameraStepsData = [], stepCount = 0, myImages, onImageClick, retake, openCameraScreen } = props;
    const [flashLight, setFlashLight] = useState(true);
    const [cameraFacingMode] = useState("ENVIRONMENT");
    const [operatingSystem, setOperatingSystem] = useState("");
    const [cameraReady, setCameraReady] = useState(false);
    const firstTimeDemo = localStorage.getItem('firstTimeDemo');
    const [isOpen, setIsOpen] = useState(firstTimeDemo ? false : true);
    const handleClosePopup = () => {
        setIsOpen(false);
        localStorage.setItem('firstTimeDemo', true);
    };
    const handleOpenPopup = () => {
        setIsOpen(true);
    };

    const handleTakePhotoAnimationDone = (dataUri) => {
        flashLightHandler(false);
        if (onImageClick) {
            setTimeout(() => {
                onImageClick(dataUri, stepCount);
            }, 500);
        }
    };
    const toggleTorch = () => {
        if (flashLight) {
            flashLightHandler(true);
            setFlashLight(false);
        } else {
            flashLightHandler(false);
            setFlashLight(true);
        }
    };

    const flashLightHandler = (isTorchOpen) => {
        navigator.mediaDevices
            .getUserMedia({
                video: { facingMode: "environment" },
            })
            .then((stream) => {
                const video = document.querySelector("video");
                video.srcObject = stream;
                document.localStream = stream;

                // get the active track of the stream
                const track = stream.getVideoTracks()[0];
                document.localTrack = track;
                video.addEventListener("loadedmetadata", (e) => {
                    window.setTimeout(() => {
                        onCapabilitiesReady(track.getCapabilities());
                    }, 500);
                });

                function onCapabilitiesReady(capabilities) {
                    if (capabilities.torch) {
                        track
                            .applyConstraints({
                                advanced: [{ torch: isTorchOpen }],
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    }
                }
            })
            .catch((err) => {
                // Swal.fire("FlashOff", "", "info");
                console.error("getUserMedia() failed: ", err);
            });
    };

    const setRef = (stream) => { };
    const cameraStop = () => {
        if (document.localStream) {
            document.localStream.getTracks().forEach((track) => {
                track.applyConstraints({ advanced: [{ torch: false }] })
                    .then(res => {
                        track.stop().then(res2 => {
                            document.localStream = undefined;
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            });;
        }
    };
    useEffect(() => {
        toggleTorch();
        const getOperatingSystem = GetMobileOperatingSystem();
        setOperatingSystem(getOperatingSystem);
        return () => {
            cameraStop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Container className={`p-0 ${cameraReady ? '' : 'black-color'}`}>
                <Camera
                    onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                    isFullscreen={fullscreen}
                    idealFacingMode={FACING_MODES[cameraFacingMode]}
                    isImageMirror={false}
                    imageType={IMAGE_TYPES.JPG}
                    className="WebCam"
                    onCameraStart={(stream) => {
                        document.localStream = stream;
                        setRef(stream);
                    }}
                ></Camera>
                <Dialog
                    open={isOpen}
                    keepMounted
                    onClose={handleClosePopup}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <Card style={{
                        padding: '1rem'
                    }}>
                        <Card.Img
                            className="camera-card-image"
                            variant="top"
                            src={cameraStepsData[stepCount].image}
                        />
                        <Card.Body className="p-0 mt-2">
                            <Card.Text className="black-color text-center py-3 px-2 font-weight-600">
                                Demo Pic of {cameraStepsData[stepCount].title} <br />
                                Please shoot a photo like this
                            </Card.Text>
                            <p className="text-center card-link mt-1" onClick={handleClosePopup}>Got it</p>
                        </Card.Body>
                    </Card>
                </Dialog>
                <Row className="position-absolute w-100 top-0 bottom-0 left-0 right-0 m-0" style={{
                    minHeight: '100vh'
                }}>
                    <Col className="p-0">
                        <Row className="overlay-blur mx-0 justify-content-center">
                            <Col xs={12}>
                                <div className="d-flex justify-content-between mt-5">
                                    {operatingSystem !== "iOS" && (
                                        <div className="p-0 ">
                                            <IconButton className="text-white" onClick={toggleTorch}>
                                                {flashLight ? <FlashOffIcon /> : <FlashOnIcon />}
                                            </IconButton>
                                        </div>
                                    )}
                                    <div className="text-overlay p-0">
                                        <p style={{ fontSize: 15 }}>{cameraStepsData[stepCount].title || ''}</p>
                                        <p style={{ fontSize: 13 }}>{cameraStepsData[stepCount].headerText || ''}</p>
                                        <p style={{ fontSize: 13 }}>{cameraStepsData[stepCount].headerMirrorText || ''}</p>
                                        <span className='btn mt-2' style={{ fontSize: 12, marginBottom: 2, backgroundColor: '#048ffc', color: "#ffffff" }} onClick={handleOpenPopup}>Check Demo Pic</span>
                                    </div>
                                    <div className="p-0 mr-5"></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="height-56">
                            <Col xs={1} className="p-0 h-100 black-bg"></Col>
                            <Col xs={10} className="p-0">
                                <div className={`overlay`}>
                                    <div className="overlay-element top-left"></div>
                                    <div className="overlay-element top-right"></div>
                                    <div className="overlay-element bottom-left"></div>
                                    <div className="overlay-element bottom-right"></div>
                                    <div className="tooth-overlay-1">
                                        <img src={cameraStepsData[stepCount].overlay} alt='overlay1' />
                                    </div>
                                </div>
                            </Col>
                            <Col xs={1} className="p-0 h-100 black-bg"></Col>
                        </Row>

                        <div className="bottom-text-overlay d-flex py-4">
                            <span className="pt-2 bottom-mirror-text font-size-17">
                                <div className="mb-2 mt-1">
                                    <Row className="align-items-end mx-0">
                                        <Col
                                            lg={12}
                                            xs={12}
                                            className="px-0 d-flex justify-content-around mx-auto"
                                        >
                                            {myImages.map((myImage, index) => {
                                                return myImage.image_name === undefined ? (
                                                    <CameraModuleBottomImage
                                                        isImage={false}
                                                        key={index}
                                                        count={index + 1}
                                                        image={myImages[index].image_url}
                                                        onClick={() => openCameraScreen(index)}
                                                        imgHighlight={index === stepCount}
                                                    />
                                                ) : (
                                                    <CameraModuleBottomImage
                                                        isImage={true}
                                                        key={index}
                                                        image={myImage.image_url}
                                                        imgHighlight={index === stepCount}
                                                        onClick={() => retake && retake(index)}
                                                    />
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </div>
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>

    );
};

export default CameraTakeImage;