import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from '@material-ui/core/Slide';
import { CircularProgress, Divider, FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Col, Container, Row } from "react-bootstrap";
import { ReviewReportPostAPI } from "../../../Redux/Services/ReportService";
import SnackBarMessages from "../Snackbar/Snackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogMessage = (props) => {
  const [open, setOpen] = useState(props.open);
  const [isLoader, setIsLoader] = useState(false);
  const [reason, setReason] = useState('');
  const [snackBarData, setSnackBarData] = useState({
    isOpen: false,
    message: '',
    isSuccess: false
  });


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const selectDetectionsHandler = (event) => {
    setReason(event.target.value);
  }

  const submitReviewReport = async () => {
    setSnackBarData({ isOpen: false });
    setIsLoader(true);
    try {
      const objData = {
        reason: reason,
        reportId: props.reportId,
        image: props.image,
        isCavityOrTartar: props.isCavityOrTartar
      }
      const response = await ReviewReportPostAPI(objData);
      setIsLoader(false);
      if (response.status === 200) {
        setSnackBarData({ isOpen: true, message: 'Review submitted successfully', isSuccess: true });
        setTimeout(() => {
          handleClose();
        }, 2000);
        // setOpen(false);
        // props.onClose();
      }
    } catch (error) {
      setIsLoader(false);
      setSnackBarData({ isOpen: true, message: 'Something went wrong', isSuccess: false });
    }

  }

  return (
    <Container>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {
          snackBarData.isOpen && <SnackBarMessages
            open={snackBarData.isOpen}
            message={snackBarData.message}
            type={snackBarData.isSuccess ? 'success' : 'error'}
            timing={5000}
            position='top-right'

          />
        }
        <Container>
          <Row>
            <Col xs={12} className="my-3 text-uppercase font-weight-500 black-color">Please select any of these</Col>
            <Col xs={12} className="px-4">
              <FormControl className="w-100" component="fieldset">
                <RadioGroup
                  // defaultValue="I think there are wrong detections"
                  onChange={selectDetectionsHandler}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="I think there are wrong detections"
                    control={<Radio color="primary" />}
                    label="I think there are wrong detections" />
                  <Divider />
                  <FormControlLabel
                    value="I think there are less detections"
                    control={<Radio color="primary" />}
                    label="I think there are less detections" />
                  <Divider />
                  <FormControlLabel
                    value="I think there are more detections"
                    control={<Radio color="primary" />}
                    label="I think there are more detections" />
                </RadioGroup>
              </FormControl>
            </Col>
          </Row>
        </Container>
        {isLoader && <div className="text-center my-2"><CircularProgress /></div>}
        {
          !isLoader && (
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={submitReviewReport} color="primary" autoFocus>
                Submit
              </Button>
            </DialogActions>
          )
        }
      </Dialog>
    </Container>
  );
};

export default DialogMessage;
