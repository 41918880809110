import React, { Component } from "react";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import * as Yup from "yup";

import { connect } from "react-redux";
import { verifyCodeAction } from "../../../Redux/Action/AuthAction";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";
import SnackBarMessages from "../../UI/Snackbar/Snackbar";

class VerifyForgotPwdCode extends Component {
  constructor() {
    super();
    this.state = {
      error: undefined,
      email: "",
      data: undefined,
      isLoading: false
    };
  }
  componentDidMount() {
    const data = this.props?.location?.state?.data;
    if (data) {
      this.setState({ email: data.email });
    } else {
      return this.props.history.push('/');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.verifyCode !== this.props.verifyCode) {
      this.setState({ error: this.props.verifyCode.message, isLoading: false });

      if (this.props.verifyCode.message && !this.props.verifyCode?.response) {
        Swal.fire(this.props.verifyCode.message, '', 'error');
      }

      if (this.props.verifyCode?.response?.data) {
        this.setState({ data: this.props.verifyCode.response.data, isLoading: false });
        this.props.history.push("/new-password", {
          data: this.props.verifyCode.response.data,
        });
      }
    }
  }

  render() {
    return (
      <div className="container h-100">
        <SnackBarMessages
            open={true}
            position="top-right"
            timing={10000}
            type="success"
            message="Code sent to your email. Pls check your spam folder also"
          />
        <div className="row h-100 align-items-center justify-content-center mb-3">
          <div className="col">
            <h4 className="text-center">
              Forgot Password Code
            </h4>
            <Formik
              validate={(values) => {
                let errors = {};
                if (this.state.error) {
                  this.setState({ error: '' });
                }
                if (!values.forgotpass_code) {
                  errors.forgotpass_code = "Code is Required";
                }
                return errors;
              }}
              validationSchema={Yup.object().shape({
                forgotpass_code: Yup.string().required(
                  "Code is Required"
                ),
              })}
              initialValues={{ forgotpass_code: "", email: "" }}
              onSubmit={(values, { setSubmitting }) => {
                values.email = this.state.email;
                this.props.dispatch(verifyCodeAction(values));
                setSubmitting(false);
                this.setState({ isLoading: true });
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <input
                      id="forgotpass_code"
                      name="forgotpass_code"
                      type="text"
                      placeholder="Code"
                      className="form-input px-3 my-3 fontAwesome"
                      value={values.forgotpass_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.forgotpass_code && touched.forgotpass_code && (
                      <div className="text-danger">
                        {errors.forgotpass_code}
                      </div>
                    )}

                    {this.state.email ? (
                      <div className="text-danger m-2">{this.state.error}</div>
                    ) : (
                      <div className="text-success m-2">{this.state.error}</div>
                    )}

                    <div className="text-center">
                      {this.state.isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          type="submit"
                          className="form-btn"
                        >
                          Submit Code
                        </Button>
                      )}
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    verifyCode: state.verifyCode,
  };
};

export default connect(mapStateToProps, null)(VerifyForgotPwdCode);
