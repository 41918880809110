import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import TermsImg from "../../../../assets/Dashboard/Drawer/Terms_Icon.svg";
import Header from "../../../UI/Header/Header";

const TermsOfUse = () => {
  return (
    <Container>
      <Header title="Terms of use" routeRedirection="/dashboard" />
      <Row className="margin-top-70px">
        <Col>
          <img src={TermsImg} alt="head" className="mx-auto d-block my-5" />
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="privacyPolicy-header">Terms of Use</h4>
          <p className="privacyPolicy-content">
            Welcome to Toothlens website and smartphone application ("Online
            Services"), an online information and communication service
            provided  ("Toothlens" or "We" or "Us"), subject to your agreement
            with all of these terms and conditions ("Terms"). Toothlens is the
            software product of  a company currently registered as Toothlens
            Healthcare in INDIA and the US.
          </p>
          <p className="privacyPolicy-content">
            Toothlens may modify these Terms at any time, and such modifications
            shall be effective immediately upon posting the modified Terms on
            Toothlens website. We will ask you to acknowledge that you have
            received the modified Terms before accessing the Services.
            Concerning any changes to the Terms that involve material adverse
            changes, such as deteriorated entitlements or higher costs, we will
            offer subscribers an opportunity to opt-out. You can opt-out by
            sending an email to
            <a href="mailto:support@toothlens.com">support@toothlens.com</a>.
          </p>
          <p className="privacyPolicy-content">
            If you do not accept these updated Terms or do not wish to be bound
            by changes following posted notices, you should discontinue the use
            of the Websites and the Service. Toothlens may offer website
            visitors the opportunity to participate in additional features or
            services from time to time. You may be required to enter into other
            agreements or provide authorizations before accessing such features
            or services.
          </p>
          <p className="privacyPolicy-content">
            If you do not agree with all the Terms, or under the age of eighteen
            (18), do not use the Service. Please review all of the Terms and the
            Instructions For Use carefully before using the Service. In addition
            to these Terms, the Online Services are also governed by our Privacy
            Policy and Cookie Policy.
          </p>

          <h4 className="privacyPolicy-header">The App</h4>
          <p className="privacyPolicy-content">
            Toothlens is an artificial intelligence-powered dental hygiene
            self-check tool. The app's goal is to inform you about dental health
            generally and provide you with the opportunity to look for
            indicators of some of the most common dental concerns such as tooth
            decay, visible plaque, and root exposure, and to indicate your
            dental health risks over time. We aim to create higher awareness
            about dental health by sending you reminders and providing you with
            information to keep you on track with your dental health journey.
          </p>

          <h4 className="privacyPolicy-header">Toothlens Check</h4>
          <p className="privacyPolicy-content">
            The technology is intended to provide a risk indicator for the most
            common dental health concerns, based on photographs taken by you on
            the mobile device that you upload to the App.
          </p>
          <p className="privacyPolicy-content">
            We perform quality control of the App with a team of algorithm and
            image recognition experts collaborating with dental professionals.
            In cases where the potential dental health risk can be identified
            through the App, we will provide feedback to you about possible next
            steps. The goal of the app is to help you understand your dental
            health. It is not intended to replace traditional dental health risk
            evaluation methods, is not a diagnosis, and is not a substitute for
            a dental professional's visits. The App is not intended to be used
            by persons under 18 years old. The accuracy of the App is dependent
            on the photographs taken by you on your mobile device and provided
            through the App.
          </p>
          <p className="privacyPolicy-content">
            The App may be modified by us at any time, without notice.
          </p>
          <div>
            <b> No doctor-patient relationship</b>
          </div>
          <p className="privacyPolicy-content">
            The Services are provided solely to you (Personal Services). No
            interactions within the Services create a doctor-patient
            relationship as defined under the relevant laws. Toothlens is
            designed to support the dental health decisions and choices that you
            make for yourself. In the context of health and wellness, your
            preferences should always be made in conjunction with your attending
            dental health care provider.
          </p>
          <div>
            <b>2. Data collection and data privacy</b>
          </div>
          <p className="privacyPolicy-content">
            Services can be provided to you when we collect specific data. The
            goals for which certain types of data are collected are listed
            below. For further information on how we collect your data, we refer
            to our Privacy Policy.
          </p>
          <p className="privacyPolicy-content">
            Your data is collected to provide you with the services, including
            the risk assessment, feedback, customer care, marketing,
            non-commercial communications, research activities, managing and
            improving our Services, and legal obligations.
          </p>
          <div>
            <b>3. Health Follow Up Policy</b>
          </div>
          <p className="privacyPolicy-content">
            At Toothlens, we are continually improving our services by
            performing research activities. As part of the research activities,
            we may perform checks on the photos to verify the Toothlens Check's
            accuracy. In case we think that it will benefit your health if we
            share the outcome of those checks, we may contact you outside of our
            regular automated communication. We will inform you about these
            findings by sending you a message to the email address that you have
            provided to us as your username. In case of a severe result, and
            when we cannot get in touch with you via your email address, we may
            try to contact you via the phone number you provided to us.
          </p>
          <div>
            <b>4. No Warranties</b>
          </div>
          <p className="privacyPolicy-content">
            You understand and agree that Toothlens and any Services, content,
            or information contained on or provided by Toothlens is provided on
            an "as is'' basis. Toothlens does not make any express or implied
            warranties, representations, or endorsements whatsoever (including
            without limitation warranties of title or non-infringement, or the
            implied warranties of merchantability or fitness for a particular
            purpose) about the service or any merchandise, information, or
            service provided through the service.
          </p>
          <p className="privacyPolicy-content">
            Toothlens does not guarantee that use of Toothlens Check will be
            free from technological difficulties including, but not limited to,
            unavailability of information, downtime, service disruptions,
            viruses, or worms, and you understand that you are responsible for
            implementing sufficient procedures and checkpoints to satisfy your
            particular requirements for accuracy of data input and output.
          </p>
          <div>
            <b>5. Fees for Services</b>
          </div>
          <p className="privacyPolicy-content">
            Toothlens offers certain enhanced features of the Services which you
            can purchase as six months or one year (“Subscription”). A
            description of features associated with Subscriptions is available
            via the Services. When you purchase a Subscription or a Product
            (each, a “Transaction”), we may ask you to supply additional
            information relevant to your Transaction, such as your credit card
            number, the expiration date of your credit card and your address(es)
            for billing and delivery (such information, “Payment Information”).
            You represent and warrant that you have the legal right to use all
            payment method(s) represented by any such Payment Information. The
            amounts due and payable by you for a Transaction through the
            Services will be presented to you before you place your order. If
            you choose to initiate a Transaction via the Services, you authorize
            us to provide your Payment Information to third party service
            providers so we can complete your Transaction and agree (a) to pay
            the applicable fees and any taxes; (b) that Toothlens may charge
            your credit card or third party payment processing account,
            including, but not limited to, your account with the app store or
            distribution platform (like the Apple App Store, Google Play or the
            Amazon Appstore) where the App is made available (each, an “App
            Provider”), for verification, pre-authorization and payment
            purposes; and (c) to bear any additional charges that your App
            Provider, bank or other financial service provider may levy on you
            as well as any taxes or fees that may apply to your order. You’ll
            receive a confirmation email after we confirm the payment for your
            order. Your order is not binding on Toothlens until accepted and
            confirmed by Toothlens. All payments made are non-refundable and
            non-transferable except as expressly provided in these Terms.
          </p>
          <p className="privacyPolicy-content">
            If you have any concerns or objections regarding charges, you agree
            to raise them with us first and you agree not to cancel or reject
            any credit card or third party payment processing charges unless you
            have made a reasonable attempt at resolving the matter directly with
            Calm.
          </p>
          <p className="privacyPolicy-content">
            Toothlens reserves the right to not process or to cancel your order
            in certain circumstances, for example, if your credit card is
            declined, if we suspect the request or order is fraudulent, or in
            other circumstances Toothlens deems appropriate in its sole
            discretion. Toothlens also reserves the right, in its sole
            discretion, to take steps to verify your identity in connection with
            your order. You may need to provide additional information to verify
            your identity before completing your Transaction (such information
            is included within the definition of Payment Information). Toothlens
            will either not charge you or refund the charges for orders that we
            do not process or cancel.
          </p>
          <div>
            <b>6. Refund</b>
          </div>
          <p className="privacyPolicy-content">
            For 6 months and yearly purchases made through the{" "}
            <a href="https://toothlens.com/" target="_blank" rel="noreferrer">
              toothlens.com
            </a>{" "}
            website or any Play/App Store, we are usually able to accommodate
            refund requests made within 30 days of your purchase.
          </p>

          <div>
            <b>
              7. Subscriptions Automatically Renew Until You Cancel & How to
              Cancel Your Subscription
            </b>
          </div>
          <p className="privacyPolicy-content">
            All amounts are payable and charged: (i) for one-off purchase (e.g.
            lifetime Subscription), at the time you place your order; and (ii){" "}
            <b>
              For 6 monthly or yearly subscriptions, at the beginning of the
              subscription and, because each such subscription renews
              automatically for an additional period equal in length to the
              expiring subscription term until you cancel it, at the time of
              each renewal until you cancel
            </b>
            , using the Payment Information you have provided. You must cancel
            your 6 monthly or yearly Subscription before it renews to avoid the
            billing of the fees for the next Subscription period. If you
            purchase your Subscription via the Site,
            <b>
              you can cancel the renewal of your subscription at any time
            </b>{" "}
            by contacting us by email at{" "}
            <a href="mailto: support@toothlens.com.">support@toothlens.com.</a>{" "}
            If you purchase your Subscription via an App Provider,{" "}
            <b>you can cancel the renewal of your subscription at any</b> time
            with the App Provider. You will not receive a refund for the fees
            you already paid for your current subscription period and you will
            continue to receive the Services ordered until the end of your
            current Subscription period.
          </p>
          <div>
            <b>8. Changes to Price Terms for Subscriptions</b>
          </div>
          <p className="privacyPolicy-content">
            Toothlens reserves the right to change its pricing terms for
            Subscriptions at any time and Toothlens will notify you in advance
            of such changes becoming effective. Changes to the pricing terms
            will not apply retroactively and will only apply for Subscription
            renewals after such changed pricing terms have been communicated to
            you. If you do not agree with the changes to Toothlens’s pricing
            terms then you may choose not to renew your Subscription in
            accordance with the section “Subscriptions Automatically Renew Until
            You Cancel & How to Cancel Your Subscription.”
          </p>
          <div>
            <b>9.Future Functionality</b>
          </div>
          <p className="privacyPolicy-content">
            You agree that your purchases are not contingent on the delivery of
            any future functionality or features, or dependent on any oral or
            written public comments made by Toothlens regarding future
            functionality or features.
          </p>

          <div>
            <b>10. Discontinuance of Services</b>
          </div>
          <p className="privacyPolicy-content">
            Toothlens reserves the right to modify, discontinue, temporarily or
            permanently, the Services, or any part thereof, at any time, with or
            without notice. When appropriate, Toothlens will give you a
            proportional refund. Also, your access to the Services may be
            discontinued at any time if you violate the Terms of this agreement
            or if we determine, if you purposely misuse the service or act in an
            unethical way, or for any other reason, that it is no longer
            appropriate for you to have access to the Services.
          </p>
          <div>
            <b>11. Disclaimer, Limitations, and Exclusions of Liability</b>
          </div>
          <p className="privacyPolicy-content">
            <b>1.</b> The Toothlens Check is being provided to monitor, track,
            and understand your dental health. Online Services are intended to
            help understand and support self-assessments. Still, they are not to
            be used or relied on solely for any diagnostic or treatment
            purposes, and they do not replace a visit to the doctor. Any
            reliance by you is at your discretion and risk.  The risk assessment
            is based on a smartphone generated picture, which may not be an
            actual representation of any discomfort you experience; therefore,
            it is highly recommended that you discuss your risk factors and your
            assessment results with your doctor. Toothlens shall not be liable
            for any decision you take to discuss your dental health, personal
            risk factors, and your assessment results with your doctor.
          </p>
          <p className="privacyPolicy-content">
            <b>2.</b> Toothlens makes no representation or warranties relating
            to the information contained in the online and in-app library. We do
            not warrant that the Online Services information will be available,
            complete, completely accurate, up-to-date.
          </p>
          <p className="privacyPolicy-content">
            <b>3.</b> Notwithstanding the previous, Toothlens reserves the
            right, but is not obligated, to refuse to post any content and
            remove your access to the Service.
          </p>
          <p className="privacyPolicy-content">
            <b>4.</b> We have no responsibility or liability for any action that
            you do or do not take because of or despite the App's information.
            If you are experiencing a medical emergency or you have any concern
            about your medical condition in any way, you should seek immediate
            medical attention. You should not delay seeking medical advice, stop
            treatment, or disregard medical advice because of the information
            contained in the Toothlens Check.
          </p>
          <p className="privacyPolicy-content">
            <b>5.</b> Toothlens does not guarantee the correct functioning of
            Toothlens Check if you use Toothlens Check while you are subject to
            a contra-indication for the use.
          </p>
          <p className="privacyPolicy-content">
            <b>6.</b> To the maximum extent permitted by law, our liability to
            you is limited at our option to resupplying of Toothlens Check.
          </p>
          <p className="privacyPolicy-content">
            <b>7.</b> To the extent that our website and the information and
            services on our website are provided free of charge, we will not be
            liable for any loss or damage of any nature.
          </p>
          <p className="privacyPolicy-content">
            <b>8.</b> We will not be liable to you regarding any losses arising
            out of any event or events beyond our reasonable control.
          </p>
          <p className="privacyPolicy-content">
            <b>9.</b> The Services are for personal use only. Therefore we will
            not be liable to you regarding any business losses, including
            (without limitation) loss of or damage to profits, income, revenue,
            use, production, anticipated savings, business contracts, commercial
            opportunities, or goodwill.
          </p>
          <p className="privacyPolicy-content">
            <b>10.</b> You agree that you will not bring any claim personally
            against our officers or employees in respect of any losses you
            suffer in connection with the website or these Terms (this will not,
            of course, limit or exclude the liability of the limited liability
            entity itself for the acts and omissions of our officers and
            employees).
          </p>
          <p className="privacyPolicy-content">
            <b>11.</b> We shall not be liable for any content you publish on
            social media using a social media plugin on our website.
          </p>
          <p className="privacyPolicy-content">
            <b>12. Copyright and Trademark Information</b> <br /> The mark
            "Toothlens" and the toothlens logo are trademarks and service marks
            of toothlens. International copyright laws protect the content and
            design of Toothlens. You may not copy, reproduce, republish, upload,
            post, display, transmit, or frame any of these materials without
            prior written consent from the copyright owners, except that you may
            view, download, display, and print a single copy of these materials
            on a single computer for personal, non-commercial use only, so long
            as:
          </p>
          <ul className="px-3 py-1">
            <li>You do not alter or modify the materials in any way;</li>
            <li>
              You include all applicable notices and disclaimers (including
              copyright notices). You do not use the materials in a way that
              suggests an association with Toothlens or an affiliated Toothlens
              entity. You understand and agree that these materials' title shall
              not pass to you or any other user.
            </li>
          </ul>
          <p className="privacyPolicy-content">
            <b>13. Consumer rules</b> <br /> Depending on your country, consumer
            protection rules may apply. We recommend you to take note of these.
          </p>
          <p className="privacyPolicy-content">
            <b>14. Governing Law & Jurisdiction</b> <br /> These Terms are
            governed by the laws of India and subject to the exclusive
            jurisdiction of the courts of India (insofar as this choice of law
            and forum is not set aside by (super)mandatory laws of other
            countries involved).
          </p>
          <p className="privacyPolicy-content">
            <b>15. Contact Us</b> <br /> You may contact Toothlens at{" "}
            <a href="mailto:support@toothlens.com">support@toothlens.com </a>
            at any point in time.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default TermsOfUse;
