import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import DialogMessage from "../Dialog/Dialog";
import ReviewFlagExplainer from "../Card/ExplainerCard/ReviewFlagExplainer";

const SliderCavityTartarCard = (props) => {

  const [isDialog, setIsDialog] = useState(false);
  const [image, setImage] = useState('');
  const isShowReviewFlageExplainer = JSON.parse(localStorage.getItem('isShowReviewFlageExplainer')) === null ? true : false;

  const reviewHandler = (obj) => {
    const splitImg = obj.split('-').join('.');
    setImage(splitImg);
    setIsDialog(true);
  }

  const onCloseDialog = () => {
    setIsDialog(false);
  }

  const settings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // centerMode: true,
    cssEase: "linear",
  };

  return (
    <>
      <Slider {...settings}>
        {props.reportScoreImg.map((item, index) => {
          return (
            <div key={item} className="cavity-slider">
              <Card className="cavity-card">
                <Card.Img
                  variant="top"
                  src={
                    props.imgUrl +
                    item.split("-").join(".")
                  }
                  alt="report images"
                />
              </Card>

              <div className="px-3 black-color font-size-17 mt-5">
                {props.contents.length > 0 &&
                  props.contents[index]?.subText.map((item) => {
                    return (
                      <div key={item} className="row mb-3">
                        <div className="col-2 text-center pr-0">
                          <i className="far fa-circle primary-color font-size-16 circle-icon" />
                        </div>
                        <div className="col-10 pl-0 pr-5">
                          <p>{item}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="px-3 primary-color font-size-20 text-right">
                <i className="far fa-flag" onClick={() => reviewHandler(item)}></i>
              </div>
            </div>
          );
        })}
      </Slider>
      {isShowReviewFlageExplainer && <ReviewFlagExplainer message="Click here if you have any issue with the detection" explainerCardName="flag" />}
      {isDialog && <DialogMessage isCavityOrTartar={props.isCavityOrTartar} image={image} reportId={props.reportId} open={isDialog} onClose={onCloseDialog} />}
    </>

  );
};

export default SliderCavityTartarCard;
