import * as types from "../Action";

const initState = {
  message: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  const response = action.payload;

  switch (action.type) {
    case types.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        response,
        message: "Forgot password code verified successfully",
      };
    case types.VERIFY_CODE_ERROR:
      return {
        message: "Invalid Code!",
      };

    default:
      return state;
  }
}
