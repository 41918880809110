import { takeLatest } from 'redux-saga/effects';
import { registerSaga, loginSaga ,reportSaga,reportUploadSaga,forgotPasswordSaga,verifyCodedSaga,createPasswordSaga,changePasswordSaga,profileUpdateSaga, verifyUserCodedSaga, veterinaryCaseSaga} from './AuthSaga';

import * as types from '../Action';


export default function* watchUserAuthentication() {
  yield takeLatest(types.REGISTER_USER, registerSaga);
  yield takeLatest(types.LOGIN_USER, loginSaga);
  yield takeLatest(types.USER_REPORT, reportSaga);
  yield takeLatest(types.USER_REPORT_UPLOAD, reportUploadSaga);
  yield takeLatest(types.FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(types.VERIFY_CODE, verifyCodedSaga);
  yield takeLatest(types.VERIFY_USER_CODE, verifyUserCodedSaga);
  yield takeLatest(types.CREATE_NEW_PASSWORD, createPasswordSaga);
  yield takeLatest(types.CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(types.USER_PROFILE, profileUpdateSaga);
  yield takeLatest(types.VETERINARY_CASE, veterinaryCaseSaga);
}