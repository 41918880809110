import React, { useState, useEffect } from "react";
import "date-fns";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ImageUploading from "react-images-uploading";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import { profileAction } from "../../../../Redux/Action/AuthAction";
import userProfile from "../../../../assets/Dashboard/Mask-Group.svg";
import Header from "../../../UI/Header/Header";
import "./ProfileDetails.css";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            width: "100%",
        },
        container: {
            height: "100vh",
        },
        button: {
            margin: "20px",
        },
    },
}));

const ProfileForm = (props) => {
    const [userDetails, setUserDetails] = useState(
        JSON.parse(localStorage.getItem("user"))?.user
    );
    const classes = useStyles();
    const [images, setImages] = React.useState([]);
    const [error, setError] = useState("");
    const [isdatePickerOpen, setIsdatePickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState(
        new Date("2014-08-18T21:11:54")
    );
    const [profileDetailsData, setProfileDetailsData] = useState(userDetails);
    const heading = props.location.state?.title;
    let history = useHistory();
    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const setPickerStatus = (status) => {
        setIsdatePickerOpen(status);
    };

    useEffect(() => {
        if (!userDetails) {
            history.push("/dashboard");
        }
        if (userDetails && (!userDetails.phone_number || !userDetails.gender || !userDetails.state || !userDetails.city || !userDetails.country)) {
            history.push("/profile-form");
         }
        setError(props.profileResponse.message);
        if (
            props.profileResponse.message === "Your profile updated successfully!"
        ) {
            const updatedUserData = props.profileResponse.response.data.data;
            const userData = { ...userDetails, ...updatedUserData };
            localStorage.setItem("user", JSON.stringify({ user: userData }));
            Swal.fire("User data updated successfully!", "", "success");
            props.dispatch({ type: "USER_PROFILE_CLEAR" });
            history.push("/dashboard");
        }
        if (props.userData?.response?.data?.data?.user) {
            setProfileDetailsData(props.userData.response.data.data.user);
        } else {
            setProfileDetailsData(userDetails);
        }
    }, [props.profileResponse, props.userData?.response?.data?.data?.user]);

    return (
        <Container className="">
            <Header title={heading ? heading : 'Profile details'} routeRedirection="/dashboard" />
            <div className='margin-top-100'></div>
            <div className="my-5">
                <div className="upload__image-wrapper">
                    <button
                        className="image-upload-btn"
                    >
                    </button>
                    <div className="image-border mx-auto">
                        {[].map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image.data_url} alt="logo" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Row >
                <div className="vetlens-page vetlens-page-height">
                    <Col xs={12} className="text-center my-5">
                        <div>
                            <h2 className='text-capitalize'>{profileDetailsData.first_name || ''} {' '} {profileDetailsData.last_name || ''}</h2>
                            <h6 className='text-capitalize'>{profileDetailsData.gender ?? ''} | {profileDetailsData.age ?? ''}</h6>
                        </div>
                    </Col>
                    <Col xs={12} className="mt-4">
                        <Row>
                            <Col><div className='profile-details-label ml-3'>Vetnery ID</div></Col>
                            <Col><div className='profile-details-value'>{profileDetailsData.vetnery_id}</div></Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="mt-4">
                        <Row>
                            <Col><div className='profile-details-label ml-3'>State</div></Col>
                            <Col><div className='profile-details-value'>{profileDetailsData.state}</div></Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="mt-4">
                        <Row>
                            <Col><div className='profile-details-label ml-3'>Country</div></Col>
                            <Col><div className='profile-details-value'>{profileDetailsData.country}</div></Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="mt-4">
                        <Row>
                            <Col><div className='profile-details-label ml-3'>Contact details</div></Col>
                            <Col><div className='profile-details-value'>{profileDetailsData.phone_number}</div></Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="mt-4">
                        <Row>
                            <Col><div className='profile-details-label ml-3'>Email ID</div></Col>
                            <Col><div className='profile-details-value'>{profileDetailsData.email}</div></Col>
                        </Row>
                    </Col>
                    <div className='position-absolute bottom-0  mb-5 add-case-btn primary-text'>
                        <Link to={'/profile-form'}>
                            <Button
                                variant="primary"
                                size="lg"
                                className='form-btn'
                            >
                               Edit profile
                            </Button>
                        </Link>
                    </div>
                </div>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: state.login,
        profileResponse: state.userProfile,
    };
};

export default connect(mapStateToProps, null)(ProfileForm);
