import * as types from '../Action';

const initState = {
  message: undefined, 
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  const response = action.payload;

  switch (action.type) { 
    case types.FORGOT_PASSWORD_SUCCESS: 
      return {
        ...state,
        response,
        message: "Verify Code send to your Email", 
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state, 
        message : "Invalid Email!!!",
      }

    default:
      return state;
  }
};
