import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as EmailValidator from "email-validator";

import { Formik } from "formik";
import * as Yup from "yup";
import { registerUserAction } from "../../../../Redux/Action/AuthAction";
import { connect } from "react-redux";

import classes from "../signUp.module.css";
import { Button, CircularProgress } from "@material-ui/core";
import SnackBarMessages from "../../../UI/Snackbar/Snackbar";
class Signin extends Component {
    constructor(props) {
        super();
        this.state = {
            error: undefined,
            status: "",
            isLoading: false,
            message: "",
            userDetails: JSON.parse(localStorage.getItem("signupDetails2")),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.signupResponse !== this.props.signupResponse) {
            if (this.props.signupResponse?.message) {
                this.setState({
                    error: this.props.signupResponse.message,
                    isLoading: false,
                    message: this.props.signupResponse.message,
                });
            }
        }
        if (
            prevProps.signupResponse.response !== this.props.signupResponse.response
        ) {
            this.setState({
                status: this.props.signupResponse.response.status,
                isLoading: false,
                message: "Code sent to your email. Pls check your spam folder also",
            });
            localStorage.removeItem("signupDetails");
            localStorage.removeItem("signupDetails2");
            console.log('this.props', this.props);
            this.props.history.push("/verify-user-code", {
                email: this.props.signupResponse.response.data.email,
                comeBack: this.props.location?.state?.comeBack !== undefined ? true : undefined,
            });
        }
    }

    render() {
        const { history } = this.props;
        // let formData = this.props.location.state.values;

        const handleLoginNavigation = () => {
            history.push("/login", {
                comeBack:
                    this.props.location.state.comeBack !== undefined ? true : undefined,
            });
        };

        return (
            <Container fluid>
                <h4 className='page-title mt-4 mb-4'>Create Account</h4>
                {this.state.error && (
                    <SnackBarMessages
                        open={true}
                        type="error"
                        position="top-right"
                        timing={5000}
                        message={this.state.message}
                    />
                )}
                <Formik
                    validate={(values) => {
                        let errors = {};
                        if (!values.name) {
                            errors.name = "Name is Required";
                        }
                        if (!values.email) {
                            errors.email = "Email is Required";
                        } else if (!EmailValidator.validate(values.email)) {
                            errors.email = "Invalid email address.";
                        }
                        if (!values.password) {
                            errors.password = "Password is Required";
                        } else if (values.password.length < 8) {
                            errors.password = "Password must be 8 characters long.";
                        }
                        if (!values.confirm_password) {
                            errors.confirm_password = "Confirm password is Required";
                        } else if(values.password !== values.confirm_password) {
                            errors.confirm_password = "The password and confirmation password do not match."

                        }
                        return errors;
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required("Name is required"),
                        email: Yup.string().email().required(" Email is Required"),
                        password: Yup.string()
                            .required("No password provided.")
                            .min(8, "Password is too short - should be 8 chars minimum."),
                        confirm_password: Yup.string().required("Confirm password is required"),
                    })}
                    initialValues={{
                        name: "",
                        email: "",
                        password: "",
                        confirm_password: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        // this.setState({ isLoading: true, error: false });
                        let data = {
                            ...values,
                        };
                        localStorage.setItem("signupDetails2", JSON.stringify(values));
                        this.props.dispatch(registerUserAction(data));
                        setSubmitting(false);
                        this.setState({ isLoading: true, error: false });
                    }}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = props;

                        return (
                            <form onSubmit={handleSubmit}>
                                <div className='mb-1'>
                                    <label>Doctor Name</label>
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        className="form-input my-2 fontAwesome"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.name && touched.name && (
                                        <div className="text-danger mb-2 ml-2">
                                            {errors.name}
                                        </div>
                                    )}
                                </div>
                                <div className='mb-1'>
                                    <label>Email Address</label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="text"
                                        placeholder="Enter email address here"
                                        className=" form-input my-2 fontAwesome"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.email && touched.email && (
                                        <div className="text-danger mb-2 ml-2">{errors.email}</div>
                                    )}
                                </div>

                                <div className='mb-1'>
                                    <label>Password</label>
                                    <input
                                        id="password"
                                        name="password"
                                        type='password'
                                        placeholder="Enter Password"
                                        className={`form-input my-2 ${classes["fontAwesome"]}`}
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.password && touched.password && (
                                        <div className="text-danger">{errors.password}</div>
                                    )}
                                </div>
                                <div className='mb-1'>
                                    <label>Confirm password</label>
                                    <input
                                        id="confirm_password"
                                        name="confirm_password"
                                        type='password'
                                        placeholder="Re-enter password"
                                        className={`form-input my-2 ${classes["fontAwesome"]}`}
                                        value={values.confirm_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.confirm_password && touched.confirm_password && (
                                        <div className="text-danger">{errors.confirm_password}</div>
                                    )}
                                </div>
                                <div className="text-center mt-5">
                                    {this.state.isLoading || isSubmitting ? (
                                        <CircularProgress />
                                    ) : (
                                        <Button
                                            type="submit"
                                            // disabled={isSubmitting}
                                            className="form-btn"
                                        >
                                            Sign up
                                        </Button>
                                    )}
                                </div>
                                <Row>
                                    <Col xs={12}>
                                        <h4 className={`primary-text my-3`}>
                                            <span>Already have an account. <strong onClick={handleLoginNavigation}>Sign in</strong> </span>
                                        </h4>
                                    </Col>
                                </Row>
                            </form>
                        );
                    }}
                </Formik>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        signupResponse: state.signup,
    };
};

export default connect(mapStateToProps, null)(Signin);
