import React, { useState, useEffect } from 'react';
// import { Row, Col } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import BackWhIcon from "../../../assets/icon/back-wh-icon.svg";
import CloseIcon from "../../../assets/icon/close-icon.svg";

import cssClasses from './Header.module.css';

const defaultIconState = {
    showBackIcon: false,
    showCloseIcon: false
};

const CameraHeader = (props) => {
    const { backFn, backLink, closeLink, closeFn, routeRedirection, title, showBackIcon, showCloseIcon } = props;
    const history = useHistory();
    const [iconsState, setIconsState] = useState(defaultIconState);

    const backBtn = () => {
        if (backFn) {
            backFn();
        } else if (routeRedirection) {
            history.replace(routeRedirection);
        }
    };
    const closeBtn = () => {
        if (closeFn) {
            closeFn();
        }
    };
    const updateIconsState = () => {
        let currentIconsState = defaultIconState;
        if (backFn || routeRedirection) {
            currentIconsState.showBackIcon = true;
        }
        if (closeFn) {
            currentIconsState.showCloseIcon = true;
        }
        console.log(currentIconsState);
        setIconsState(currentIconsState);
    };

    return (
        <div className={`${cssClasses['heading']}`}>
            <div>
                {showBackIcon ? <>
                    {backLink ? <Link to={backLink}>
                        <img height="20" width="20" src={BackWhIcon} alt="back-icon" />
                    </Link> : <img height="20" width="20" onClick={backBtn} src={BackWhIcon} alt="back-icon" />}
                </> : <div style={{
                    width: 24,
                    height: 24
                }}>{" "}</div>}
            </div>
            <div className={`${cssClasses['center-text']}`}>{title}</div>
            <div>
                {showCloseIcon ? <>
                    {closeLink ? <Link to={closeLink}>
                        <img height="24" width="24" src={CloseIcon} alt="close-icon" />
                    </Link> : <img height="24" width="24" onClick={closeBtn} src={CloseIcon} alt="close-icon" />}
                </> : null}
            </div>
        </div>
        // <Row>
        //     <Col
        //         // xs={12}
        //         className={`position-fixed ${cssClasses['header-nav-container']}`}
        //         // className={`d-flex align-items-center justify-content-center mb-3
        //         // ${cssClasses['header-nav-container']} ${props.bgClass ? props.bgClass : ''} position-fixed`}
        //     >
        //         <div>
        //         <Link to={props.routeRedirection} className={`${cssClasses['back-btn-icon']}`}>
        //             <img width={20} height={20} src={BackWhIcon} alt="back-arrow-img" />
        //         </Link>

        //         </div>

        //         <h1 className={`${props.titleClasses ? props.titleClasses : 'text-white mb-0 py-3 font-size-18 text-center'}`}>
        //             {props.title}
        //         </h1>
        //         <div>
        //         <img src={CloseIcon} alt="clonse-img" width={24} height={24} />
        //         </div>
        //     </Col>
        // </Row>
    );
};

export default CameraHeader;