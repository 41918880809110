import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
// import 'react-html5-camera-photo/build/css/index.css';

import { connect } from "react-redux";
import MessageModal from "./../../UI/Modal/message-modal";
import StepsModal from "./../../UI/Modal/steps-modal";
import LoginModal from "./../../UI/Modal/login-modal";
import ScanningTooth from "../../UI/animation/ScanningTooth";

import ToothPhotosSubmitModal from "./../../UI/Modal/ToothPhotosSubmitModal";
import { CameraStepsData } from "./../../Utils/camera-static-data/CameraStepsData";
import CameraModuleButton from "../../UI/CameraModule/CameraModuleButton";
import CameraModuleBottomImage from "../../UI/CameraModule/CameraModuleBottomImage";
import CameraModuleBackground from "../../UI/CameraModule/CameraModuleBackground";
import CameraCancelButton from "../../UI/CameraModule/CameraCancelButton";
import "./CameraModule.css";
import { Button } from "@material-ui/core";

const CameraModuleTwo = (props) => {
  let history = useHistory();
  const [images, setImages] = useState(props.reportState.responseImages);
  const [showLogin, setShowLogin] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const fullscreen = true;
  const upperBlock = CameraStepsData;

  useEffect(() => {
    setImages(props.reportState.responseImages);
  }, [props.reportState.responseImages]);

  useEffect(() => {
    setImages(props.reportState.responseImages);
  }, [props.myImages, props.reportState.responseImages]);

  const handleTakePhotoAnimationDone = (dataUri) => {
    // console.log("dataUri", dataUri);
  };

  const handleReportSubmit = () => {
    if (authToken == null && props.myImages.length >= 5) {
      setShowLogin(true);
    } else {
      if (props.myImages.length >= 5) {
        setLoader(true);
        props.reportUpload(images);
      }
    }
  };

  const handleSignupNavigation = () => {
    history.push("/signup", { comeBack: true });
  };
  const handleLoginNavigation = () => {
    history.push("/login", { comeBack: true });
  };

  const redirectToReshoot = (img, index, imgContents) => {
    history.push("/camera-module-reshot", {
      dataUri: img,
      demoImage: upperBlock[index].image,
      imgCount: index + 1,
      data: upperBlock[index],
      isReshoot: true,
      overlay: `Overlay${index + 1}`,
    });
  };

  return (
    <Container fluid className="bg-container camera-preview p-0">
      <Camera
        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
        isFullscreen={fullscreen}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        isImageMirror={false}
        className="WebCam"
      ></Camera>
      <CameraModuleBackground />
      <Row className="align-items-start header-photo-count h-100 overflow-auto mx-0">
        <Col xs={12} className="text-right pt-2">
          <CameraCancelButton routeUrl="/dashboard" />
        </Col>
        <Col xs={12} className="px-0 text-center">
          <Link to="/instruction-for-use" className="ml-5">
            <Button className="text-white font-weight-bold instructions-btn teritary-bg my-2"
            >
              <i className="far fa-images mr-2 font-weight-bold font-size-16"></i>
              General instructions
            </Button>
          </Link>
          {props.myImages.length <= 4 && (
            <StepsModal upperBlock={upperBlock} myImages={props.myImages} />
          )}

          {!authToken && showLogin ? (
            <LoginModal
              signup={handleSignupNavigation}
              login={handleLoginNavigation}
            />
          ) : undefined}
          {props.reportUploadState.message !== undefined &&
            props.reportUploadState.message !== "" && (
              <MessageModal
                isQuestionnaireGiven={props.reportUploadState.response.isQuestionnaireGiven}
                reportId={props.reportUploadState.response.report._id}
                isSuccess={
                  props.reportUploadState.message ===
                    "Something went wrong please try again!"
                    ? false
                    : true
                }
              />
            )}
          {showLoader && !props.reportUploadState.message ? (
            // <ScanningTooth />
            <></>
          ) : (
            !showLogin &&
            !props.reportUploadState.message &&
            props.myImages.length === 5 && (
              <ToothPhotosSubmitModal onSubmit={handleReportSubmit} />
            )
          )}
        </Col>
        <Col xs={12} className="px-0">
          <Row className="align-items-end mx-0">
            <Col
              lg={8}
              xs={12}
              className="px-0 d-flex justify-content-around mx-auto"
            >
              {upperBlock.map((block, index) => {
                return images[index]?.image_name === undefined ? (
                  <CameraModuleBottomImage
                    isImage={false}
                    key={index}
                    count={index + 1}
                    image={props.myImages[index]}
                  />
                ) : (
                  <CameraModuleBottomImage
                    isImage={true}
                    key={index}
                    image={props.myImages[index]}
                    onClick={() =>
                      redirectToReshoot(props.myImages[index], index)
                    }
                  />
                );
              })}
            </Col>
          </Row>
          <Row className="align-items-end mt-4 pr-3 mx-0">
            <Col xs={12} className="d-flex justify-content-end mx-auto">
              <div
                className={
                  !props.reportState.responseImages[4].image_name
                    ? "d-none"
                    : ""
                }
              ></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapDespatchToProps = (dispatch) => {
  return {
    reportUpload: (reportUpload) => {
      dispatch({ type: "USER_REPORT_UPLOAD", reportUpload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    myImages: state.image,
    reportState: state.report,
    reportUploadState: state.reportUpload,
  };
};

export default connect(mapStateToProps, mapDespatchToProps)(CameraModuleTwo);
