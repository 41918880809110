import React from 'react';
import classes from './ActionButton.module.css';

const ActionButton = (props) => {
    const { leftFn, leftFaFaIcon, leftIcon, leftType, rightFn, rightFaFaIcon, rightIcon, rightType, topText, bottomText, showTextArea, onChangeTextare } = props;
    // console.log('props', props);
    return (
        <div className={`${classes['action-btn-root']}`}>

            <div className="prediction-text">{topText && topText}</div>
            <div className={`${classes['action-btn']}`}>
                {leftIcon || leftFaFaIcon || leftType || leftFn ? <div className={`${classes['small-circle']} ${classes[leftType]}`} onClick={() => {
                    if (leftFn) {
                        leftFn();
                    }
                }}>
                    {leftFaFaIcon ? <i className={`fas ${leftFaFaIcon} font-size-22`}></i> : null}
                    {leftIcon && leftIcon}
                </div> : null}
                <div className={`${classes['small-circle']} ${classes[rightType]}`}
                    onClick={() => {
                        if (rightFn) {
                            rightFn();
                        }
                    }}>
                    {rightFaFaIcon ? <i className={`fas ${rightFaFaIcon} font-size-22`}></i> : null}
                    {rightIcon && rightIcon}
                </div>
            </div>
            {showTextArea ? 
            <div className='w-100 px-3'>
                <textarea style={{
                    backgroundColor: '#000',
                    height: 'auto',
                    color: '#fff',
                    boxShadow: 'none',
                    border:'0.5px solid #048ffc',
                    outline:'none'
                }}
                    rows={2}
                    id="predictionText"
                    className="prediction-text w-100 text-center"
                    onChange={(e) => onChangeTextare && onChangeTextare(e.target.value)}
                    value={bottomText}
                    placeholder="You can write the observation"
                ></textarea>
            </div>: null}
        </div>
    );
};

export default ActionButton;