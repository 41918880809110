import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Header from "../../../UI/Header/Header";
import "./FAQ.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "100%",
    flexShrink: 0,
  },
  headingContainer: {
    backgroundColor: "#f7f7f7",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

const Faq = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container className="faq-bg faq-container">
      <Header title="Faq" routeRedirection="/dashboard" />
      <Row className="margin-top-70px">
        <Col xs={12} className="px-0">
          <div className={classes.root}>
            <h5 className="faq-header mt-4 mb-3 mx-3 font-weight-500">
              Quality and Credibility
            </h5>

            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                className={classes.headingContainer}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  Are the guidelines by Toothlens Check credible?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The guidelines have been developed based on the American
                  Dental Association and NHS, UK risk assessment guidelines. The
                  best practices and recommendations of the top global
                  associations have been used for the reporting.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>
                  Is it possible to do a dental check without radiographs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Radiographs or X-rays are needed for a comprehensive dental
                  checkup, which will be done by a dental professional. The
                  Toothlens Check uses an algorithm that identifies problem
                  areas visible on tooth photos and is not a replacement for
                  dental radiographs.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.heading}>
                  Does Toothlens have US FDA approval?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothlens is a self check wellness app and not a
                  medical/diagnostic app. A consumer app like Toothlens is not
                  in the oversight of the US FDA currently. However, when such
                  regulations are necessary, Toothlens would apply for it and
                  get the required approvals.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.heading}>
                  Is Toothlens HIPAA compliant?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes. Toothlens is HIPPA compliant and follows all the best
                  practices of privacy and data security.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.heading}>
                  How does the risk assessment work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothlens algorithm has been developed and tested in
                  cooperation with dental experts. It can assess photos of teeth
                  with cavities and tartar.The images are processed through a
                  Convolutional Neural Network (CNN), a class of neural networks
                  that is particularly suitable for image data classification
                  tasks. The algorithm indicates to label the level of risk of
                  the specific dental problem. Based on the issue, we provide a
                  supportive recommendation on the next steps to take. *Our
                  dental checks do not intend to provide a dental diagnosis nor
                  replace visits to a dentist or dental professional.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography className={classes.heading}>
                  Can Toothlens check all my dental issues?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The Toothlens algorithm can detect cavities and tartar.
                  However, the quality of detection depends on the clarity of
                  the image, tooth coverage, lighting conditions, and sharpness
                  of pictures. Whether you can do a Toothlens Check or not,
                  depends on whether the problem area can be accurately captured
                  with the camera to be processed with the Toothlens algorithm
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography className={classes.heading}>
                  Does Toothlens replace my Dentist?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothlens is not a diagnostic device and does not replace a
                  visit to your dentist. Toothlens helps you become more aware
                  of your dental health and plan a visit to a dentist in time.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <h5 className="faq-header mt-4 mb-3 mx-3 font-weight-500">
              General
            </h5>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <Typography className={classes.heading}>
                  Why is it important to detect dental problems early?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Early detection of dental issues can enable you to take
                  actions early. The American Dental Association recommends
                  regular dental checks to prevent any dental problems. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <Typography className={classes.heading}>
                  How big a problem is the incidence of dental cavities and
                  plaque/tartar?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  According to the National Center for Health Statistics 
                  -Percent of children aged 5-19 years with untreated dental
                  caries: 16.9% (2013-2016) -Percent of adults aged 20-44 with
                  untreated dental caries: 31.6% (2013-2016) Detailed report-
                  <a
                    href="https://www.cdc.gov/nchs/fastats/dental.htm"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://www.cdc.gov/nchs/fastats/dental.htm
                  </a>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel10bh-content"
                id="panel10bh-header"
              >
                <Typography className={classes.heading}>
                  Why should I even track my dental hygiene?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  It is vital to track cavities, dental plaque because it can
                  get worsened with time. What may be a small problem now can
                  become a much larger issue later. A little cavity spot could
                  get more extensive and grow deeper, causing severe dental pain
                  and swelling.Plaque/tartar, if unattended, can result in
                  bleeding gums and weakening of teeth. Root exposures, if
                  unattended, can lead to sensitivity and cavities. <br />
                  <span className="mt-2 mb-1 d-block">
                    Additional Reading Resources
                  </span>
                  <a
                    className="d-block mb-1"
                    href="https://www.webmd.com/oral-health/guide/dental-health-cavities#1"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://www.webmd.com/oral-health/guide/dental-health-cavities#1
                  </a>
                  <a
                    className="d-block mb-1"
                    href="https://www.webmd.com/oral-health/guide/plaque-and-your-teeth"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://www.webmd.com/oral-health/guide/plaque-and-your-teeth
                  </a>
                  <a
                    className="d-block mb-1"
                    href="https://www.healthline.com/health/dental-and-oral-health/exposed-tooth-root"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://www.healthline.com/health/dental-and-oral-health/exposed-tooth-root
                  </a>
                  <a
                    className="d-block mb-1"
                    href="https://www.webmd.com/oral-health/guide/receding_gums_causes-treatments#1"
                    rel="noreferrer"
                    target="_blank"
                  >
                    https://www.webmd.com/oral-health/guide/receding_gums_causes-treatments#1
                  </a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <h5 className="faq-header mt-4 mb-3 mx-3 font-weight-500">
              About Toothlens
            </h5>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel11bh-content"
                id="panel11bh-header"
              >
                <Typography className={classes.heading}>
                  What exactly is Toothlens?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothlens is an AI based, intelligent mobile app that allows
                  you to perform your own dental health check without having to
                  step outside of your space. It is a quick, easy and
                  hassle-free dental-health management solution. WIth Toothlens,
                  you get the power of early detection, guidance and tracking,
                  all in one single app.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChange("panel12")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel12bh-content"
                id="panel12bh-header"
              >
                <Typography className={classes.heading}>
                  How does Toothlens work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothlens works on a unique machine learning algorithm, called
                  Toothlens-detect technology which is built by an amazing team
                  of clinicians along with engineers. It is a well tested
                  proprietary technology that can detect dental problems like
                  cavities, tartar and others using just the images of your
                  teeth.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel13"}
              onChange={handleChange("panel13")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel13bh-content"
                id="panel13bh-header"
              >
                <Typography className={classes.heading}>
                  Who should use Toothlens?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Everyone above 18 years should use Toothlens. It is useful in
                  maintaining good oral hygiene and thus can be used even if
                  they don't have an existing dental problem.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel14"}
              onChange={handleChange("panel14")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel14bh-content"
                id="panel14bh-header"
              >
                <Typography className={classes.heading}>
                  How do I use it?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Well, all you need to do is, download the Toothlens
                  application and simply click some pictures of your teeth on
                  your smartphone device. It does not need any additional camera
                  or other external hardware devices.The application then scans
                  the images and locates the areas that have cavities and
                  tartar. You can also track the progress of your dental
                  treatment with Toothlens.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel15"}
              onChange={handleChange("panel15")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel15bh-content"
                id="panel15bh-header"
              >
                <Typography className={classes.heading}>
                  Is Toothlens available everywhere?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The web application is currently available in the North
                  American market . You can download the app through your dental
                  insurance carrier/broker or dental benefits provider
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel16bh-content"
                id="panel16bh-header"
              >
                <Typography className={classes.heading}>
                  Are there more benefits of Toothlens?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothlens identifies dental cavities and tartar by scanning
                  the images of your teeth. Dental cavities and tartar are
                  leading causes of  teeth and gum infections and cause
                  permanent damage, if not treated in time. Timely and regular
                  Toothlens scans help in early detection,prevention and early
                  treatment.In addition based on your regular usage pattern of
                  Toothlens, your dental insurance carrier/broker,may offer
                  additional options like dental provider network
                  references,better dental plan coverage,discounts  etc 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel17"}
              onChange={handleChange("panel17")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel17bh-content"
                id="panel17bh-header"
              >
                <Typography className={classes.heading}>
                  How is Toothlens  technology different from others?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothlens includes the ability to do what standard risk
                  assessment scoring does and much more.It is close to the user
                  and does not require you to visit or schedule your time to get
                  checked..This helps users track their dental risk scores
                  routinely and take preventive/early dental care
                  interventions. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel18"}
              onChange={handleChange("panel18")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel18bh-content"
                id="panel18bh-header"
              >
                <Typography className={classes.heading}>
                  Can you spell out in detail about the Toothlens Check?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothless checks a multiple aspects of dental health, such as-
                  <span className="d-block mb-1 mt-1">
                    1.Dental Risk Score-This a dental hygiene risk score based
                    on the questionnaire that you fill after signing up. It is
                    developed based on the caries risk assessment guidelines of
                    the ADA. The objective is to reduce the risk of getting
                    cavities and tartar.
                  </span>
                  <span className="d-block mb-1">
                    2.Cavity Check-The cavity score and location finder checks
                    for untreated dental cavities. The cavity score gives the
                    number of areas that the algorithm flags for cavities/
                    potential cavities. It is not the number of teeth, as each
                    tooth has many surfaces and has several regions with
                    cavities. The location finder maps the areas with cavities
                    over the pictures. The cavity care recommendations provide
                    guidelines for home care and dental office visits 
                  </span>
                  <span className="d-block mb-1">
                    3.Tartar Check-The Tartar Score and location finder checks
                    for visible plaque/tartar on the teeth. The tartar count
                    gives the number of areas that the algorithm flags as having
                    visible plaque/tartar. It is not the number of teeth, as
                    each tooth has five surfaces and many of these surfaces may
                    have tartar. The location finder maps the areas with visible
                    plaque/tartar over the pictures. The plaque care
                    recommendations provide guidelines for home care and dental
                    office visits  Additional Reading Resources <br />
                    <a
                      href="https://www.webmd.com/oral-health/guide/dental-health-cavities#1"
                      rel="noreferrer"
                      target="_blank"
                    >
                      https://www.webmd.com/oral-health/guide/dental-health-cavities#1
                    </a>{" "}
                    <br />
                    <a
                      href="https://www.webmd.com/oral-health/guide/plaque-and-your-teeth"
                      rel="noreferrer"
                      target="_blank"
                    >
                      https://www.webmd.com/oral-health/guide/plaque-and-your-teeth
                    </a>
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel19"}
              onChange={handleChange("panel19")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel19bh-content"
                id="panel19bh-header"
              >
                <Typography className={classes.heading}>
                  How do I interpret the Toothlens Check?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  You may refer to the dental risk score,  the number of teeth,
                  and the location of tooth/ teeth having problems. You may then
                  follow recommended steps and guidelines.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel20"}
              onChange={handleChange("panel20")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel20bh-content"
                id="panel20bh-header"
              >
                <Typography className={classes.heading}>
                  Will the app work on my device?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes. Toothlens works on all mobile devices. If you face an
                  issue with a particular device, please reach out to us at
                  <a href="mailto:support@toothlens.com">
                  support@toothlens.com
                  </a>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel21"}
              onChange={handleChange("panel21")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel21bh-content"
                id="panel21bh-header"
              >
                <Typography className={classes.heading}>
                  Can I use my application for my family?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Toothless results are highly personalised. If you want to
                  support your friends or family to take care of their teeth,
                  you must ask them to download their version of Toothlens. This
                  takes care of the confidentiality of results.  *Toothlens
                  cannot be held responsible for the confidential information
                  and health of third parties and the results you access. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel22"}
              onChange={handleChange("panel22")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel22bh-content"
                id="panel22bh-header"
              >
                <Typography className={classes.heading}>
                  How can I refer the app to  my friends and family?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  You can help your friends and family take better care of their
                  teeth by recommending them to use Toothlens. You also have an
                  option to share the app via the settings button. Invite them
                  to join with a personalized promo code to use when they sign
                  up.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel23"}
              onChange={handleChange("panel23")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel23bh-content"
                id="panel23bh-header"
              >
                <Typography className={classes.heading}>
                  What is Toothlens's mission?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Our mission is to make dental health more accessible by
                  reducing out of pocket costs for consumers and payouts for
                  insurance providers using early detection, and the vision is
                  to become the best dental self-check tool
                </Typography>
              </AccordionDetails>
            </Accordion>

            <h5 className="faq-header mt-4 mb-3 mx-3 font-weight-500">
              My Account
            </h5>
            <Accordion
              expanded={expanded === "panel24"}
              onChange={handleChange("panel24")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel24bh-content"
                id="panel24bh-header"
              >
                <Typography className={classes.heading}>
                  How do I verify my email address?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="px-2">
                  <li>
                    It is mandatory to verify your email address before gaining
                    access to the Toothlens app, Here are the steps:
                  </li>
                  <li>
                    After you sign up, you will be prompted to verify your
                    account via email.
                  </li>
                  <li>
                    Please open the verification email sent to the email address
                    you have signed up with (titled "Activate your Toothlens
                    account").
                  </li>
                  <li>
                    Click the button 'Verify My Email Address' in the
                    verification email, and within a couple of seconds, your
                    account gets successfully verified, and you can use the
                    Toothlens app
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel25"}
              onChange={handleChange("panel25")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel25bh-content"
                id="panel25bh-header"
              >
                <Typography className={classes.heading}>
                  I am having trouble verifying my email address; what do I do?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  If you are encountering issues verifying your account, please
                  (re-)open the Toothlens app at the 'Verify Email' screen and
                  check the following steps:
                  <span className="d-block mt-1 mb-1">
                    a. Please double check your Email address for any typos.
                  </span>
                  <span className="d-block mt-1 mb-1">
                    b. Resend email by clicking the button "I did not get it.
                    Resend mail"
                  </span>
                  <span className="d-block mt-1 mb-1">
                    c. Search in your inbox for emails from
                    support@toothlens.com
                  </span>
                  <span className="d-block mt-1 mb-1">
                    d. Check your spam folder.
                  </span>
                  <span className="d-block mt-1 mb-1">
                    e. Check your trash folder.
                  </span>
                  If you are still having trouble verifying, please let us know
                  at which step you cannot proceed. You can contact us at
                  <a href="mailto:support@toothlens.com">
                    {" "}
                    support@toothlens.com
                  </a>
                  .
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel26"}
              onChange={handleChange("panel26")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel26bh-content"
                id="panel26bh-header"
              >
                <Typography className={classes.heading}>
                  Why do I need to provide the correct contact details?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="px-2">
                  <li>
                    Make sure we have your correct contact details. At
                    Toothlens, we continuously perform quality checks to improve
                    our service by evaluating the assessments' output. Toothlens
                    provides feedback to you about the recommended next steps to
                    take in cases with a potential health risk.
                  </li>
                  <li>
                    If our team identifies a case as a potential health risk,
                    our customer service team will reach out to you. We may send
                    you email notifications and in-app messages relating to the
                    analysis results, which includes emails in which we provide
                    you with information and ask you to provide us with
                    information about possible follow-up actions.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel27"}
              onChange={handleChange("panel27")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel27bh-content"
                id="panel27bh-header"
              >
                <Typography className={classes.heading}>
                  Is my personal information safe?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="px-2">
                  <li>
                    Yes, we do everything we can to protect your personal
                    information. Toothlens does not sell your personal
                    information to third parties and performs all possible
                    measures to keep your data safe. All personal information
                    and data are treated highly confidential.
                  </li>
                  <li>
                    All your data is stored on secure cloud servers AWS(Amazon
                    web services). We do everything we can to ensure that
                    external service providers handle your data securely and
                    according to the applicable law.
                  </li>
                  <li>
                    All data you provide through the App and the website is
                    encrypted when transferred to and from the servers using
                    Transport Layer Security (TLS). Furthermore, information is
                    stored in a coded form when stored on the servers.
                  </li>
                  <li>
                    We store your account data and your digital images in
                    separate data warehouses. Firewalls and monitoring protect
                    the cloud server infrastructure.
                  </li>
                  <li>
                    Please read our{" "}
                    <Link to="privacy-policy">privacy policy</Link> for more
                    information.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel28"}
              onChange={handleChange("panel28")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel28bh-content"
                id="panel28bh-header"
              >
                <Typography className={classes.heading}>
                  Why do I need a profile?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Your Toothlens profile helps you better understand your risks
                  and provides you with personalized dental recommendations.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel29"}
              onChange={handleChange("panel29")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel29bh-content"
                id="panel29bh-header"
              >
                <Typography className={classes.heading}>
                  What do I do if I've lost my username and password?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  If you forget your password, you can reset it by selecting
                  'Log in' on the application's first screen. After entering the
                  email that you used to sign up, choose 'I forgot my password.'
                  You'll receive an email within a few minutes containing a link
                  that will allow you to reset your password. If you don't see
                  the email in your inbox after two minutes, check your spam
                  folder. Click on the link in the email to reset your password.
                  You will be taken to a new page to create a new password for
                  your account. Click on the "Set Password" link to complete the
                  process.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel30"}
              onChange={handleChange("panel30")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel30bh-content"
                id="panel30bh-header"
              >
                <Typography className={classes.heading}>
                  How can I cancel my subscription ?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Please check our <Link to="/terms-of-use">subscription policy</Link> in the
                  terms of use section.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel31"}
              onChange={handleChange("panel31")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel31bh-content"
                id="panel31bh-header"
              >
                <Typography className={classes.heading}>
                  What is the refund policy?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  For 6 months and yearly purchases made through the{" "}
                  <a
                    href="https://toothlens.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    toothlens.com
                  </a>{" "}
                  website or any Play/App Store, we are usually able to
                  accommodate refund requests made within 30 days of your
                  purchase. To know more about fees and <Link to="terms-of-use">subscriptions</Link>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <h5 className="faq-header mt-4 mb-3 mx-3 font-weight-500">
              Using the application
            </h5>
            <Accordion
              expanded={expanded === "panel32"}
              onChange={handleChange("panel32")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel32bh-content"
                id="panel32bh-header"
              >
                <Typography className={classes.heading}>
                  Can I use the Front Camera to take a picture of my teeth?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Our algorithm can only analyze teeth conditions when the
                  picture meets its requirements. Because the quality of front
                  cameras varies and conditions are likely to be not met, our
                  service requires you to take pictures with the rear camera.
                  However, if you have a good quality front camera device, you
                  can use it(Use the front camera for teeth in the upper arch).
                  If you cannot take the picture yourself, we recommend asking
                  another person (friend, family member) to take the pic for
                  you.
                  <span className="d-block my-1">
                    If you have any further questions, feel free to reach out to{" "}
                    <a href="mailto:support@toothlens.com">
                    support@toothlens.com
                    </a>
                    .
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel33"}
              onChange={handleChange("panel33")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel33bh-content"
                id="panel33bh-header"
              >
                <Typography className={classes.heading}>
                  Can I upload a picture instead?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  No, you will not be able to upload any pictures. You must
                  capture images directly inside the app.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel34"}
              onChange={handleChange("panel34")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel34bh-content"
                id="panel34bh-header"
              >
                <Typography className={classes.heading}>
                  How do I take a good quality picture?
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="flex-column">
                <span className="d-block mb-1">
                  To take the highest quality photos, please follow these
                  instructions:
                </span>
                <ul className="px-2">
                  <li>
                    Take photos using the smartphone camera with flash. If you
                    are unable to do it yourself, you can ask someone else to do
                    it.
                  </li>
                  <li>
                    Focus the camera so that only the teeth are visible and not
                    the face. It's essential to cover as many teeth as possible.
                  </li>
                  <li>
                    Keep the camera still and take the picture without any
                    blurring.
                  </li>
                  <li>Review and repeat the picture if not suitable.</li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel35"}
              onChange={handleChange("panel35")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel35bh-content"
                id="panel35bh-header"
              >
                <Typography className={classes.heading}>
                  How can I connect if I have any further questions?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  If you have any further questions or encounter any issues with
                  the platform, please contact us at any point at
                  <a href="mailto:support@toothlens.com" className="ml-1">
                  support@toothlens.com
                  </a>
                  - we look forward to hearing from you.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel36"}
              onChange={handleChange("panel36")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.headingContainer}
                aria-controls="panel36bh-content"
                id="panel36bh-header"
              >
                <Typography className={classes.heading}>
                  Do you have a complaint procedure?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Keeping you satisfied with our services is our top-most
                  priority. And your feedback truly helps us in improving our
                  services. We attach great importance to you approaching us
                  about any feedback/dissatisfaction. You can reach out to our
                  Customer Support team using the following email address:{" "}
                  <a href="mailto:support@toothlens.com">
                  support@toothlens.com
                  </a>
                  . Your inputs are valuable to us.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Faq;
