
// export const API_HOST = "https://lite.toothlens.com/api/v1/";
export const API_HOST = "https://lite.toothlens.com/api/v1/";

// export const API_HOST = "/api/v1/";

// export const API_HOST = "http://localhost:3001/api/v1/";
export const CAVITY_IMG_URL =
    "https://toothlens-lite-report.s3.ca-central-1.amazonaws.com/input/";
export const TARTAR_IMG_URL =
    "https://toothlens-lite-report.s3.ca-central-1.amazonaws.com/input/";