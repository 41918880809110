import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Landing from "./Components/LandingPage/landing";
import LoginSignup from "./Components/Login-Signup/LoginSignup";
import Login from "./Components/Login-Signup/Login/login";
import signUp from "./Components/Login-Signup/Signup/signUp";
import signUpStepTwo from "./Components/Login-Signup/Signup/signup-stepTwo/SignupStepTwo";
import Dashboard from "./Components/Dashboard/dashboard";

import Blog from "./Components/Dashboard/Drawer/Blog/Blog";
import AboutUs from "./Components/Dashboard/Drawer/AboutUS/AboutUs";
import ProfileForm from "./Components/Dashboard/Drawer/ProfileDetails/ProfileForm";
import ProfileDetails from "./Components/Dashboard/Drawer/ProfileDetails/ProfileDetails";
import ChangePassword from "./Components/Dashboard/Drawer/ChangePassword/ChangePassword";
import Subscription from "./Components/Dashboard/Drawer/Subscription/Subscription";
import NotificationReminders from "./Components/Dashboard/Drawer/NotificationReminder/NotificationRimind";
import Faq from "./Components/Dashboard/Drawer/FAQ/FAQ";
import InstructionForUse from "./Components/Dashboard/Drawer/InstructionForUse/InstructionForUse";
import PrivacyPolicy from "./Components/Dashboard/Drawer/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./Components/Dashboard/Drawer/TermsOfUse/TermsOfUse";


import Observation from "./Components/Observation"
import ObservationCamera from "./Components/Observation/CameraTakeImage"

import CaseForm from "./Components/Dashboard/Case/CaseForm/CaseForm"
import Cavity from "./Components/Dashboard/Cavity/Cavity";
import TotalTest from "./Components/Dashboard/TotalTest/TotalTest";
import Tartar from "./Components/Dashboard/Tartar/Tartar";
import DentalRiskScore from "./Components/Dashboard/DentalRiskScore/DentalRiskScore";
import Report from "./Components/Dashboard/TotalTest/Report/Report";
import CameraModuleTwo from "./Components/Dashboard/CameraModule/CameraModule2";
import CameraModuleReshot from "./Components/Dashboard/CameraModule/CameraModuleReshot";
import CameraModuleFirst from "./Components/Dashboard/CameraModule/CameraModuleFirst";
import ForgotPassword from "./Components/Login-Signup/ForgotPassword/ForgotPassword";
import VerifyCode from "./Components/Login-Signup/ForgotPassword/VerifyCode";
import NewPassword from "./Components/Login-Signup/ForgotPassword/NewPassword";
import Insights from "./Components/Dashboard/Insights/Insights";
import Questionnaire from "./Components/Dashboard/Questionnaire/Questionnaire";
import VerifyUserCode from "./Components/Login-Signup/Signup/VerifyUserCode";
import ScrollToTop from "./common/scrollToTop";
import QrCodePage from "./Components/QrCodePage/QrCodePage";
import Knowledge from "./Components/Dashboard/Knowledge/Knowledge";
import SelectBranches from "./Components/Dashboard/TotalTest/Report/SelectBranches";
// import { startRefreshTokenTimer, refreshToken } from "./Redux/Services/AuthService";
import CaseDetails from './Components/Dashboard/Case/CaseDetails/CaseDetails';
import List from "./Components/Dashboard/list";
function App() {
  const history = useHistory();
  const [isDesktop, setIsDesktop] = useState(false);


  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        const routeUrl = location.pathname;
        const splitUrl = routeUrl.split('-')[0];
        if (splitUrl === '/camera') {
          return history.push('/camera-module-two');
        }
        if (localStorage.getItem('user')) {
          return history.push('/dashboard');
        }
      }
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('isExplainerCard', true);
    const isMobileOrDesktop = getDesktopOrMobile();
    if (isMobileOrDesktop === 'Desktop') {
      localStorage.clear();
      setIsDesktop(true);
    }
  }, []);

  // Checking whether its mobile or Desktop
  const getDesktopOrMobile = () => {
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
    if (isMobile) {
      return 'Mobile';
    } else {
      return 'Desktop';
    }
  }

// testing the git
  return (
    isDesktop ? (
      <Switch>
        <Route exact path="/" component={QrCodePage} />
      </Switch>) : (
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/landing-two" component={LoginSignup} />
          <Route path="/signup" component={signUp} />
          {/* <Route path="/sign-up" component={signUpStepTwo} /> */}
          <Route path="/login" component={Login} />
          <Route path="/camera-module-first" component={CameraModuleFirst} />
          <Route path="/camera-module-two" component={CameraModuleTwo} />
          <Route path="/camera-module-reshot" component={CameraModuleReshot} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/verify-code" component={VerifyCode} />
          <Route path="/verify-user-code" component={VerifyUserCode} />
          <Route path="/new-password" component={NewPassword} />

          <Route path="/observation/camera/:id" component={ObservationCamera} />
          <Route path="/observation/:id" component={Observation} />


          {/* Dashboard */}
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/list" component={List} />
          <Route path="/case-form/:id" component={CaseForm} />
          <Route path="/case-details/:id" component={CaseDetails} />
          <Route path="/cavity" component={Cavity} />
          <Route path="/tartar" component={Tartar} />
          <Route path="/dental-risk-score" component={DentalRiskScore} />
          <Route path="/total-test" component={TotalTest} />
          <Route path="/total-test-report/:id" exact component={Report} />
          <Route path="/select-branches/:reportId" component={SelectBranches} />
          <Route path="/insights" exact component={Insights} />
          <Route path="/questionnaire" exact component={Questionnaire} />
          <Route path="/knowledge" exact component={Knowledge} />

          {/* Drawer  */}
          <Route path="/subcription" component={Subscription} />
          <Route path="/profile-details" component={ProfileDetails} />
          <Route path="/profile-form" component={ProfileForm} />
          <Route path="/change-password" component={ChangePassword} />
          <Route
            path="/Notification&Reminders"
            component={NotificationReminders}
          />
          <Route path="/FAQ" component={Faq} />
          <Route path="/instruction-for-use" component={InstructionForUse} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/blog" component={Blog} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/abouts-us" component={AboutUs} />
        </Switch>
      </ScrollToTop>
    )

  );
}

export default App;
