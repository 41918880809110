import axios from "axios";
import { API_HOST } from "./BaseUrl";

export const ReviewReportPostAPI = (requestData) => {
    let authToken = localStorage.getItem("authToken");
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: authToken,
    };
    return new Promise((resolve, reject) => {
      return axios
        .post(API_HOST + `review-report`, requestData, { headers: headers })
        .then((response) => {
          if (response.status === 200) {
             return resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          if (error && error.response) {
            reject({ ...error.response, isError: true });
          } else {
            reject({ message: 'Network error', isError: true });
          }
        });
    });
  };