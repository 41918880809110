import React, { useState } from 'react';
import { Button } from "@material-ui/core";

import cssClasses from  './ReviewFlagExplaner.module.css';

const ReviewFlagExplainer = ({ message, cssClass, explainerCardName }) => {

    const [displayClassName, setDisplayClassName] = useState('');

    const gotItHandler = () => {
        if (explainerCardName === 'flag') {
            localStorage.setItem('isShowReviewFlageExplainer', JSON.stringify(false));
        } else if (explainerCardName === 'send report') {
            localStorage.setItem('isShowSendReportExplainer', JSON.stringify(false));
        }
        setDisplayClassName('d-none');
    }

    return (
        <div
            className={`${cssClasses['review-flag-container']} ${cssClasses['right-side-popover']} ${displayClassName} ${cssClasses[cssClass]}`}>
            <p className="mb-2">{message}</p>
            <div className="text-center my-1">
                <Button className="primary-bg text-white" onClick={gotItHandler}>Got it</Button>
            </div>
        </div>
    )
}

export default ReviewFlagExplainer;