import * as types from "../Action";

const initState = {
  message: undefined,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  const response = action.payload;

  switch (action.type) {
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        response,
        message: "Your Password is Sucessfully Changed..!!",
      };
    case types.CHANGE_PASSWORD_ERROR:
      return {
        message: response.message,
      };
      case types.CLEAN_CHANGE_PASSWORD:
        return initState

    default:
      return state;
  }
}
