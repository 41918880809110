import React, { useState } from 'react';
import { Button, FormControl, FormControlLabel, Input, InputAdornment, Radio, RadioGroup } from '@material-ui/core';
import { Container, Row } from 'react-bootstrap';
import SearchIcon from '../../../../assets/icon/Search-Icon.svg';
import BranchIcon from '../../../../assets/icon/branch-icon.svg';
import Header from '../../../UI/Header/Header';
import ConfirmationBranchPopup from './ConfirmationPopup';
import { generatePdf } from '../../../../Redux/Services/AuthService';
import Swal from 'sweetalert2';

const SelectBranches = (props) => {
    const [reportId, setReportId] = useState(props.match.params.reportId);
    const [selectBranch, setSelectBranch] = useState('');
    const [userId, setUserId] = useState(props.location.state.userId);
    const [isConfirmationPopup, setIsConfirmationPopup] = useState(false);

    const branchesData = ['Texas', 'California', 'New York', 'Austin', 'Chicago', 'Washington, D.C.'];

    const handleChangeBranch = (event) => {
        setSelectBranch(event.target.value);
    };

    const submitBranchHandler = () => {
        const reportData = {
            userId,
            reportId,
            branchName: selectBranch,
            branchEmail: 'flags@toothlens.com',
            isPdfSendToDentist: true
        }
        generatePdf(reportData).then((res) => {
            setIsConfirmationPopup((prevState) => !prevState);
        }).catch((err) => {
            Swal.fire('Something went wrong', '', 'error');
        })
    }

    const onCloseDialog = () => {
        setIsConfirmationPopup(false);
    }

    return (
        <Container fluid className='select-branches-container'>
            <Header title="choose your branches" routeRedirection={`/total-test`} />
            {
                isConfirmationPopup && <ConfirmationBranchPopup open={isConfirmationPopup} onClose={onCloseDialog} />
            }
            <Row className="px-3 margin-top-70px">
                <FormControl className='mt-4' fullWidth variant="standard">
                    <Input
                        id="standard-adornment-amount"
                        placeholder='Search branch'
                        // onChange={handleChange('amount')}
                        startAdornment={<InputAdornment className='mb-2' position="start">
                            <img src={SearchIcon} alt="SearchIcon" />
                        </InputAdornment>}
                    />
                </FormControl>
                <FormControl className='my-4 w-100'>
                    <RadioGroup
                        row
                        aria-label={`branch`}
                        name={`branch`}
                        value={selectBranch}
                        onChange={handleChangeBranch}
                    >
                        {branchesData.map((data, index) => {
                            return (
                                <label key={`option${index}`}
                                    className="w-100 questions-option">
                                    <FormControlLabel
                                        className="profile-radios"
                                        value={`${data}`}
                                        control={<Radio color="primary" />}
                                        label={data}
                                    />
                                </label>
                            );
                        })}
                    </RadioGroup>
                </FormControl>
                <Button
                    type="submit"
                    disabled={!selectBranch}
                    className="form-btn"
                    onClick={submitBranchHandler}
                >
                    Confirm
                    <span className='position-absolute right-20px'>
                        <img src={BranchIcon} alt="SearchIcon" />
                    </span>
                </Button>
            </Row>
        </Container>
    )
}

export default SelectBranches;