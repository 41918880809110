import * as types from "../Action";

const initState = {
  responseImages: [
    {
      title: "front Teeth",
    },
    {
      title: "Upper Jaw",
    },
    {
      title: "Lower Jaw",
    },
    {
      title: "Left Side",
    },
    {
      title: "Right Side",
    },
  ],
  imageCount: 0,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action) {
  switch (action.type) {
    case types.USER_REPORT_SUCCESS:
      let newArray = state.responseImages;
      newArray[action.payload.imgCount - 1].image_id = action.payload.imgCount;
      newArray[action.payload.imgCount - 1].image_name = action.payload
        ? action.payload.data.imageName
        : "";
      return {
        ...state,
        responseImages: newArray,
        imageCount: action.payload.imageCount,
      };
    case types.USER_REPORT_RESET:
      const stateData = {...state};
      const updatedState = stateData.responseImages.map((img) => (
        {
          title: img.title
        }
      ));
      stateData.responseImages = updatedState;
      stateData.imageCount = 0;
      return stateData;
    case types.USER_REPORT_ERROR:
      return {};
    default:
      return state;
  }
}
