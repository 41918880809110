import React from "react";
import { useHistory } from "react-router-dom";

const CameraCancelButton = ({ routeUrl, backFn }) => {
  return (
    <div onClick={backFn} className="d-flex justify-content-end">
      <i className="fas fa-times camera-cancel-btn font-size-23 text-white d-flex justify-content-center align-items-center"></i>
    </div>
  );
};

export default CameraCancelButton;
