import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from '@material-ui/core/TextField';
import "./Popup.css";
import Header from "../../UI/Header/Header";
import { postPopUpData } from "../../../Redux/Services/AuthService";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";

const Popup = ({ trigger }) => {
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  let history = useHistory();

  const [option, setoption] = useState({
    id1: false,
    id2: false,
    id3: false,
    id4: false,
    id5: false,
    id6: false,
    id7: false,
    text: "",
    checkbox: [],
  });
  const onchangeid = (e) => {
    const array = option.checkbox;
    if (array.includes(e.target.value)) {
      const index = array.indexOf(e.target.value);
      array.splice(index, 1);
      setoption({ ...option, checkbox: array });
    } else {
      array.push(e.target.value);
      setoption({ ...option, checkbox: array });
    }
  };
  const onchangetext = (e) => {
    setoption({ ...option, text: e.target.value });
  };
  function handleData(e) {
    const array = option.checkbox;
    array.push(option.text);
    setoption({ ...option, checkbox: array });
    postPopUpData(option.checkbox)
      .then((res) => {
        if (res) {
          Swal.fire(
            "Congratulations!",
            "You have successfully filled your personalize expectations",
            "success"
          );
        }
      })
      .catch((err) => {
        Swal.fire("Server error, Please try later!", "", "error");
      });
    e.preventDefault();
    trigger(false);
  }

  return (
    <div className="model px-3">
      <form onSubmit={handleData}>
        <div className="model_container px-3">
          <Header title="Questionnaire" routeRedirection="/dental-risk-score" />

          <div className="title">How can we help you today?</div>
          <div className="content px-3">
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id1"
                  value={"I just need a routine check"}
                />{" "}
              </Col>
              <Col
                className="d-flex justify-content-start align-items-center"
                xs={9}
              >
                I just need a routine check
              </Col>
            </Row>

            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id2"
                  value={"I think I have cavities"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                I think I have cavities
              </Col>
            </Row>
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  onChange={onchangeid}
                  color="primary"
                  name="id3"
                  value={"I have some tooth discomfort"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                {" "}
                I have some tooth discomfort
              </Col>
            </Row>

            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id5"
                  value={"I am looking for tooth replacement/Bridges/Implants"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                {" "}
                I am looking for tooth replacement/Bridges/Implants
              </Col>
            </Row>
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id6"
                  value={"I want a smile correction/braces/aligners"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                I want a smile correction/braces/aligners
              </Col>
            </Row>
            <Row>
              <Col
                xs={1}
                className="d-flex justify-content-center align-items-center"
              >
                <Checkbox
                  type="checkbox"
                  color="primary"
                  onChange={onchangeid}
                  name="id7"
                  value={"I want whiter teeth"}
                />{" "}
              </Col>
              <Col
                xs={9}
                className="d-flex justify-content-start align-items-center"
              >
                I want whiter teeth
              </Col>
            </Row>
            <Row className="p-3">
              <Col
                xs={4}
                className="d-flex justify-content-center align-items-center"
              >
                Others
              </Col>
              <Col xs={6}>
                <TextField
                  style={{ width: 150 }}
                  label="Query"
                  name="text"
                  margin="normal"
                  type="text"
                  onChange={onchangetext}
                  value={option.text}
                />
              </Col>
            </Row>
          </div>
          <div className="footer">
            <Button
              className="form-btn"
              type="submit"
            >
              submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Popup;
