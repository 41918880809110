import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CameraModuleBottomImage from "../UI/CameraModule/CameraModuleBottomImage";


const Observations = (props) => {
    const { reportImages = [], next, nextLabel, imageUrl, onChangeImgPreview, retake, stepCount = 0, updateNote, note, loading } = props;
    const [obsText, setObsText] = useState('');

    const onChange = (val) => {
        setObsText(val);
    }

    const onUpdateNote = () => {
        if(updateNote) {
            updateNote(obsText);
            setObsText('')
        }
    }

    const onChangeImage = (image_url, index) => {
        onUpdateNote();
        if(onChangeImgPreview) {
            onChangeImgPreview(image_url, index)
        }
    }

    useEffect(() => {
        setObsText(note);
    }, [note])


    return (
        <Container
            fluid
            className="reshoot-container p-0"
            style={{
                height: 'calc(100vh)'
            }}
        >
            <Row style={{
                marginTop: 80
            }}>
                <Col lg={12} xs={12} className="d-flex justify-content-around" style={{
                    maxHeight: '100vh'
                }} >
                    <div className="position-relative px-4 mb-1">
                        <i
                            onClick={() => retake()}
                            style={{
                                top: 42,
                                position: 'relative',
                                width: '100%',
                                textAlign: 'end',
                                fontSize: 28,
                                right: 16,
                                display: 'flow-root',
                                color: '#fff'
                            }} className="fa fa-redo" aria-hidden="true"></i>
                        <img style={{
                            border: '3px solid white',
                            borderRadius: 10,
                            width: 320,
                            height: 320,
                        }} src={imageUrl} alt="cropImage" />
                        <div className='w-100 px-2 my-5'>
                            <textarea style={{
                                backgroundColor: '#000',
                                height: 'auto',
                                color: '#fff',
                                boxShadow: 'none',
                                border: '0.5px solid #048ffc',
                                outline:'none'
                            }}
                                rows={2}
                                id="predictionText"
                                className="prediction-text w-100 text-center"
                                onChange={(e) => onChange(e.target.value)}
                                value={obsText}
                                placeholder="You can write the observation"
                            ></textarea>
                        </div>
                        {/* <p className="text-white text-center font-size-18 mt-2">{reportImages[stepCount].note ? reportImages[stepCount].note : <span>&nbsp;&nbsp;</span> }</p> */}
                    </div>
                </Col>
            </Row>
            <Row className="align-items-end mx-0 mt-3">
                <Col
                    lg={8}
                    xs={12}
                    className="px-0 d-flex justify-content-around mx-auto"
                >
                    {reportImages.map((reportImage, index) => {
                        return reportImage.image_name === undefined ? (
                            <CameraModuleBottomImage
                                isImage={false}
                                key={index}
                                count={index + 1}
                                image={reportImage.image_url}
                            />
                        ) : (
                            <CameraModuleBottomImage
                                isImage={true}
                                key={index}
                                image={reportImage.image_url}
                                imgHighlight={index === stepCount}
                                onClick={() => onChangeImage(reportImage.image_url, index)}
                            />
                        );
                    })}
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col xs={12}>
                    {next ? <div className='mt-4 px-3 text-center'>
                        {loading?<CircularProgress/>:
                          <Button
                          variant="primary"
                          size="lg"
                          onClick={() => {
                                onUpdateNote();
                                if(next) {
                                    next()
                                }
                            }}
                          className='form-btn'
                      >
                          {nextLabel ? nextLabel : 'Next'}
                      </Button>}
                      
                    </div> : null}
                </Col>
            </Row>
        </Container>
    );
};

export default Observations;