import React, { useState, useEffect } from "react";
import Drawer from "./Drawer/Drawer";
import { Container, Row, Button, Col } from "react-bootstrap";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import DaskIcon from "../../assets/icon/daskboard-dask-icon.svg";
import Logo from "../../assets/logo/logo.svg";

import "./dashboard.css";

import { Link, useHistory } from "react-router-dom";
import { getAllCases } from "../../Redux/Services/VeterinaryCaseService";
import {studentExcelUploadService} from "../../Redux/Services/AuthService"
import { dateFormat } from "../Utils";
import SmallCard from "../UI/Card/SmallCard/SmallCard";
import SnackBarMessages from "../UI/Snackbar/Snackbar";

let timer;
const Dashboard = () => {
  const history = useHistory();
  const [state, setState] = useState({
    cavity: 35,
    Tartar: 10,
    title: "Toothlens Lite",
  });
  const [searchBy, setSearchBy] = useState("Name");
  const [searchValue, setSearchValue] = useState("");
  const [pageMsg, setPageMsg] = useState("");

  const swapSlider = (event) => {
    if (event >= 1) {
      setState({ title: "Instruction for use" });
    } else {
      setState({ title: "Toothlens Lite" });
    }
  };

  const [allCases, setAllCases] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [message, setMessage] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const excelName=JSON.parse( localStorage.getItem('excelName'))
  const [loading, setLoading] = useState(true);


  const getCases = (data = {}, msg = "No data found") => {
    setAllCases([]);
    setPageMsg("Loading...");
    data.fileName=excelName
    getAllCases(data)
      .then((res) => {
        if (res.data.data && res.data.data.length) {
          setAllCases(res.data.data);
          setPageMsg("");

        } else {

          setPageMsg(msg);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if(localStorage.getItem('folderName')){ 
      localStorage.removeItem('folderName'); 
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const authToken = localStorage.getItem("authToken");
    if (!user || !authToken) {
      history.push("/login");
    }
  }, [fileUploaded]);

  const goto = (path) => {
    history.push(path);
  };

  const handleSearch = (cb, timeout = 500) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(cb, timeout);
  };

  const onChangeSearchBy = (e) => {
    const val = e.target.value;
    setSearchValue("");
    setSearchBy(val);
    getCases();
    setLoading(false)

  };

  const onChange = (e) => {
    const val = e.target.value ?? "";
    setSearchValue(val);
    handleSearch(() => {
      let key = "name";
      if (searchBy === "Phone No.") {
        key = "phone_no";
      } else if (searchBy === "Case No.") {
        key = "case_number";
      } else if (searchBy === "Date") {
        key = "date";
      }
      if (val === "") {

        getCases();
        
      } else {
        getCases({ [key]: val ?? "" });
      }
    });
  };
  const handleFileUpload = async (e) => {
   await studentExcelUploadService(e).then(res=>{
     setFileUploaded(!fileUploaded)
      setSuccess(true)
      setMessage(res.message)
   }).catch(err=>
    {
      setError(true)
      setMessage(err.response.data.message)
    })
     };
  return (
    <>
       <Drawer title={state.title} />
      <div className="dashboard-bg dashboard-overflow h-100">
        <div className="position-absolute right-0 p-2 width-50px z-index-9">
          <img className="w-100" src="/images/logo/logo.svg" alt="logo" />
        </div> 
         <Container className="mt-5 pt-3">
          <Row>
            <div className="vetlens-page">
              <Row
                style={{
                  maxHeight: "calc(100vh - 150px)",
                  overflowY: "auto",
                }}
              >
                <Col className="mt-3" xs={12}>
                <div className="text-center my-2 header-text "><strong>{excelName}</strong></div>

                  <form
                    action="/"
                    onSubmit={(e) => {
                      e.preventDefault();
                      let key = "name";
                      if (searchBy === "Phone No.") {
                        key = "phone_no";
                      } else if (searchBy === "Case No.") {
                        key = "case_number";
                      } else if (searchBy === "Date") {
                        key = "date";
                      }
                      getCases({ [key]: searchValue ?? "" });
                    }}
                  >
                    <div className="px-4">
                      <div className="input-group mb-3 search-group">
                        <div className="input-group-prepend">
                          <select onChange={onChangeSearchBy}>
                            <option value="Name">Name</option>
                            <option value="Case No.">Case ID</option>
                          </select>
                        </div>
                        <input
                          type={searchBy === "Date" ? "date" : "text"}
                          className="form-control"
                          onChange={onChange}
                          value={searchValue}
                          placeholder={`Search by ${searchBy}`}
                          aria-label="Search by Name"
                        />
                        <div className="input-group-append">
                          <input id="searchSubmitBtn" type="submit" hidden />
                          <label
                            htmlFor="searchSubmitBtn"
                            className="input-group-text"
                          >
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>

                </Col>
                <Col className="" xs={12}>
               {allCases.map((item, index) => {
                    return (
                      <div
                        className="px-4 pt-4"
                        key={index}
                        onClick={() => {
                          goto("/observation/" + item._id);
                        }}
                      >
                        <SmallCard
                          date_value={item.created_date_value || ""}
                          {...item}
                        />
                      </div>
                    );
                  })} 
                   <div className="px-4 pt-4 text-center font-size-22">
                    {pageMsg}
                  </div>  
              {loading &&  <div className="primary-text d-flex justify-content-center align-items-center h-100"
                  style={{minHeight:'400px'}}>
                   <div className="mx-4 teritary-color"><strong> Mention the CaseId or Name in the above search box to get the case details and  complete the check !!</strong></div>
                  </div> }   
                </Col>
              </Row>
            </div>
          </Row>
          {/* <div className="position-absolute bottom-0 add-case-btn">
            <div className="mb-3  primary-text">
         <input type="file" onChange={(e)=>handleFileUpload(e)}  variant="primary" size="lg"/>
             </div>
            <div className="mb-3 add-case-btn primary-text">
              <Link to={"/case-form/new"}>
                <Button variant="primary" size="lg" className="form-btn">
                  Add Case
                </Button>
              </Link>
            </div>
          </div> */}

          <div
        className="position-absolute text-center bg-danger mb-5"
        style={{
          bottom: 10,
          left:90
        }}
      >
        <div className="bg-danger text-center d-block">
        <Button
        className="btn btn-primary btn-lg"
        onClick={()=>{
          history.push('/list')
        }}>Back to File Upload</Button> 
        </div>
       
      </div> 
      
          {error || success && (
          <SnackBarMessages
            open={true}
            position="top-right"
            timing={5000}
            type={error?"error":"success"}
            message={message}
          />
        )}
        {error && (
          <SnackBarMessages
            open={true}
            position="top-right"
            timing={5000}
            type="error"
            message={message}
          />
        )}
        </Container> 
      </div>
    </>
  );
};
export default Dashboard;
