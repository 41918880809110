import { put, call } from 'redux-saga/effects';
import { registerUserService, loginUserService, reportUploadService, userReportUpload, forgotPasswordService, verifyCodeService, createNewPasswordService ,changePasswordService,userProfileUpdateService, verifyUserCodeService} from '../Services/AuthService';

import { createCaseService } from '../Services'

import * as types from '../Action'
import Swal from 'sweetalert2';

export function* registerSaga(payload) {
  try {
    const response = yield call(registerUserService, payload);
    yield   put({ type: types.REGISTER_USER_SUCCESS, payload:response });
    
  } catch (error) {
    yield put({ type: types.REGISTER_USER_ERROR, payload: error });
  }
}

export function* loginSaga(payload) {
  try {
    const response = yield call(loginUserService, payload);
    yield put({ type: types.LOGIN_USER_SUCCESS, payload:response });
  } catch (error) {
    yield put({ type: types.LOGIN_USER_ERROR, payload: error });
  }
}

export function* forgotPasswordSaga(payload) {
  try {
    const response = yield call(forgotPasswordService, payload);
    yield put({ type: types.FORGOT_PASSWORD_SUCCESS, payload: response });
    //  window.location.href = "/verify-code";
  } catch (error) {
    yield put({ type: types.FORGOT_PASSWORD_ERROR, error})
  }
}


export function* verifyCodedSaga(payload) {
  try {
    const response = yield call(verifyCodeService, payload);
    yield put({ type: types.VERIFY_CODE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.VERIFY_CODE_ERROR, error })
  }
}

export function* verifyUserCodedSaga(payload) {
  try {
    const response = yield call(verifyUserCodeService, payload);

    yield put({ type: types.VERIFY_USER_CODE_SUCCESS, payload: response });

    //  window.location.href = "/verify-code";
  } catch (error) {

    yield put({ type: types.VERIFY_USER_CODE_ERROR,error })
  }
}


export function* createPasswordSaga(payload) {
  try {
    const response = yield call(createNewPasswordService, payload);
    yield  put({ type: types.CREATE_NEW_PASSWORD_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.CREATE_NEW_PASSWORD_ERROR, error})
  }
}

export function* changePasswordSaga(payload) {
  try {
    const response = yield call(changePasswordService, payload);
    yield  put({ type: types.CHANGE_PASSWORD_SUCCESS, payload: response });
    //  window.location.href = "/verify-code";
  } catch (error) {
    yield put({ type: types.CHANGE_PASSWORD_ERROR, payload: error})
  }
}

export function* reportSaga(payload) {
  try {
    const response = yield call(reportUploadService, payload);
    response.imgCount = payload.report.imgCount;
    yield put({ type: types.USER_REPORT_SUCCESS, payload:response });
  } catch (error) {
    Swal.fire('Image not uploaded, Please try again', '', 'error');
    yield put({ type: types.USER_REPORT_ERROR })
  }
}


export function* reportUploadSaga(payload) {
  try {
    const response = yield call(userReportUpload, payload);
    yield put({ type: types.USER_REPORT_UPLOAD_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.USER_REPORT_UPLOAD_ERROR, error })
  }
}

export function*  profileUpdateSaga(payload) {
  try {
    const response = yield call(userProfileUpdateService, payload);
    yield put({ type: types.USER_PROFILE_SUCCESS, payload:response })
    
  } catch (error) {
    yield put({ type: types.USER_PROFILE_ERROR, error })
  }
}

export function* veterinaryCaseSaga(payload) {
    try {
      const response = yield call(createCaseService, payload);
      yield put({ type: types.VETERINARY_CASE_SUCCESS, payload:response })
      
    } catch (error) {
        console.log(error)
      yield put({ type: types.VETERINARY_CASE_ERROR, payload:error, error })
    }
  }
