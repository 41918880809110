import React, { useState, useEffect } from "react";
import { Row, Container, Col, Tabs, Tab } from "react-bootstrap";

import TeethImg from "../../../assets/Dashboard/reports/cavity-initial.svg";
import {
  getLatestReport,
  getCavityTartarContents,
} from "../../../Redux/Services/AuthService";
import NoteText from "../../UI/Note/NoteText";
import CavityTartarBackArrow from "../../UI/Header/CavityTartarBackArrow";
import CavityTartarEmptyContents from "../../UI/EmptyContents/CavityTartarEmptyContents";
import SliderCavityTartarCard from "../../UI/Slider/SliderCavityTartarCard";
import { CAVITY_IMG_URL, TARTAR_IMG_URL } from "../../../Redux/Services/BaseUrl";
import CavityTartarSkeleton from './../../UI/skeleton/cavityTartarSkeleton';
import "./Cavity.css";
import GradesCard from "../../UI/Card/ExplainerCard/GradesCard";

const Cavity = (props) => {

  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [reportImages, setReportImages] = useState([]);
  const [cavityContents, setCavityContents] = useState([]);
  const [tartarContents, setTartarContents] = useState([]);
  const [overallReportContents, setOverallReportContents] = useState("");
  const [reportScoreImg, setReportScoreImg] = useState([]);
  const [reportId, setReportId] = useState('');
  const [emptyContent, setEmptyContent] = useState({
    headerText:
      "You don’t seem to have tested the cavity conditions of your teeth",
    img: TeethImg,
    bottomText: "Cavities spread fast. Catch it early",
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      getLatestReport()
        .then((res) => {
          const resObj = res.data.data[0];
          setReportImages(resObj.report_images);
          setReportId(resObj._id);
          const reportScore = Object.keys(resObj.report_score[0]);
          // deleting last value from reportScore
          reportScore.splice(reportScore.length - 1, 1);
          setReportScoreImg(reportScore);
          const cavityScore = resObj.report_score[0].overall_score[1];
          const tartarScore = resObj.report_score[0].overall_score[0];
          getCavityTartarContents(resObj._id, cavityScore, tartarScore)
            .then((res1) => {
              if (
                res1 &&
                res1?.data?.code === 200 &&
                res1?.data?.data?.cavityContent &&
                res1?.data?.data?.tartarContent
              ) {
                setCavityContents(res1.data.data.cavityContent);
                setTartarContents(res1.data.data.tartarContent);
                setOverallReportContents(res1.data.data);
              }
              resultTextHandler('outputTartarContent1', res1.data.data.overallTartarResult[0]);
              resultTextHandler('outputCavityContent1', res1.data.data.overallCavityResult[0]);
              setIsSkeletonLoading(false);
            })
            .catch((err) => {
            });
        })
        .catch((err) => {
          setIsSkeletonLoading(false);
        });
    } else {
      setIsSkeletonLoading(false);
    }

  }, []);

  const selectedTab = (select) => {
    if (select === "Cavity") {
      setEmptyContent({
        headerText:
          "You don't seem to have taken your teeth scan yet.",
        img: TeethImg,
        bottomText: "Cavities spread fast. Catch it early",
      });
    } else if (select === "Tartar") {
      setEmptyContent({
        headerText:
          "You don't seem to have taken your teeth scan yet.",
        img: TeethImg,
        bottomText: "Tartar buildup quickly. Catch it early",
      });
    }
  };

  const tagHighLightedHandler = (text) => {
    let replaceText;
    let cssClass;
    if (text.search('A') > -1) {
      replaceText = /A/g;
      cssClass = 'text-success';
    } else if (text.search('B') > -1) {
      replaceText = /B/g;
      cssClass = 'text-success';
    } else if (text.search('C') > -1) {
      replaceText = /C/g;
      cssClass = 'text-warning';
    } else if (text.search('D') > -1) {
      replaceText = /D/g;
      cssClass = 'text-danger';
    } else if (text.search('E') > -1) {
      replaceText = /E/g;
      cssClass = 'text-danger';
    }
    return text.replace(replaceText, (key) => `<span class="${cssClass} font-weight-bold">${key}</span>`).replace(/(\#\S+)/g, (key) => `<span>${key}</span>`);
  }


  function resultTextHandler(el, text) {
    var outEl = document.getElementById(el);
    outEl.innerHTML = tagHighLightedHandler(text);
  }

  return (
    <Container className="dental-risk-bg min-vh-100">
      <Row>
        {/* Back Arrow */}
        <CavityTartarBackArrow />

        <Col xs={12} className="px-0 overflow">
          <Tabs
            defaultActiveKey="Cavity"
            id="uncontrolled-tab-example"
            className="cavity-navTabs mx-auto black-color"
            onSelect={selectedTab}
          >
            <Tab eventKey="Cavity" title="Cavity">
              <div className="mt-5 pt-5">
                {isSkeletonLoading && <CavityTartarSkeleton />}
                {
                  reportScoreImg.length > 0 && (
                    <>
                      {
                        overallReportContents?.overallCavityResult?.map((item, index) => (
                          <p key={index} className="px-4 black-color font-size-20 mb-2" id={`outputCavityContent${index+1}`}>
                            {item}
                          </p>
                        ))
                      }
                      <div className="shadow bg-white m-3 py-3 border-radius-15px">
                        <SliderCavityTartarCard
                          isCavityOrTartar="cavity"
                          reportId={reportId}
                          imgUrl={CAVITY_IMG_URL}
                          reportScoreImg={reportScoreImg}
                          contents={cavityContents}
                        />
                      </div>
                      <GradesCard isCavity={true} />
                    </>
                  )
                }
              </div>
            </Tab>
            <Tab eventKey="Tartar" title="Tartar">
              <div className="mt-5 pt-5">
                {
                  reportScoreImg.length > 0 && (
                    <>
                      {
                        overallReportContents?.overallTartarResult?.map((item, index) => (
                          <p key={index} className="px-4 font-size-20 mb-2" id={`outputTartarContent${index+1}`}>
                            {item}
                          </p>
                        ))

                      }

                      <div className="shadow bg-white m-3 py-3 border-radius-15px">
                        <SliderCavityTartarCard
                          isCavityOrTartar="tartar"
                          reportId={reportId}
                          imgUrl={TARTAR_IMG_URL}
                          reportScoreImg={reportScoreImg}
                          contents={tartarContents}
                        />
                      </div>
                      <GradesCard isTartar={true} />
                    </>
                  )
                }
              </div>
            </Tab>
          </Tabs>


        </Col>
        {reportImages.length > 0 && (
          <Col>
            <NoteText />
          </Col>
        )}
      </Row>

      {/* when there is no report list */}
      {!isSkeletonLoading && reportImages.length === 0 && (
        <CavityTartarEmptyContents emptyContent={emptyContent} />
      )}
    </Container>
  );
};

export default Cavity;
