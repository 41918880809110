import React, { useEffect, useState } from "react";
import QRCode from 'qrcode';
import cssClasses from './QrCode.module.css';
import Logo from './../../assets/logo/logo.svg';

const QrCodePage = () => {

    const [src, setSrc] = useState('');

    useEffect(() => {
        QRCode.toDataURL('https://app.vetlens.com').then((data) => {
            setSrc(data);
        });
    }, []);

    return (
        <div style={{backgroundColor: '#ffffff'}}>
            <div className={`container ${cssClasses['qr-container']}`}>
                <div className="row">
                    <div className={`col-12 primary-bg`}>
                        <div className="row">
                            <div className="col-12 text-center d-flex align-items-center justify-content-center py-2">
                                <img className={`${cssClasses['logo']}`} src={Logo} alt="logo" />
                                <h1 className={`text-white mb-0 py-3 font-size-20 text-uppercase ml-4`}>
                                Toothlens Lite
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5 px-5 text-center">
                        <p className="font-weight-500 font-size-20 text-uppercase">Thanks for visiting</p>
                        <p>Toothlens Lite works on mobile devices only</p>
                        <p>Please scan to use the App</p>
                    </div>
                    <div className={`col-12 text-center my-5`}>
                        <img className={`${cssClasses['qr-code-img']}`} src={src} alt="QR code img" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QrCodePage;