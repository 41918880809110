import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import SuccessTeeth from "./../../../assets/cameraModule/Success.svg";
import FailedTeeth from "./../../../assets/cameraModule/Failed.svg";
import { useHistory } from "react-router";
import { connect } from "react-redux";

const MessageModal = (props) => {
  let history = useHistory();

  const redirectToDashboard = () => {
    if (props.isSuccess) {
      localStorage.setItem('isGradeExplainer', true);
    }
    props.resetReport();
    props.removeAllImages();
    props.resetReportUpload();
    if (props.isQuestionnaireGiven) {
      return history.push("/dashboard");
    } else {
      return history.push("/questionnaire", {
        userQuestionsData: [],
        isGeneratePdf: !props.isQuestionnaireGiven ? true : false,
        reportId: props.reportId
      });
    }
  }

  return (
    <Row className="mx-0">
      <Col xs={12} className="d-flex justify-content-around mx-auto px-0">
        <div className="all-well-modal p-3">
          <div className="text-center">
            <img src={props.isSuccess ? SuccessTeeth : FailedTeeth} alt="SuccessTeeth" />
          </div>
          <div className="text-center">
            <h3 className="login-createOne my-3 mx-4 font-size-16">
              <span>
                {
                  props.isSuccess ? 'Thanks for submitting your photos.Go to Dashboard to see your reports.' :
                    'Sorry!! AI is experiencing some difficulty. Please rescan after 5 minutes'
                }
              </span>
              {
                !props.isQuestionnaireGiven && <span>Please take questionnaire to generate report</span>
              }
            </h3>
          </div>
          <div className="text-center mb-3">
            <button
              type="submit"
              className="modal-signup-btn mt-2"
              onClick={redirectToDashboard}
            >
              { !props.isQuestionnaireGiven ? 'Go to Questionnaire' :  'Go to Dashboard' }
            </button>
            {/* <Button
                  className="position-relative left-0 text-center reshoot-btn reshoot-left-icon rounded-pill primary-bg"
                  variant="primary"
                  size="sm"
                >
                  <i className="fas fa-redo font-size-22 text-white"></i>
                </Button> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

const mapDespatchToProps = (dispatch) => {
  return {
    resetReport: () => {
      dispatch({ type: "USER_REPORT_UPLOAD_RESET" });
    },
    removeAllImages: (imageData) => {
      dispatch({ type: "REMOVE_ALL IMAGES" });
    },
    resetReportUpload: (report) => {
      dispatch({ type: "USER_REPORT_RESET" });
    },
  };
};

export default connect(null, mapDespatchToProps)(MessageModal);
