import React, { useState } from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import PushToDentalOfficeGif from '../../../../assets/gif/pust-to--dental-office.gif';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const ConfirmationBranchPopup = (props) => {
    const { onClose, selectedValue, open } = props;
    const [isOpenDialog, setIsOpenDialog] = useState(open);
    let history = useHistory();

    const handleClose = () => {
        onClose(selectedValue);
        setIsOpenDialog(false);
        history.push('/total-test');
    };

    return (
        <Dialog onClose={handleClose} open={isOpenDialog} className="rating-container">
            <div className='text-right mt-3 d-flex justify-content-end'>
                <Button>
                    <Link to={'/total-test'}>
                        <i style={{ fontSize: '20px' }}
                            className="fas fa-times camera-cancel-btn primary-color d-flex justify-content-center align-items-center"></i>
                    </Link>
                </Button>
            </div>
            <div className="p-4 mb-3">
                <p className='font-weight-500 black-color'>
                    You have successfully shared your report to Dentistry
                </p>
                <img className='w-100' src={PushToDentalOfficeGif} alt="PushToDentalOfficeGif" />
            </div>
            <DialogActions className='d-block'>
                <div className='my-2 text-center'>
                    <Button style={{ fontSize: '20px' }} color="primary">
                        <Link to={'/dashboard'}>Go to dashboard</Link>
                    </Button>
                </div>
                <div>
                    <Button className="form-btn text-white mb-3" onClick={handleClose}>
                        View sent report
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )

}

export default ConfirmationBranchPopup;